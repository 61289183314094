import "./index.scss";
import React, { useEffect, useState } from "react";
import { Spinner } from 'react-bootstrap';
import CreateIcon from "../components/Create";
import { useDispatch, useSelector } from "react-redux";
import RequestSupportModal from "./RequestSupportModal";
import { reqGetListFAQs } from "../../../reduxs/cms/action";
import { CollapsibleContent } from '../../../components/collapsable-content';
import { reqGetUserProfile } from "../../../reduxs/user/action";

const tabs = [
  { type: 'app', title: 'APP', eventKey: 'content-app' },
  { type: 'hardware', title: 'SALES GALLERY HARDWARE', eventKey: 'content-hardware' },
  { type: 'tv_display', title: 'TV DISPLAY', eventKey: 'tv-display' },
]

const CMSFrequentlyAQS = () => {
  const dispatch = useDispatch();
  const faqList = useSelector((state) => state.cms.faqs);
  const authUser = useSelector((state) => state.user.data);
  const [activeTab, setActiveTab] = useState('app')

  const [isShowForm, setIsShowForm] = useState(false);

  const onTabClick = (type) => {
    setActiveTab(type)
  }

  useEffect(() => {
    dispatch(reqGetListFAQs());
    dispatch(reqGetUserProfile());
  }, []);

  const getListFaqByType = (type) => (faqList || []).filter(item => item.type === type);

  return (
    <div className="page-container faq-page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">FAQS</h1>
      </div>
      <div className="category-wrapper">
        <div className="container-fluid  text-center category-container">
          <div className="row  no-gutters">
            {tabs.map((tab, idx) => (
              <div key={tab.type} className="col header-item-container">
                <div onClick={() => onTabClick(tab.type)} className={idx !== 1 ? "header-item" : "header-item header-item--border"}>{tab.title}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="page-body content-page-body faq-page-body">
        <div className='scroll-view faq-container'>
          {
            getListFaqByType(activeTab)?.length
              ? getListFaqByType(activeTab)?.map((item) => {
                return <CollapsibleContent
                  key={item?.id}
                  title={item?.question}
                  content={item?.answer}
                />
              })
              : <div className="d-flex justify-content-center"><Spinner animation="border" /></div>
          }
        </div>
        <CreateIcon onClick={() => setIsShowForm(true)} />
      </div>
      {
        isShowForm &&
        <RequestSupportModal
          show={isShowForm}
          setShow={setIsShowForm}
          user={authUser}
        />
      }
    </div>
  );
};
export default CMSFrequentlyAQS;
