import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as PANOLENS from "panolens";
import { ImageLoader } from "three";
import "./virtual-modal.scss";
import { getImagePath } from "../../helper/media";
import classNames from "classnames";
import { WEBSOCKET_CHANNEL } from "../../constants/options";
import socket from "../../helper/socket";
import { Vector3 } from "three";


const VirtualModal = ({ isPresentation }) => {
  const modal = useSelector((state) => state.exploreModal.modal);

  const loader = new ImageLoader();
  const [viewer, setViewer] = useState(undefined);

  const authUser = useSelector((state) => state.user.data);
  useEffect(() => {
    onInitPanorama();
  }, [modal]);

  function temporaryStopRotate() {
    viewer.disableAutoRate();
    setTimeout(function () {
      viewer.enableAutoRate();
    }, 3000);
  }
  
  useEffect(() => {
    if (viewer) {
      window.addEventListener("click", temporaryStopRotate);
      viewer.addUpdateCallback(sendCameraPos);
      return () => {
        viewer.removeUpdateCallback(sendCameraPos);
        window.removeEventListener("click", temporaryStopRotate)
      }
    }
  }, [viewer]);

  useEffect(() => {
    if (!viewer) {
      return;
    }
    if (!isPresentation) {
      return;
    }

    // Prevent receiving camera position before init
    setTimeout(() => {
      socket.on(WEBSOCKET_CHANNEL.SHARE_PANORAMA_ACTION, listenerCameraAction);
    }, 1000);
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_PANORAMA_ACTION, listenerCameraAction);
    };
  }, [viewer]);

  const listenerCameraAction = ({ content }) => {
    const camera = viewer?.camera;
    if (!camera) {
      return;
    }

    let position1 = new Vector3();
    position1.x = content.position.x;
    position1.y = content.position.y;
    position1.z = content.position.z;

    camera.position.copy(content.position);
    camera.quaternion.copy(content.quaternion);
    camera.fov = content.fov;
    viewer.panorama?.rotation && (viewer.panorama.rotation.y = content.rotationY);
    camera.updateProjectionMatrix();
  };

  function sendCameraPos() {
    if (isPresentation) {
      return;
    }
    const camera = viewer?.camera;
    if (!camera) {
      return;
    }

    socket.emit(WEBSOCKET_CHANNEL.SHARE_PANORAMA_ACTION, {
      content: {
        position: camera.position,
        quaternion: camera.quaternion,
        rotationY: viewer.panorama?.rotation?.y,
        fov: camera.fov,
      },
      to: authUser?.id,
      from: authUser?.id,
    });
  
  };

  const onInitPanorama = () => {
    let selector = document.querySelector("#wrap-panorama");
    if (selector != undefined) {
      selector.replaceChildren();
    }
    if (modal == undefined || modal.virtualTour == undefined) {
      return;
    }
    const length = modal.virtualTour.media.length;
    if (length <= 0) {
      return;
    }

    const image1stURL = getImagePath(modal.virtualTour.media[0].path);
    loader.load(
      image1stURL,
      function (image) {
        const pano = new PANOLENS.ImagePanorama(image);
        viewer.add(pano);
        viewer.setPanorama(pano);
      },
      undefined,
      function (error) {
        console.error("An error happened.", error);
      }
    );

    let viewer = new PANOLENS.Viewer({
      container: document.querySelector("#wrap-panorama"),
      controlButtons: [],
      autoRotateSpeed: 0.5, 
      ...(!isPresentation ? { autoRotate: true } : {}),
    });
    viewer.camera.fov = 80;
    viewer.camera.updateProjectionMatrix();

    setViewer(viewer);
  };

  return (
    <>
      <div
        className={classNames("virtual-tour-modal", {
          isPresentation: isPresentation,
        })}
      >
        <div id='wrap-panorama' className='img-panorama' />
      </div>
    </>
  );
};

export default VirtualModal;
