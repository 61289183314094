export const GET_LIST_CUSTOMERS = 'GET_LIST_CUSTOMERS';
export const GET_LIST_AGENTS = 'GET_LIST_AGENTS';
export const GET_LIST_UNITS = 'GET_LIST_UNITS';
export const SET_EDITABLE_CUSTOMER = 'SET_EDITABLE_CUSTOMER';
export const SET_EDITABLE_PROPERTY_COLUMN = 'SET_EDITABLE_PROPERTY_COLUMN';
export const SET_EDITABLE_CUSTOMER_NOTE = 'SET_EDITABLE_CUSTOMER_NOTE';
export const ADD_ONE_CUSTOMER = 'ADD_ONE_CUSTOMER';
export const EDIT_ONE_CUSTOMER = 'EDIT_ONE_CUSTOMER';
export const IS_SHOW_UPLOAD_MEDIA_MODAL = 'IS_SHOW_UPLOAD_MEDIA_MODAL';
export const GET_LIST_MEDIA = 'GET_LIST_MEDIA';
export const SET_EDITABLE_MEDIA = 'SET_EDITABLE_MEDIA';
export const SET_IS_SHOW_CREATE_GALLERY_MODAL = 'IS_SHOW_CREATE_GALLERY_MODAL';
export const SET_SELECTED_MEDIA = 'SET_SELECTED_MEDIA';
export const GET_LIST_GALLERIES = 'GET_LIST_GALLERIES';
export const SET_EDITABLE_GALLERY = 'SET_EDITABLE_GALLERY';
export const SET_IS_SHOW_DELETE_GALLERY_MODAL =
  'SET_IS_SHOW_DELETE_GALLERY_MODAL';
export const GET_LIST_APPOINTMENT = 'GET_LIST_APPOINTMENT';
export const GET_LIST_AMENITY = 'GET_LIST_AMENITY';
export const REMOVE_ONE_CUSTOMER = 'REMOVE_ONE_CUSTOMER';
export const GET_LIST_FAQS = 'GET_LIST_FAQS';
