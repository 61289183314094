import React, { useRef, useState, useEffect, useCallback, memo, forwardRef } from "react";
import * as dat from "dat.gui";
import { reqGetUserProfile } from "../../reduxs/user/action";
import {
  Canvas,
  useThree,
  extend,
  useFrame,
  useLoader
} from "@react-three/fiber";
import * as THREE from "three";
import { Vector3 } from "three";
import {
  PAGES,
  LAYERS,
  OBJECT_TYPES,
  HOTSPOT_TYPES,
  WEBSOCKET_CHANNEL,
  ACTION_NAME,
} from "../../constants/options";
import { rStats } from "../../helper/rStats";
import { glStats, threeStats } from "../../helper/rStats.extras";
import { gsap } from "gsap";
import { getMediaUrl } from "../../helper/media";
import { clearFilterUnit } from "../../helper/utils"
import {
  reqSetExploreModal,
  reqSetIsShowExpandAmenity,
  reqSetIsShowExploreModal,
  reqSetIsShowAmenityVirtualTour
} from "../../reduxs/explore-modal/action";
import { reqFilterUnitAvailability, reqSetIsShowFilter, reqSetIsShowUnitList } from "../../reduxs/unit-explore/action";
import { reqSetActiveTransportOption, reqSetIsTransparent } from "../../reduxs/home/action";
import { reqSetPage } from "../../reduxs/home/action";
import { reqSetActivePrecinctID } from "../../reduxs/transport-options/action";
import { useDispatch, useSelector } from "react-redux";
import { OrbitCustomControls } from "./OrbitCustomControls";
import socket from "../../helper/socket";
import { Sky } from './Sky';
import rightArrowIcon from "../../assets/images/arrow-right-thin-black.svg";
import { Environment } from "@react-three/drei";

extend({ OrbitCustomControls });
extend({ Sky })

export let selectedHotspot3D;
export let selectedHotspot;

const ToneMappingOptions = {
  None: THREE.NoToneMapping,
  Linear: THREE.LinearToneMapping,
  Reinhard: THREE.ReinhardToneMapping,
  Cineon: THREE.CineonToneMapping,
  ACESFilmic: THREE.ACESFilmicToneMapping,
  Custom: THREE.CustomToneMapping
};
const SkyModel = memo(forwardRef((props, skyRef) => {
  return <sky ref={skyRef} scale={[450000, 450000, 450000]} />;
}));
SkyModel.displayName = 'SkyModel';

const CanvasBox = memo(
  React.forwardRef((props, refScene) => {
    const {
      _3dSetting,
      controls,
      hotspots,
      isIntroduction,
      objects,
      targetPosition,
      activeObjectIds,
      setActiveObjectIds,
      isPresentation,
    } = props;
    const dispatch = useDispatch();
    // const isDragging3D = useSelector((state) => state.scene.isDragging3D);
    const [ambientLight, setAmbientLight] = useState({
      intensity: 50,
      color: "#222222"
    });
    const [toneMapping, setToneMapping] = useState({
      toneMapping: ToneMappingOptions.Linear,
      exposure: 1.2,
    });
    const [environmentMap, setEnvironmentMap] = useState({
      hasMap: true,
      intensity: 1
    });
    const toneMappingGUIOptions = {
      exposure: 1.2,
      toneMapping: 'Linear',
      blurriness: 0.3,
      intensity: 1.0,
    };
    const lightingGUIOptions = {
      intensity: 50,
      color: "#222222"
    };
    const guiContainerRef = useRef();
    const gui = new dat.GUI({ autoPlace: false });
    const LightingGUI = gui.addFolder("Lights");
    LightingGUI.add(lightingGUIOptions, "intensity", 0, 1000)
      .name("intensity")
      .onChange(e => {
        setAmbientLight((prev) => ({ ...prev, intensity: e }));
      });
    LightingGUI.addColor(lightingGUIOptions, "color")
      .name("color")
      .onChange(e => {
        setAmbientLight((prev) => ({ ...prev, color: e }));
      });
    const ToneMappingGUI = gui.addFolder("Tone Mapping");
    ToneMappingGUI.add(toneMappingGUIOptions, 'toneMapping', Object.keys(ToneMappingOptions))
      .name("toneMapping")
      .onChange(e => {
        setToneMapping((prev) => ({ ...prev, toneMapping: ToneMappingOptions[e] }));
      });
    ToneMappingGUI.add(toneMappingGUIOptions, 'exposure', 0, 2)
      .name("exposure")
      .onChange(e => {
        setToneMapping((prev) => ({ ...prev, exposure: e }));
      });
    const SkyGUI = gui.addFolder("Sky");
    const skyGUIOptions = {
      turbidity: 20,
      rayleigh: 0.327,
      mieCoefficient: 0.002,
      mieDirectionalG: 0.9999,
      elevation: 15,
      azimuth: -140,
      exposure: 1.0,
      visible: true,
    }
    SkyGUI.add(skyGUIOptions, 'turbidity', 0.0, 20.0, 0.1 ).onChange( skyGuiChanged );
    SkyGUI.add(skyGUIOptions, 'rayleigh', 0.0, 4, 0.001 ).onChange( skyGuiChanged );
    SkyGUI.add(skyGUIOptions, 'mieCoefficient', 0.0, 0.1, 0.001 ).onChange( skyGuiChanged );
    SkyGUI.add(skyGUIOptions, 'mieDirectionalG', 0.0, 1, 0.0001 ).onChange( skyGuiChanged );
    SkyGUI.add(skyGUIOptions, 'elevation', 0, 90, 0.1 ).onChange( skyGuiChanged );
    SkyGUI.add(skyGUIOptions, 'azimuth', - 180, 180, 0.1 ).onChange( skyGuiChanged );
    SkyGUI.add(skyGUIOptions, 'exposure', 0, 1, 0.0001 ).onChange( skyGuiChanged );
    SkyGUI.add(skyGUIOptions, 'visible').onChange( skyGuiChanged );

    useEffect(() => {
      if (
        !(
          !!objects.length &&
          !!hotspots.length &&
          Object.keys(_3dSetting).length
        )
      ) {
        return <div />;
      }

      dispatch(reqGetUserProfile());
      if (guiContainerRef.current && !isPresentation) {
        guiContainerRef.current.appendChild(gui.domElement);
      }
      return () => gui.destroy();
    }, []);

    const authUser = useSelector((state) => state.user.data);
    const isShowRightPanel = useSelector(
      (state) => state.exploreModal.isShowExploreModal
    );

    const page = useSelector((state) => state.home.page);
    const modal = useSelector((state) => state.exploreModal.modal);

    // GLTF
    const gltfModels = useSelector((state) => state.scene.gltfModels);
    const envMap = useSelector((state) => state.scene.envMap);
    const meshRefs = useRef([]);
    const EnvironmentGUI = gui.addFolder("Environment");
    EnvironmentGUI.domElement.classList.add("environment-gui");
    const environmentGUIOption = {  
      envMap: true,
      envIntensity: 1,
    }
    EnvironmentGUI.add(environmentGUIOption, "envMap")
      .name("envMap")
      .listen()
      .onChange(e => {
        setEnvironmentMap((prev) => ({ ...prev, hasMap: e }));
      })
    EnvironmentGUI.add(environmentGUIOption, 'envIntensity', 0, 1, 0.01)
      .name("envIntensity")
      .onChange(e => {
        setEnvironmentMap((prev) => ({ ...prev, intensity: e }));
      })
    const MeshesGUI = gui.addFolder("Meshes");
    MeshesGUI.domElement.classList.add("meshes-gui");
    gltfModels.forEach(m => {
      const modelMaterial = m.children?.[0]?.material;
      if (modelMaterial) {
        const options = {
          color: modelMaterial?.color ? `#${modelMaterial?.color?.getHexString()}` : "#ffffff",
          emissive: modelMaterial?.emissive ? `#${modelMaterial?.emissive?.getHexString()}` : "#000000",
          roughness: modelMaterial?.roughness,
          metalness: modelMaterial?.metalness,
          opacity: modelMaterial?.opacity,
        }
        const MeshesFolder = MeshesGUI.addFolder(m.name);
        MeshesFolder.addColor(options, 'color')
          .name('color')
          .onChange(e => {
            const mesh = meshRefs.current.find(mesh => mesh.modelName === m.name);
            if (mesh && mesh.material) {
              mesh.material.color.set(e);
            }
          })
        MeshesFolder.addColor(options, 'emissive')
          .name('emissive')
          .onChange(e => {
            const mesh = meshRefs.current.find(mesh => mesh.modelName === m.name);
            if (mesh && mesh.material) {
              mesh.material.emissive.set(e);
            }
          })
        MeshesFolder.add(options, 'roughness', 0, 1, 0.01)
          .name("roughness")
          .onChange(e => {
            const mesh = meshRefs.current.find(mesh => mesh.modelName === m.name);
            if (mesh && mesh.material) {
              mesh.material.roughness = e;
            }
          })
        MeshesFolder.add(options, 'metalness', 0, 1, 0.01)
          .name("metalness")
          .onChange(e => {
            const mesh = meshRefs.current.find(mesh => mesh.modelName === m.name);
            if (mesh && mesh.material) {
              mesh.material.metalness = e;
            }
          })
        MeshesFolder.add(options, 'opacity', 0, 1, 0.01)
          .name("opacity")
          .onChange(e => {
            const mesh = meshRefs.current.find(mesh => mesh.modelName === m.name);
            if (mesh && mesh.material) {
              mesh.material.opacity = e;
            }
          })
      }
    })
    
    function skyGuiChanged() {
      if (sky == undefined || sky.current == undefined) {
        return;
      }
      let sun = new Vector3(0, 0.0, 0);
      const uniforms = sky.current.material.uniforms;
      uniforms[ 'turbidity' ].value = skyGUIOptions.turbidity;
      uniforms[ 'rayleigh' ].value = skyGUIOptions.rayleigh;
      uniforms[ 'mieCoefficient' ].value = skyGUIOptions.mieCoefficient;
      uniforms[ 'mieDirectionalG' ].value = skyGUIOptions.mieDirectionalG;
      const phi = THREE.MathUtils.degToRad( 90 - skyGUIOptions.elevation );
      const theta = THREE.MathUtils.degToRad( skyGUIOptions.azimuth );
      sun.setFromSphericalCoords( 1, phi, theta );
      uniforms[ 'sunPosition' ].value.copy( sun );
      sky.current.exposure = skyGUIOptions.exposure;
      sky.current.visible = skyGUIOptions.visible;
    }

    let lastPosition = new THREE.Vector3();
    let lastTarget = new THREE.Vector3();
    let pointerDown = false;

    const refListenSocket = useRef(false);

    const listenerSharedUIAction = ({ content }) => {
      if (content.action === ACTION_NAME.CLICK_HOTSPOT) {
        refListenSocket.current = true;
        handleClickHotspot(content.data.hotspot, content.data.selectedHotspotId);
        let timeout = setTimeout(() => {
          refListenSocket.current = false;
          clearTimeout(timeout)
        }, 500);
      }
      if(content.action === ACTION_NAME.SHOW_AMENITY_VIRTUAL_TOUR) {
        handleClickVirtualTour()
      }
    };
    useEffect(() => {
      if (isPresentation) {
        socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
      }
      return () => {
        refListenSocket.current = false;
        socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
      };
    }, [isPresentation]);

    const sendCameraPos = () => {
      if (!isPresentation && controls.current?.didUpdate) {
        lastPosition.x = controls.current.object.position.x;
        lastPosition.y = controls.current.object.position.y;
        lastPosition.z = controls.current.object.position.z;

        lastTarget.x = controls.current.target.x;
        lastTarget.y = controls.current.target.y;
        lastTarget.z = controls.current.target.z;

        let quaternion = {
          x: controls.current.object.quaternion.x,
          y: controls.current.object.quaternion.y,
          z: controls.current.object.quaternion.z,
          w: controls.current.object.quaternion.w,
        };

        socket.emit(WEBSOCKET_CHANNEL.SHARE_CAMERA_ACTION, {
          content: {
            position: lastPosition,
            quaternion: quaternion,
            zoom: controls.current.object.zoom,
          },
          to: authUser?.id,
          from: authUser?.id,
        });
      }
    }

    const light = useRef();
    let glS, tS, rS;
    let timeVector3 = new Vector3(0, 0, 0);
    let alphaVector3 = new Vector3(0, 0, 0);

    let isCameraAnimation = false;

    let position = new THREE.Vector3();

    const [selectedHotspotId, setSelectedHotspotId] = useState("");
    const [isCameraAnimated, setCameraAnimated] = useState(false);

    useEffect(() => {
      setSelectedHotspotId("")
    }, [page])

    let hotspot3Ds = [];
    let hotspotHasChildren = {};
    let pointerDownId;
    let hotspotPointerDownId;

    let meshInstanceMap = {};

    populateMeshInstanceMapKeys(gltfModels);
    associateModelsToMap(objects);

    const sky = useRef();

    function populateMeshInstanceMapKeys(models) {
      models.forEach((model) => {
        let entry = { model, instances: [] };
        let key = model.name;
        meshInstanceMap[key] = entry;
      });
    }

    function associateModelsToMap(objects) {
      objects.forEach((obj) => {
        // Make assumption that we can remove .fbx from file_name
        let name = obj["3d_filename"];
        if (!meshInstanceMap[name]) {
          console.warn("No GLTF File supplied for", obj);
          return;
        }
        meshInstanceMap[name].instances.push(obj);
      });
    }

    function handleAreaClick(controls, camLookAtPosition, camPosition) {
      return controls.current.lookAtAndMovePosition(
        camLookAtPosition,
        camPosition
      );
    }

    function updateHotspot() {
      for (let i = 0; i < hotspot3Ds.length; i++) {
        let hotspot3D = hotspot3Ds[i];
        if (!hotspot3D || !hotspot3D.material) {
          continue;
        }
        let hotspot = hotspot3D.userData;
        hotspot3D.material.map = hotspot.texture;
        let isVisible = true;
        let isSubHotspot = hotspot.parent_id != null;
        if (isSubHotspot) {
          isVisible = hotspot.parent_id == selectedHotspotId;
        } else if (hotspotHasChildren[hotspot.id]) {
          isVisible = hotspot.id != selectedHotspotId;
        }
        hotspot3D.visible = isVisible;
        if (!isVisible) {
          hotspot3D.layers.set(LAYERS.DISABLE);
        } else {
          hotspot3D.layers.set(hotspot.layer);
        }
      }
    }

    function threePosition(data) {
      return new Vector3(data.x, data.z, -data.y);
    }

    function threePosition2(data, vector) {
      vector.x = data.x;
      vector.y = data.z;
      vector.z = -data.y;
    }

    function setColor(color, object3d) {
      object3d.traverse(function (child) {
        if (child instanceof THREE.Material) {
          child.color.set(color);
        } else if (child.material != null) {
          if (child.material instanceof Array) {
            for (var i = 0; i < child.material.length; i++) {
              child.material[i].color.set(color);
            }
          } else {
            child.material.color.set(color);
          }
        }
      });
    }

    function animateAlpha(alpha, object3d) {
      if (alpha == object3d.userData.alpha) {
        return;
      }

      alphaVector3.x = object3d.userData.alpha;
      alphaVector3.y = 0;
      alphaVector3.z = 0;

      gsap.to(alphaVector3, {
        duration: 0.2,
        x: alpha,
        y: 0.0,
        z: 0.0,
        onUpdate: function () {
          setAlpha(alphaVector3.x, object3d);
        },
        onComplete: function () {
          object3d.userData.alpha = alpha;
        }
      });
    }

    function setAlpha(alpha, object3d) {
      object3d.traverse(function (child) {
        if (child instanceof THREE.Material) {
          child.opacity = alpha;
        } else if (child.material != null) {
          if (child.material instanceof Array) {
            for (var i = 0; i < child.material.length; i++) {
              child.material[i].opacity = alpha;
            }
          } else {
            child.material.opacity = alpha;
          }
        }
      });
    }

    const handleClickHotspot = useCallback((hotspot, selectedHotspotId) => {
      if (!isPresentation) {
        // dispatch(setIsDragging3D(false));
        socket.emitUIActionEvent(ACTION_NAME.CLICK_HOTSPOT, {
          hotspot,
          selectedHotspotId
        });
      }
      switch (hotspot.link_type) {
        case HOTSPOT_TYPES.FUTURE_ITEM:
          if (hotspot.link) {
            dispatch(reqSetExploreModal(hotspot.link));
            dispatch(reqSetIsShowExploreModal(true));
          }
          break;
        case HOTSPOT_TYPES.EXPLORE_DISTRICT:
          if (hotspot.link) {
            dispatch(reqSetExploreModal(hotspot.link));
            dispatch(reqSetIsShowExploreModal(true));
          }
          break;
        case HOTSPOT_TYPES.EXPLORE_DISTRICT_DETAIL:
          if (hotspot.link) {
            dispatch(reqSetExploreModal(hotspot.link));
            dispatch(reqSetIsShowExploreModal(true));
          }
          break;
        case HOTSPOT_TYPES.AMENITY:
          dispatch(reqSetPage(PAGES.AMENITIES_PAGE));
          break;
        case HOTSPOT_TYPES.LOCATION_PAGE:
          dispatch(reqSetPage(PAGES.LOCATION_PAGE));
          break;
        case HOTSPOT_TYPES.UNIT_EXPLORE:
          setActiveObjectIds([])
          dispatch(reqSetIsShowFilter(true));
          dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
          dispatch(reqSetIsTransparent(false));
          clearFilterUnit('auto_available');
          dispatch(reqSetIsShowUnitList(true));
          dispatch(reqFilterUnitAvailability([{ id: 1, title: "Available", value: "available" }]));
          break;
        case HOTSPOT_TYPES.MODAL:
          if (hotspot.link) {
            if (selectedHotspotId == hotspot["id"]) {
              setSelectedHotspotId("")
              dispatch(reqSetIsShowExploreModal(false));
            } else {
              setSelectedHotspotId(hotspot.id)
              dispatch(reqSetExploreModal(hotspot.link));
              dispatch(reqSetIsShowExploreModal(true));
            }
          }
        default:
          break;
      }

      switch (hotspot.layer) {
        case LAYERS.D_HOTSPOT:
          controls.current.hideLayer(LAYERS.D_HOTSPOT);
          controls.current.showAndEnableLayer(LAYERS.EXPLORE_DISTRICT_HOTSPOT);
        default:
          break;
      }

      if (hotspot.parent_id) {
        return;
      }

      const hotspot3D = hotspot3Ds.find((hotspot3D) => hotspot3D.userData.id === hotspot.id);
      updateHotspot();
      if (hotspot3D && hotspot3D.material) {
        hotspot3D.material.map = hotspot.activeTexture ? hotspot.activeTexture : hotspot.texture;
      }
      selectedHotspot3D = hotspot3D;
      selectedHotspot = hotspot;
    },  [isPresentation, hotspot3Ds, controls.current]);

    const handleShowModalImage = () => {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.SHOW_AMENITY_MODAL_IMAGE);
      }
      dispatch(reqSetIsShowExpandAmenity(true));
    }

    const handleClickVirtualTour = () => {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.SHOW_AMENITY_VIRTUAL_TOUR);
      }
      dispatch(reqSetIsShowExploreModal(false))
      dispatch(reqSetIsShowAmenityVirtualTour(true));
    } 

    const Hotspot = memo((props) => {
      const onPointerOver = () =>
        controls.current && controls.current.setCursorStyle("pointer");
      const onPointerOut = () =>
        controls.current && controls.current.setCursorStyle("grab");
      const webglHotspots = hotspots.map((hotspot) => {
        hotspot.texture = useLoader(
          THREE.TextureLoader,
          getMediaUrl(hotspot.image_path)
        );
        if (hotspot.active_image_path) {
          hotspot.activeTexture = useLoader(
            THREE.TextureLoader,
            getMediaUrl(hotspot.active_image_path)
          );
        }
        return hotspot;
      });
      const { selectedHotspotId } = props;
      hotspot3Ds = [];
      hotspotHasChildren = {};
      return (
        <group>
          {webglHotspots.map((hotspot, index) => {
            threePosition2(hotspot.position, position);

            let isVisible = true;
            let isSubHotspot = hotspot.parent_id != null;
            if (isSubHotspot) {
              isVisible = hotspot.parent_id == selectedHotspotId;
              hotspotHasChildren[hotspot.parent_id] = true;
            }

            const hasLine = hotspot.line_position;
            if (hasLine && !hotspot.lineGeometry) {
              hotspot.lineGeometry = new THREE.BufferGeometry().setFromPoints([
                new THREE.Vector3(position.x, position.y, position.z),
                new THREE.Vector3().copy(hotspot.line_position),
              ]);
            }

            return (
              <React.Fragment key={`hotspot-${hotspot?.id}`}>
                {hasLine && (
                  <line
                    geometry={hotspot.lineGeometry}
                    visible={isVisible}
                    layers={isVisible ? hotspot.layer : LAYERS.DISABLE}
                  >
                    <lineBasicMaterial color={"black"} />
                  </line>
                )}
                <sprite
                  ref={(r) => {
                    if (r && r.material) {
                      r.material.map.minFilter =
                        THREE.LinearMipMapNearestFilter;
                      r.material.map.magFilter = THREE.LinearFilter;
                      r.material.precision = "highp";
                      r.material.map.needsUpdate = true;
                      r.renderOrder = 1;
                    }
                    hotspot3Ds.push(r);
                  }}
                  visible={isVisible}
                  layers={isVisible ? hotspot.layer : LAYERS.DISABLE}
                  onPointerOver={() => onPointerOver()}
                  onPointerOut={() => onPointerOut()}
                  userData={hotspot}
                  onPointerDown={() => {
                    hotspotPointerDownId = hotspot.id;
                  }}
                  onPointerUp={(e) => {
                    if (hotspotPointerDownId == hotspot.id) {
                      e.stopPropagation();
                      handleClickHotspot(e.object.userData, selectedHotspotId);
                    }

                    hotspotPointerDownId = null;
                  }}
                  key={index}
                  position={[position.x, position.y, position.z]}
                  scale={[
                    hotspot?.scale?.x || 1,
                    hotspot?.scale?.y || 1,
                    hotspot?.scale?.z || 1,
                  ]}
                >
                  <spriteMaterial
                    sizeAttenuation={false}
                    fog={false}
                    precision="highp"
                    attach="material"
                    map={(selectedHotspotId === hotspot.id && !!hotspot.activeTexture) ? hotspot.activeTexture : hotspot.texture}
                  />
                </sprite>
              </React.Fragment>
            );
          })}
        </group>
      );
    });
    Hotspot.displayName = "Hotspot";

    const RenderInstance = (instance, model) => {
      let isClickable = true;

      let use_color =
        instance.type == OBJECT_TYPES.DO || instance.type == OBJECT_TYPES.FD;
      let use_texture = instance.use_texture;
      let isActive = activeObjectIds.includes(instance.id);

      model.children.map((mesh_, index) => {
        if (mesh_?.material?.color != null && !use_color) {
          Object.assign(mesh_, { name: instance.id, modelName: model.name });
        }
      });

      return model.children.map((mesh_, index) => {
        let isTransparency =
          (instance.alpha != null && instance.alpha <= 80.0) ||
          (instance.hover_alpha != null && instance.hover_alpha <= 80.0) ||
          (instance.active_alpha != null && instance.active_alpha <= 80.0);
        if (isActive) {
          setColor(mesh_.userData.active_color, mesh_);
          isTransparency && setAlpha(mesh_.userData.active_alpha, mesh_);
        } else {
          if (!use_texture || use_color) {
            setColor(mesh_.userData.color, mesh_);
          }
          if (!use_texture || isTransparency) {
            setAlpha(mesh_.userData.alpha, mesh_);
          }
        }

        const onPointerOver =
          instance.hover_color != null
            ? () => {
              if (pointerDownId && pointerDownId != instance.id) {
                return;
              }
              if (mesh_.userData.isActive) {
                return;
              }
              controls.current && controls.current.setCursorStyle("pointer");
              instance.hover_color &&
                setColor(mesh_.userData.hover_color, mesh_);
              animateAlpha(mesh_.userData.hover_alpha, mesh_);
              mesh_.userData.isHover = true;
            }
            : null;

        const onPointerOut =
          instance.hover_color != null
            ? () => {
              if (pointerDownId && pointerDownId != instance.id) {
                return;
              }
              if (mesh_.userData.isActive) {
                return;
              }
              controls.current && controls.current.setCursorStyle("grab");
              if (mesh_.userData.isHover) {
                setColor(mesh_.userData.color, mesh_);
                animateAlpha(mesh_.userData.alpha, mesh_);
                mesh_.userData.isHover = false;
              }
            }
            : null;

        const onPointerDown = (e) => {
          e.stopPropagation();
          pointerDownId = instance.id;
        };

        const onPointerUp = () => {
          pointerDownId == instance.id && onClick != null && onClick();
          pointerDownId = null;
        };

        const onClick = isClickable
          ? async () => {
            setActiveObjectIds([instance.id]);
            dispatch(reqSetActivePrecinctID(null));

            if (instance.cam_position) {
              const camPosition = threePosition(instance.cam_position);
              const camLookAtPosition =
                instance.cam_focus_point_position != null
                  ? threePosition(instance.cam_focus_point_position)
                  : position;
              handleAreaClick(controls, camLookAtPosition, camPosition);
            }
            if (instance?.modal) {
              dispatch(reqSetIsShowExploreModal(true));
              dispatch(reqSetExploreModal(instance?.modal));
            }
            if (instance?.sub_precinct) {
              dispatch(reqSetActiveTransportOption([instance?.sub_precinct]));
            } else {
              dispatch(reqSetActiveTransportOption([]));
            }
          }
          : null;

        if (mesh_.material) {
          mesh_.material.envMapIntensity = environmentMap.intensity;
          mesh_.material.depthWrite = true;
        }

        let meshInstance = (
          <mesh
            ref={ref => {
              if (ref) {
                const included = meshRefs.current.find(r => r.name === ref.name);
                if (!included) {
                  meshRefs.current = [...meshRefs.current, ref]
                }
              }
            }}
            key={index}
            {...mesh_}
            layers={instance.layer != null ? instance.layer : null}
            userData={mesh_.userData}
            name={instance.id}
            onPointerDown={instance.type && onPointerDown}
            onPointerUp={instance.type && onPointerUp}
            onPointerOut={instance.type && onPointerOut}
            onPointerOver={instance.type && onPointerOver}
            position={model.position}
            quaternion={model.quaternion}
            rotation={model.rotation}
            scale={[
              model.scale.x * mesh_.scale.x,
              model.scale.y * mesh_.scale.y,
              model.scale.z * mesh_.scale.z
            ]}
          >
          </mesh>
        );
        return meshInstance;
      });
    };

    function GLTFModel() {
      if (!isIntroduction) {
        return <group />;
      }

      if (light.current != null) {
        light.current.layers.enableAll();
      }

      return (
        <group ref={refScene}>
          {Object.keys(meshInstanceMap).map((entry) => {
            const targetMap = meshInstanceMap[entry];
            if (!targetMap) return;
            const model = targetMap.model;
            const instances = targetMap.instances;
            return instances.map((instance) => {
              return RenderInstance(instance, model);
            });
          })}
        </group>
      );
    }

    const AnimationCamera = memo((props) => {
      const { animation3dSetting, controls } = props;
      const { camera } = useThree();

      const targetPosition =
        animation3dSetting != null && animation3dSetting.cam_position != null
          ? threePosition(animation3dSetting.cam_position)
          : new THREE.Vector3(
            -92.46747002504912,
            260.2837561175679,
            391.6135906913746
          );
      const delta = new Vector3(
        -200 - targetPosition.x,
        270 - targetPosition.y,
        -630 - targetPosition.z
      );

      const pipeSpline = new THREE.CatmullRomCurve3([
        new THREE.Vector3(820 - delta.x, 810 - delta.y, 0 - delta.z),
        new THREE.Vector3(200 - delta.x, 330 - delta.y, -226 - delta.z),
        new THREE.Vector3(0 - delta.x, 285 - delta.y, -331 - delta.z),
        targetPosition
      ]);

      setCameraAnimated(true);

      camera.position.copy(
        new THREE.Vector3(4620 - delta.x, 1200 - delta.y, 0 - delta.z)
      );
      camera.updateProjectionMatrix();

      timeVector3.x = 0;
      timeVector3.y = 0;
      timeVector3.z = 0;

      return <group />;
    });
    AnimationCamera.displayName = "AnimationCamera";

    const CameraControls = memo(() => {
      const { camera, gl, raycaster, scene } = useThree();
      const domElement = gl.domElement;

      useEffect(() => {
        setTimeout(() => {
          if (scene) {
            scene.isLoaded = true;
          }
        }, 5000);
      }, [])
      
      gl.localClippingEnabled = true;
      gl.info.autoReset = false;
      // Set max canvas resolution to 1080p without forcing container style updates
      useThree().gl.setSize(
        Math.min(window.innerWidth, 1280),
        Math.min(window.innerHeight, 720),
        false
      );


      // if (isIntroduction) {
      //   glS = new glStats(); // init at any point
      //   tS = new threeStats(gl); // init after WebGLRenderer is created

      //   rS = new rStats({
      //     userTimingAPI: true,
      //     values: {
      //       frame: { caption: "Total frame time (ms)", over: 16 },
      //       fps: { caption: "Framerate (FPS)", below: 30 },
      //       calls: { caption: "Calls (three.js)", over: 3000 },
      //       raf: { caption: "Time since last rAF (ms)" },
      //       rstats: { caption: "rStats update (ms)" }
      //     },
      //     groups: [
      //       { caption: "Framerate", values: ["fps", "raf"] },
      //       {
      //         caption: "Frame Budget",
      //         values: ["frame", "texture", "setup", "render"]
      //       },
      //     ],
      //     fractions: [{ base: "frame", steps: ["action1", "render"] }],
      //     plugins: [tS, glS]
      //   });

      // useFrame(({ gl, scene, camera }) => {
      //   if (!isShowControl && scene?.isLoaded) {
      //     return;
      //   }

      //   rS("frame").start();
      //   glS.start();

      //   rS("frame").start();
      //   rS("rAF").tick();
      //   rS("FPS").frame();

      //   rS("action1").start();
      //   rS("action1").end();

      //   rS("render").start();
      //   gl.render(scene, camera);
      //   rS("render").end();

      //   rS("frame").end();
      //   rS().update();

      //   gl.info.reset();
      // }, 1);
      // }

      useFrame(() => {
        if (controls.current?.needReloadSelectedHotspotId) {
          setSelectedHotspotId("")
          updateHotspot();
          controls.current.needReloadSelectedHotspotId = false;
        }

        // if (isDragging3D) {
        sendCameraPos();

        // }

        if (!isCameraAnimation && isCameraAnimated && !isPresentation) {
          if (controls != null && controls.current != null) {
            controls.current.update();
          }
          return;
        }

        if (!isShowControl && scene?.isLoaded) {
          return;
        }
      });

      let x = targetPosition.x;
      let y = targetPosition.y;
      let z = targetPosition.z;

      if (camera.targetPosition) {
        x = camera.targetPosition.x;
        y = camera.targetPosition.y;
        z = camera.targetPosition.z;
      }

      return (
        <orbitCustomControls
          ref={(r) => {
            controls.current = r;
            //FirstAction();
          }}
          // updateDragging3D={updateDragging3D}
          args={[camera, domElement, [0, 0, 0], [0, 0, 0]]}
          raycaster={raycaster}
          disabledUpdate={isIntroduction && !isCameraAnimated}
          neverUpdate={false}
          autoRotate={false}
          enableDamping={true}
          maxDistance={600}
          minDistance={2}
          zoomSpeed={2}
          rotateSpeed={0.8}
          minZoom={_3dSetting.minZoom ?? 0.2}
          maxZoom={_3dSetting.maxZoom ?? 8}
          minHeight={10}
          maxHeight={1000}
          movingCurveSpeed={1 ?? 0.5}
          target={[x, y, z]}
          enabled={!isPresentation}
        />
      );
    });
    CameraControls.displayName = "CameraControls";



    function ToneMapping() {
      const { gl, scene } = useThree(({ gl, scene }) => ({ gl, scene }));
      useEffect(() => {
        gl.toneMapping = toneMapping.toneMapping;
        gl.toneMappingExposure = toneMapping.exposure;
        scene.traverse((object) => {
          if (object.material) {
            object.material.needsUpdate = true;
          }
        });
      }, [toneMapping]);
      return <></>;
    }

    const isShowControl = page === PAGES.LANDING_PAGE || page === PAGES.UNIT_EXPLORER_PAGE

    return (
      <>
        {authUser?.webGLArtist  && (
          <div
            ref={guiContainerRef}
            style={{
              position: "absolute",
              right: "1rem",
              zIndex: isShowControl ? 1 : -1,
              opacity: isShowControl ? 1 : 0,
              pointerEvents: isShowControl? 'unset': 'none',
              top: "4rem"
            }}
          />
        )}
        {
          isShowRightPanel && page === PAGES.AMENITIES_PAGE && <>
            <img
              src={"/icons/expand-image.svg"} 
              alt="modal-expand-image"
              className="modal-expand-image"
              style={{
                position: "absolute",
                top: "15.5rem",
                right: "1rem",
                zIndex: 1000
              }}
              onClick={handleShowModalImage}
            /> 
            {
              modal.virtualTour && (
              <div style={{
                position: "absolute",
                bottom: "12rem",
                right: "2.25rem",
                zIndex: 1000,
                height: 60,
                width: "13rem",
                padding: "0 22.5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #242422",
                fontFamily: "Suisse Intl",
                color: "#242422",
                textTransform: "uppercase",
                cursor: "pointer"
              }}
              onClick={handleClickVirtualTour}
              >
              Virtual tour
              <img src={rightArrowIcon} alt=""/>
            </div>
          )}
          </>
        }

        <Canvas
          style={{ backgroundColor: "#B1CAE8" }}
          gl={{
            outputEncoding: THREE.sRGBEncoding,
            logarithmicDepthBuffer: true,
          }}
          dpr={Math.max(window.devicePixelRatio, 2)}
          camera={{
            position: [
              1020 + _3dSetting.cam_position.x,
              540 + _3dSetting.cam_position.z,
              630 - _3dSetting.cam_position.y
            ],
            fov: _3dSetting.FOV,
            near: 1,
            far: 10000
          }}
          onPointerEnter={(e) => (pointerDown = true)}
          onPointerLeave={(e) => (pointerDown = false)}
          onTouchStart={(e) => (pointerDown = true)}
          onTouchEnd={(e) => (pointerDown = false)}
          onWheel={() => sendCameraPos()}
        >
          <ToneMapping />
          {isIntroduction && !isCameraAnimated && (
            <AnimationCamera
              animation3dSetting={_3dSetting}
              controls={controls}
            />
          )}
          <ambientLight
            intensity={ambientLight.intensity}
            color={ambientLight.color}
          />
          <React.Suspense fallback={null}>
            <GLTFModel />
            <Hotspot selectedHotspotId={selectedHotspotId} />
          </React.Suspense>
          <CameraControls />
          <SkyModel ref={sky} />
          { environmentMap.hasMap && <Environment map={envMap} /> }
        </Canvas>
      </>
    );
  })
);

CanvasBox.displayName = "CanvasBox";

export default CanvasBox;
