import React, { useEffect, useRef, useState } from "react";

import { getUploadedServerMediaUrl } from "../../../helper/media";
import { IconPlay } from "../../svgs/icons";
import { AwModal } from "../../modal-aw/aw-modal";
import galleryApi from '../../../apis/api/gallery';
import "../../home-gallery/index.scss";
import "./index.scss";
import socket from "../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";

const Films = ({ isInfinity, isPresentation }) => {
  const [isShowVideo, setShowVideo] = useState(false);
  const [urlVideo, setUrlVideo] = useState("");
  const videoRef = useRef(null);
  const [filmRender, setFilmRender] = useState([]);

  const handleGetFilmRender = async (type) => {
    try {
      const res = await galleryApi.getListGalleryFloor({
        'type[equal]': type,
        "category[equal]": 'films'
      });
      if (res) {
        setFilmRender(res.data);
      }
    } catch (error) {
      console.log('error get films');
    }
  };

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.PLAY_VIDEO) {
      handlePlayVideo(content.data.url);
    }
    if (content.action === ACTION_NAME.STOP_VIDEO) {
      onStopVideo();
    }
  };
  useEffect(() => {
    handleGetFilmRender();

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const handlePlayVideo = (url) => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PLAY_VIDEO, {
        url
      })
    }
    setShowVideo(true);
    setUrlVideo(url);
  };

  const onStopVideo = () => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.STOP_VIDEO)
    }
    setShowVideo(false);
    setUrlVideo("");
  };

  return (
    <div className="films-content">
      {filmRender?.filter(a => a.media?.find(media => media.type === 'image')?.isActive).sort((a, b) => (a.media?.[0]?.order ?? a.media?.[1]?.order ?? 999) - (b.media?.[0]?.order ?? b.media?.[1]?.order ?? 999)).map((i, idx) => {
        return (
          <div
            key={`films-${idx}`}
            // data-fancybox="gallery"
            className={`card-films ${isInfinity && "infinity"}`}
            style={{
              backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${getUploadedServerMediaUrl(
                i.media.find(media => media.type === 'image')?.path
              )})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: "lightgray",
            }}
            onClick={() => handlePlayVideo(i.media.find(media => media.type === 'video')?.path)}
          >
            {/*<span className="sub">{i.description}</span>*/}
            {/*<span className="title">{`${data.name} ${i.name}`}</span>*/}
            {/*<div className="divider" />*/}
            <IconPlay style={{ width: 43, height: 50 }} />
          </div>
        );
      })}
      {isShowVideo && (
        <AwModal show={true} onClose={onStopVideo}>
          <div className="wrap-video-film">
            <video
              loop
              ref={videoRef}
              autoPlay={true}
              preload="auto"
              muted={isPresentation}
              // style={{ backgroundImage: `url(${getImagePath(urlBg)})` }}
            >
              <source src={getUploadedServerMediaUrl(urlVideo)} type="video/mp4" />
            </video>
          </div>
        </AwModal>
      )}
    </div>
  );
};
export default Films;
