import './index.scss';
import Markdown from "react-markdown";
import React, { useState } from 'react';
import { FAQSArrowDown, FAQSArrowUp } from '../svgs/icons';

export const CollapsibleContent = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const onToggle = () => setIsCollapsed(!isCollapsed);
  return (
    <div className="content">
      <div className="tg-container">
        <a
          className="tg-header"
          onClick={onToggle}
        >
          <span>{props.title}</span>
          {isCollapsed ? (
            <FAQSArrowUp style={{ width: 31 }} fill={"#2D2927"} />
          ) : (
            <FAQSArrowDown style={{ width: 31 }} fill={"#2D2927"} />
          )}
        </a>
        <div className="tg-breaker"/>
        <div
          className={`tg-body collapse standardServices ${isCollapsed ? "show" : ''}`}
        >
          <Markdown>{props.content}</Markdown>
        </div>
      </div>
    </div>
  );
};
