import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./index.scss";
import { memo } from "react";
import {
  reqSetExploreModal,
  reqSetIsShowExploreModal,
} from "../../reduxs/explore-modal/action";
import { useSelector } from "react-redux";
import {
  reqSetActiveTransportOption,
  reqSetSubPrecincts,
  reqSetTransportOptions,
} from "../../reduxs/home/action";
const AmenitiesOption = memo((props) => {
  const { handleClickTransportOptions } = props;
  const dispatch = useDispatch();
  const transportOptions = useSelector((state) => state.home.transportOptions);
  const subPrecincts = useSelector((state) => state.home.subPrecincts);
  const activeTpId = useSelector((state) => state.home.activeTransportOption);
  const activePrecinctId = useSelector(
    (state) => state.transportOption.activePrecinctId
  );
  const page = useSelector((state) => state.home.page);

  useEffect(() => {
    dispatch(reqSetTransportOptions());
    dispatch(reqSetSubPrecincts());
  }, []);


  const onClick = (transportOption) => {
    let newArray = [].slice();
    let newActiveTpIds = activeTpId.slice();
    if (newActiveTpIds.includes(transportOption.id)) {
      let index = newActiveTpIds.indexOf(transportOption.id);
      if (index !== -1) {
        newActiveTpIds.splice(index, 1);
      }
    } else {
      if (newActiveTpIds.length > 0) {
        newActiveTpIds.pop()
      }
      newActiveTpIds.push(transportOption.id);
    }

    let lastTPModal = null;
    let maxIndex = -1;

    let activeColorModel = {};
    transportOptions.forEach((tp) => {
      if (newActiveTpIds.includes(tp.id)) {
        let needCheckedIndex = newActiveTpIds.indexOf(tp.id);

        (tp.asset || []).forEach((a) => {
          if (!newArray.includes(a)) {
            newArray.push(a);
          }
          if (
            activeColorModel["" + a] == null ||
            needCheckedIndex > activeColorModel["" + a].index
          ) {
            activeColorModel["" + a] = {
              colorCode: tp.activeAssetColor,
              index: needCheckedIndex,
            };
          }
        });

        if (needCheckedIndex > maxIndex) {
          maxIndex = needCheckedIndex;
          lastTPModal = tp.modal;
        }
      }
    });

    subPrecincts.forEach((tp) => {
      if (activePrecinctId == tp.id) {
        (tp.asset || []).forEach((a) => {
          if (!newArray.includes(a)) {
            newArray.push(a);
          }
          if (activeColorModel["" + a] == null) {
            activeColorModel["" + a] = {
              colorCode: tp.activeAssetColor,
              index: 0,
            };
          }
        });
        if (lastTPModal == null) {
          lastTPModal = tp.modal;
        }
      }
    });

    handleClickTransportOptions(newArray, activeColorModel);
    dispatch(reqSetExploreModal(lastTPModal));
    dispatch(
      reqSetIsShowExploreModal(
        newActiveTpIds.length > 0 || activePrecinctId != null
      )
    );
    dispatch(reqSetActiveTransportOption(newActiveTpIds));
  };

  const getActiveClass = (tp) => {
    return activeTpId.includes(tp.id) ? "active" : "";
  };

  const fillTransportOptions = () => {
    if (transportOptions.length % 2 !== 0) {
      return (
        <li>
          <div className="transport-item transparent" />
        </li>
      );
    }
  };

  const getActiveColor = (tp) => {
    return activeTpId.includes(tp.id) ? tp.activeColor : "#C4C4C4";
  };

  return (
    <div className="wrap-explore-amenities">
      <div className="wrap-list-transport">
        <h4 className="card-title">AMENITIES</h4>
        <div className="card-body">
          <ul className="list-transport list-1col">
            {transportOptions.map((tp) => {
              if (!tp.name.includes("amenities")) return null;
              return (
                <li key={`transport-${tp.id}`}>
                  <div
                    className={`transport-item transport-item-${tp.name
                      } ${getActiveClass(tp)}`}
                  >
                    <span onClick={() => onClick(tp)}>
                      <svg
                        width="47"
                        height="47"
                        viewBox="0 0 47 47"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        dangerouslySetInnerHTML={{ __html: tp.svgPath }}
                      />
                    </span>
                  </div>
                </li>
              );
            })}
            {fillTransportOptions()}
          </ul>
        </div>
      </div>
      <div className="wrap-list-transport">
        <h4 className="card-title">TRANSPORT OPTIONS</h4>
        <div className="card-body">
          <ul className="list-transport list-1col">
            {transportOptions.map((tp) => {
              if (tp.name.includes("amenities")) return null;
              return (
                <li key={`transport-${tp.id}`}>
                  <div
                    className={`transport-item transport-item-${tp.name
                      } ${getActiveClass(tp)}`}
                    style={{
                      backgroundColor: getActiveColor(tp),
                      borderColor: getActiveColor(tp),
                    }}
                  >
                    <span onClick={() => onClick(tp)}>
                      <svg
                        width="47"
                        height="47"
                        viewBox="0 0 47 47"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        dangerouslySetInnerHTML={{ __html: tp.svgPath }}
                      />
                    </span>
                  </div>
                </li>
              );
            })}
            {fillTransportOptions()}
          </ul>
        </div>
      </div>
    </div>
  );
});
AmenitiesOption.displayName = "AmenitiesOption";

export default AmenitiesOption;
