import React, { useEffect } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import {
    useLoader
  } from "@react-three/fiber";
import { useDispatch } from "react-redux";
import * as THREE from "three";
import { receiveEnvMap, receiveGLTFModels, setIsLoading } from "../../reduxs/scene/action";
import assetApi from '../../apis/api/asset';
import _3dSettingsApi from '../../apis/api/_3dSettings';
import { openDatabase, getFile, saveFile, deleteFile } from './GLTF3DCache';

const GLTF3DLoader = () => {
    const dispatch = useDispatch();
    
    const [envMap] = useLoader(THREE.TextureLoader, [
        "uploads/env_maps/env-map.jpg"
    ]);
    envMap.mapping = THREE.EquirectangularReflectionMapping;
    useEffect(async () => {
        const assets = await assetApi.getAssetsList();
        const settings = await _3dSettingsApi.get3DSettings();
        const { gltf_file_name: fileName, gltf_file_version: fileVersion } = settings.data[0];
        
        const initScene = (gltf) => {
            const models = gltf.scene.children.map(c => {
                if (assets && assets.data) {
                    const asset = assets.data.find(a => a.name === c.children[0]?.name);
                    if (asset) {
                        const userData = {
                            alpha: asset.alpha != null ? asset.alpha / 100.0 : 1.0,
                            hover_alpha: asset.hover_alpha != null ? asset.hover_alpha / 100.0 : 1,
                            active_alpha: asset.active_alpha != null ? asset.active_alpha / 100.0 : 1.0,
                            color: asset.color ?? "#999999",
                            hover_color: asset.hover_color ?? asset.color,
                            active_color: asset.active_color ?? asset.color,
                            isActive: c.children[0].userData?.isActive,
                            layer: asset.layer,
                            emissive: asset.emissive ?? "#000000",
                            active_emissive: asset.active_emissive ?? "#554e2d",
                        };
                        c.children[0].userData = userData;
    
                        if (c.children[0].material) {
                            if (asset.roughness || asset.roughness === 0) c.children[0].material.roughness = asset.roughness; 
                            if (asset.metalness || asset.roughness === 0) c.children[0].material.metalness = asset.metalness; 
                            if (asset.opacity || asset.roughness === 0) c.children[0].material.opacity = asset.opacity; 
                            if (asset.color) c.children[0].material.color = new THREE.Color(asset.color);
                            if (asset.emissive) c.children[0].material.emissive = new THREE.Color(asset.emissive);
                        }
                    }
                }
    
                return {
                    ...c,
                    name: c.children[0]?.name || "",
                }
            })
            dispatch(receiveGLTFModels(models));
            setTimeout(() => {
                dispatch(setIsLoading(false));
            }, 12000);
        }

        let loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath("/draco/");
        loader.setDRACOLoader(dracoLoader);

        const db = await openDatabase();
        const cachedFile = await getFile(db, fileName);

        if (cachedFile && cachedFile.version === fileVersion) {
            // Load model from cache
            const blobUrl = URL.createObjectURL(cachedFile.file);
            loader.load(blobUrl, (gltf) => {
              initScene(gltf);

              URL.revokeObjectURL(blobUrl);
            });

        } else {
            try {
                // Load models from network
                const response = await fetch(`/uploads/${fileName}`);
                const arrayBuffer = await response.arrayBuffer();
                const blob = new Blob([new Uint8Array(arrayBuffer)], { type: 'model/gltf-binary' });
                const blobUrl = URL.createObjectURL(blob);

                loader.load(blobUrl, async (gltf) => {
                    initScene(gltf);

                    URL.revokeObjectURL(blobUrl);
                })

                // Delete old cache, and save new file
                if (cachedFile) {
                    await deleteFile(db, fileName);
                }

                await saveFile(db, fileName, blob, fileVersion);
            } catch(error) {
                console.error("Error fetching the model:", error);
            }
        }
    }, [])

    useEffect(() => {
        dispatch(receiveEnvMap(envMap));
    }, [envMap])
    return(
        <></>
    )
};
export default GLTF3DLoader;
