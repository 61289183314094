import React from "react";
import "./index.scss";

const VideoIntro = ({ onSkipReplayVideo, isPresentation }) => {

  const onSkipVideo = () => {
    onSkipReplayVideo();
  };

  return (
    <div className="wrap-video-intro">
      {/* <video
        ref={videoRef}
        autoPlay={true}
        onEnded={onSkipVideo}
        preload="auto"
        id="intro-video-2"
        muted={isPresentation}
      >
        <source src="/uploads/videos/2269289e.mp4" type="video/mp4" />
      </video> */}
      <img className="intro" src="/uploads/images/intro.png" alt="intro" />
      <img className="play" src="/uploads/images/play.svg" alt="play" />
      <div onClick={onSkipVideo} className="btn-skip-video btn btn-light">
        <span>SKIP FILM</span>
        <img src="/uploads/icons/arrow-right.svg" alt="arrow" />
      </div>
    </div>
  );
};

export default VideoIntro;
