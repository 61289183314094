import React, { useState, Fragment, useEffect, useRef } from "react";
import { Accordion } from "react-bootstrap";
import Markdown from "react-markdown";

import ProjectTeamModal from "./components/ProjectTeamModal";
import { TopNavTitle } from "../top-nav/top-nav-title";

import "./index.scss";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import imageFeatures1 from "../../assets/images/image-features-1.jpg";

const FeatureTabContent = {
  header: "FEATURE",
  timeless_design: "TIMELESS DESIGN",
  exceptional_style: "EXCEPTIONAL STYLE.",
  apartment_features: "APARTMENT FEATURES",
  paragraph2_title: "Apartments come white box fitted as standard.\nFully-fitted and furnished options, inspired by design of acclaimed Kristina Zanić Consultants, are available*",
  paragraph2_description: "*Fitted with walls, ceilings, floorings and bathrooms.\nE xcludes kitchens, wardrobes and internal door frames.\nFully furnished options are contracted and delivered through a third party supplier.",
  paragraph1: "Established in 2012, PASHA Development is involved in the acquisition, development, and management of real estate assets across the world.",
  paragraph2: "The company’s diverse portfolio comprises residential, resort, hospitality and office properties. The portfolio includes the ultra-luxury Portonovi resort in Montenegro, many of Baku’s most iconic existing and future residential addresses including the Port Bay Harbor Residences and the highly-anticipated Crescent Bay, and the city’s most sought-after business centres such as Port Bay Harbor Towers.",
  paragraph3: "Working with strategic hospitality partners - comprising One&Only, Mandarin Oriental, Four Seasons, and The Ritz-Carlton - the company is responsible for more than 15 luxury hotels. With its proven track record and award-winning portfolio, PASHA Development is considered to be Azerbaijan’s leading property developer.",
  markdown_paragraph: "\n\n-   Apartments can be white box fitted* or fully furnished via a third party\n\n-   Marble bathrooms with walk-in steam shower & free-standing baths\n\n-    Varying floor plans to suit individual living styles\n\n-   High-quality engineered oak herringbone flooring throughout\n\n-   Timber entrance door\n\n-   Master bedrooms include ample space for wardrobes\n\n-   Floor-to-ceiling windows frame sea or city views\n\n-   24-hour onsite monitoring with CCTV\n\n-   Formal dining areas for entertaining\n\n-   Private basement-level parking\n\n**Fitted with walls, ceilings, floorings and bathrooms. Excludes kitchens, wardrobes and internal door frames.\nFully furnished options are contracted and delivered through a third party supplier.*\n ",
  markdown_paragraph2: "\n\n**Living Spaces**\n\n-   Solid timber entrance door\n\n-   Floor plans offer a wide range of choices to cater to individual lifestyles\n\n**Flooring**\n\n-   Oak parquet herringbone flooring flows throughout corridors, kitchens and bedrooms\n\n-   Bathrooms feature either marble stone of Calacatta Oro or Eramosa across floors and walls\n\n-   Laundry room features dark grey ceramic tiling\n\n**Walls and Ceilings**\n\n-   Two-layered walls and ceilings are constructed using 12mm gypsum board\n\n-   Enhanced architectural aesthetics with drop ceilings feature recessed lighting\n\n-   Jotun hygiene matt paint - a 100% pure acrylic-based emulsion paint - allows for superior indoor air quality\n\n**Bathroom & Ensuite**\n\n-   Chrome Hansgrohe showerhead, tapware and accessories\n\n-   White freestanding oval baths from Antonio Lupi\n\n-   Showers equipped with glass partition\n\n-   Mirrored vanity unit with a marble countertop and open storage\n\n-   Under-mounted Bianco Duravit washbasins\n\n-   Vitra wall-mounted WC with a dual Geberit Sigma flush plate\n\n**Lighting & Electrical**\n\n-   High-efficacy round spotlights throughout apartments\n\n-   Electrical sockets finished in brushed metal look\n\n**Heating, Cooling & Ventilation**\n\n-   Air conditioning units from Altherm\n\n-   Air-cooled liquid chillers from Carrier for additional comfort - High-efficiency Viessmann boiler\n\n**IT**\n\n-   All apartments have access to fast-fibre data networks\n",
  markdown_paragraph2_1: "\n\n**Living Spaces**\n\n-   Solid timber entrance door\n\n-   Floor plans offer a wide range of choices to cater to individual lifestyles\n\n**IT**\n\n-   All apartments have access to fast-fibre data networks\n",
  markdown_paragraph2_2: "\n\n**Flooring**\n\n-   Oak parquet herringbone flooring flows throughout corridors, kitchens and bedrooms\n\n-   Bathrooms feature either marble stone of Calacatta Oro or Eramosa across floors and walls\n\n-   Laundry room features dark grey ceramic tiling\n",
  markdown_paragraph2_3: "\n\n**Walls and Ceilings**\n\n-   Two-layered walls and ceilings are constructed using 12mm gypsum board\n\n-   Enhanced architectural aesthetics with drop ceilings feature recessed lighting\n\n-   Jotun hygiene matt paint - a 100% pure acrylic-based emulsion paint - allows for superior indoor air quality\n",
  markdown_paragraph2_4: "\n\n**Bathroom & Ensuite**\n\n-   Chrome Hansgrohe showerhead, tapware and accessories\n\n-   White freestanding oval baths from Antonio Lupi\n\n-   Showers equipped with glass partition\n\n-   Mirrored vanity unit with a marble countertop and open storage\n\n-   Under-mounted Bianco Duravit washbasins\n\n-   Vitra wall-mounted WC with a dual Geberit Sigma flush plate\n",
  markdown_paragraph2_5: "\n\n**Lighting & Electrical**\n\n-   High-efficacy round spotlights throughout apartments\n\n-   Electrical sockets finished in brushed metal look\n",
  markdown_paragraph2_6: "\n\n**Heating, Cooling & Ventilation**\n\n-   Air conditioning units from Altherm\n\n-   Air-cooled liquid chillers from Carrier for additional comfort - High-efficiency Viessmann boiler\n\n-   High-efficiency Viessmann boiler\n",
  paragraph2_header: "Typical apartment specifications"
}

const FeatureTab = ({ isPresentation }) => {
  const [showProjectTeamModal, setShowProjectTeamModal] = useState(false);
  const [activeKeys, setActiveKeys] = useState([])

  const pageRef = useRef(null)

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.OPEN_FEATURE_DETAIL) {
      handleOpenFeature(content.data.currentActiveKeys, content.data.section);
    }
    if (content.action === ACTION_NAME.CLOSE_FEATURE_DETAIL) {
      handleCloseFeature(content.data.currentActiveKeys, content.data.section);
    }
    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollTop * pageRef.current.scrollHeight;
      }
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const handleToggle = (section) => {
    if (activeKeys.includes(section)) {
      handleCloseFeature(activeKeys, section)
    } else {
      handleOpenFeature(activeKeys, section)
    }
  }

  const handleOpenFeature = (currentActiveKeys, section) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.OPEN_FEATURE_DETAIL, {
        currentActiveKeys,
        section
      })
    }
    setActiveKeys([...currentActiveKeys, section])
  }

  const handleCloseFeature = (currentActiveKeys, section) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_FEATURE_DETAIL, {
        currentActiveKeys,
        section
      })
    }
    const temp = currentActiveKeys.filter(x => x !== section)
    setActiveKeys(temp)
  }

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  return (
    <div ref={pageRef} onScroll={onScroll} className={`static-page-wf teamPage ${isPresentation && "presentation"}`}>
      <div className="main">
        <TopNavTitle title="FEATURE" />
        <div className="header">
          {FeatureTabContent.timeless_design}
          <br />
          {FeatureTabContent.exceptional_style}
        </div>
        <img
          style={{ width: "100%", height: "100%", paddingBottom: 50 }}
          alt="imageFeatures1"
          src={imageFeatures1}
        />
        <div className="content apartment">
          <div className="content-message">
            <Accordion
              activeKey={activeKeys.includes("apartment") ? "apartment" : ""}
            >
              <Fragment key="list-group">
                <Accordion.Toggle
                  className={
                    "accordion-button accordion-sidebar-button txt-style"
                  }
                  eventKey="apartment"
                  onClick={() => handleToggle("apartment")}
                >
                  <span>{FeatureTabContent.apartment_features}</span>
                  {!activeKeys.includes("apartment") ? (
                    <svg
                      width="31"
                      height="16"
                      viewBox="0 0 31 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28.9699 0.309071C29.064 0.348068 29.1495 0.405228 29.2214 0.477279C29.2935 0.54925 29.3507 0.634715 29.3897 0.728791C29.4287 0.822867 29.4487 0.923709 29.4487 1.02555C29.4487 1.12739 29.4287 1.22823 29.3897 1.3223C29.3507 1.41638 29.2935 1.50185 29.2214 1.57382L15.2725 15.5227C15.2006 15.5948 15.1151 15.6519 15.021 15.6909C14.927 15.7299 14.8261 15.75 14.7243 15.75C14.6224 15.75 14.5216 15.7299 14.4275 15.6909C14.3334 15.6519 14.248 15.5948 14.176 15.5227L0.2271 1.57382C0.1551 1.50182 0.0979872 1.41634 0.0590212 1.32227C0.0200552 1.2282 0 1.12737 0 1.02555C0 0.923725 0.0200552 0.8229 0.0590212 0.728827C0.0979872 0.634755 0.1551 0.549278 0.2271 0.477279C0.2991 0.405279 0.384576 0.348166 0.478649 0.3092C0.572721 0.270235 0.673547 0.250177 0.77537 0.250177C0.877193 0.250177 0.978019 0.270235 1.07209 0.3092C1.16616 0.348166 1.25164 0.405279 1.32364 0.477279L13.9493 13.103L14.3366 13.4496L14.7238 13.7962L15.1115 13.4496L15.4992 13.103L28.1249 0.477279C28.1969 0.405228 28.2823 0.348068 28.3764 0.309071C28.4705 0.270072 28.5713 0.25 28.6732 0.25C28.775 0.25 28.8759 0.270072 28.9699 0.309071Z"
                        fill="#2D2927"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="31"
                      height="16"
                      viewBox="0 0 31 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28.9699 15.6909C29.064 15.6519 29.1495 15.5948 29.2214 15.5227C29.2935 15.4508 29.3507 15.3653 29.3897 15.2712C29.4287 15.1771 29.4487 15.0763 29.4487 14.9745C29.4487 14.8726 29.4287 14.7718 29.3897 14.6777C29.3507 14.5836 29.2935 14.4982 29.2214 14.4262L15.2725 0.477279C15.2006 0.405228 15.1151 0.34807 15.021 0.309071C14.927 0.270073 14.8261 0.25 14.7243 0.25C14.6224 0.25 14.5216 0.270073 14.4275 0.309071C14.3334 0.34807 14.248 0.405228 14.176 0.477279L0.2271 14.4262C0.1551 14.4982 0.0979872 14.5837 0.0590212 14.6777C0.0200552 14.7718 0 14.8726 0 14.9745C0 15.0763 0.0200552 15.1771 0.0590212 15.2712C0.0979872 15.3652 0.1551 15.4507 0.2271 15.5227C0.2991 15.5947 0.384576 15.6518 0.478649 15.6908C0.572721 15.7298 0.673547 15.7498 0.77537 15.7498C0.877193 15.7498 0.978019 15.7298 1.07209 15.6908C1.16616 15.6518 1.25164 15.5947 1.32364 15.5227L13.9493 2.89703L14.3366 2.55042L14.7238 2.20382L15.1115 2.55042L15.4992 2.89703L28.1249 15.5227C28.1969 15.5948 28.2823 15.6519 28.3764 15.6909C28.4705 15.7299 28.5713 15.75 28.6732 15.75C28.775 15.75 28.8759 15.7299 28.9699 15.6909Z"
                        fill="#2D2927"
                      />
                    </svg>
                  )}
                </Accordion.Toggle>
                <div className="mt-43" />
                <Accordion.Collapse eventKey="apartment">
                  <div className="content">
                    <div className="content-message">
                      <div className="paragraph">
                        <Markdown>
                          {FeatureTabContent.markdown_paragraph}
                        </Markdown>
                      </div>
                    </div>
                  </div>
                </Accordion.Collapse>
              </Fragment>
            </Accordion>
          </div>
        </div>

        <div className="content">
          <div className="content-message">
            <Accordion
              className="specification"
              activeKey={activeKeys.includes("specification") ? "specification" : ""}
            >
              <Fragment key="list-group">
                <Accordion.Toggle
                  className={
                    "accordion-button accordion-sidebar-button txt-style"
                  }
                  eventKey="specification"
                  onClick={() => handleToggle("specification")}

                >
                  <span>{FeatureTabContent.paragraph2_header}</span>
                  {!activeKeys.includes("specification") ? (
                    <svg
                      width="31"
                      height="16"
                      viewBox="0 0 31 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28.9699 0.309071C29.064 0.348068 29.1495 0.405228 29.2214 0.477279C29.2935 0.54925 29.3507 0.634715 29.3897 0.728791C29.4287 0.822867 29.4487 0.923709 29.4487 1.02555C29.4487 1.12739 29.4287 1.22823 29.3897 1.3223C29.3507 1.41638 29.2935 1.50185 29.2214 1.57382L15.2725 15.5227C15.2006 15.5948 15.1151 15.6519 15.021 15.6909C14.927 15.7299 14.8261 15.75 14.7243 15.75C14.6224 15.75 14.5216 15.7299 14.4275 15.6909C14.3334 15.6519 14.248 15.5948 14.176 15.5227L0.2271 1.57382C0.1551 1.50182 0.0979872 1.41634 0.0590212 1.32227C0.0200552 1.2282 0 1.12737 0 1.02555C0 0.923725 0.0200552 0.8229 0.0590212 0.728827C0.0979872 0.634755 0.1551 0.549278 0.2271 0.477279C0.2991 0.405279 0.384576 0.348166 0.478649 0.3092C0.572721 0.270235 0.673547 0.250177 0.77537 0.250177C0.877193 0.250177 0.978019 0.270235 1.07209 0.3092C1.16616 0.348166 1.25164 0.405279 1.32364 0.477279L13.9493 13.103L14.3366 13.4496L14.7238 13.7962L15.1115 13.4496L15.4992 13.103L28.1249 0.477279C28.1969 0.405228 28.2823 0.348068 28.3764 0.309071C28.4705 0.270072 28.5713 0.25 28.6732 0.25C28.775 0.25 28.8759 0.270072 28.9699 0.309071Z"
                        fill="#2D2927"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="31"
                      height="16"
                      viewBox="0 0 31 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28.9699 15.6909C29.064 15.6519 29.1495 15.5948 29.2214 15.5227C29.2935 15.4508 29.3507 15.3653 29.3897 15.2712C29.4287 15.1771 29.4487 15.0763 29.4487 14.9745C29.4487 14.8726 29.4287 14.7718 29.3897 14.6777C29.3507 14.5836 29.2935 14.4982 29.2214 14.4262L15.2725 0.477279C15.2006 0.405228 15.1151 0.34807 15.021 0.309071C14.927 0.270073 14.8261 0.25 14.7243 0.25C14.6224 0.25 14.5216 0.270073 14.4275 0.309071C14.3334 0.34807 14.248 0.405228 14.176 0.477279L0.2271 14.4262C0.1551 14.4982 0.0979872 14.5837 0.0590212 14.6777C0.0200552 14.7718 0 14.8726 0 14.9745C0 15.0763 0.0200552 15.1771 0.0590212 15.2712C0.0979872 15.3652 0.1551 15.4507 0.2271 15.5227C0.2991 15.5947 0.384576 15.6518 0.478649 15.6908C0.572721 15.7298 0.673547 15.7498 0.77537 15.7498C0.877193 15.7498 0.978019 15.7298 1.07209 15.6908C1.16616 15.6518 1.25164 15.5947 1.32364 15.5227L13.9493 2.89703L14.3366 2.55042L14.7238 2.20382L15.1115 2.55042L15.4992 2.89703L28.1249 15.5227C28.1969 15.5948 28.2823 15.6519 28.3764 15.6909C28.4705 15.7299 28.5713 15.75 28.6732 15.75C28.775 15.75 28.8759 15.7299 28.9699 15.6909Z"
                        fill="#2D2927"
                      />
                    </svg>
                  )}
                </Accordion.Toggle>
                <div className="mt-43" />
                <Accordion.Collapse eventKey="specification">
                  <>
                    <div>
                      <p className="paragraph2_title">
                        {FeatureTabContent.paragraph2_title}
                      </p>
                      <p className="paragraph2_description">
                        {FeatureTabContent.paragraph2_description}
                      </p>
                    </div>
                    <div className="content">
                      <div className="content-message">
                        <div className="paragraph">
                          {[...Array(6)].map((_, index) => (
                            <div key={`markdown-paragraph-${index}`}>
                              <Markdown>
                                {FeatureTabContent[`markdown_paragraph2_${index + 1}`]}
                              </Markdown>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                </Accordion.Collapse>
              </Fragment>
            </Accordion>
          </div>
        </div>
        {
          !isPresentation && <div className="bottom-top" />
        }
      </div>
      <ProjectTeamModal
        show={showProjectTeamModal}
        onHide={() => setShowProjectTeamModal(false)}
      />
    </div>
  );
};

export default FeatureTab;
