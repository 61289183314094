import React from 'react';
import { Link } from 'react-router-dom';

const SessionCreate = () => {

  return (
    <div className="card wrap-customer-create">
      <div className="card-header d-flex">
        <h6 className="title">CREATE SESSION #sessionNumber</h6>
      </div>
      <div className="card-body">
        <form className="wrap-customer-create">
          <div className="mb-3">
            <label htmlFor="session-name" className="form-label">
              Type
            </label>
            <select className="form-select" name="session-name" id="session-name">
              <option value="0">SALES AGENT NAME</option>
              <option value="1">NAME 1</option>
              <option value="2">NAME 2</option>
              <option value="3">NAME 3</option>
            </select>
            <small id="session-name-help" className="form-text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </small>
          </div>
          <div className="mb-3">
            <div className="session-item btn-new-session">
              <Link to={`/dashboard/customers`} className="btn-row">
                CUSTOMER NAME
              </Link>
            </div>
          </div>
          <div className="mb-3">
            <div className="session-item">SURNAME</div>
          </div>
          <div className="mb-3">
            <div className="session-item">MOBILE</div>
          </div>
          <div className="mb-3">
            <div className="session-item">EMAIL</div>
          </div>
          <div className="mb-3">
            <div className="session-item">SOURCE</div>
          </div>
          <div className="mb-3">
            <label htmlFor="note" className="form-label">
              Note
            </label>
            <textarea className="form-control" name="note" id="note" cols="30" rows="5" />
          </div>
          <div className="mb-3">
            <Link to={`/dashboard/sessions`} className="btn bt-light">
              Cancel
            </Link>
            <Link to="#" className="btn btn-primary">
              Save
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SessionCreate;
