export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};

export const handleCollapse = (target) => {
  const selector = target.getAttribute('data-target') || target.getAttribute('href');
  const targets = Array.from(document.querySelectorAll(selector));
  targets.forEach((target) => {
    target.classList.toggle('show');
  });
};

export const isTouchDevice = () => {
  return "ontouchstart" in window || "onmsgesturechange" in window;
};

export const clearFilterUnit = (detail = null) => {
  const event = new CustomEvent("UNIT_EXPLORER_PAGE", { detail });
  document.dispatchEvent(event);
}

export function numberWithCommas(x) {
    if (!x) return '';
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
