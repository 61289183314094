import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";

import ServicesModal from "../amenitiesV2/component/services-modal";
import { TopNavTitle } from "../top-nav/top-nav-title";
import { ChevronDown, ChevronUp } from "../svgs/icons";
import { handleCollapse } from "../../helper/utils";

import "./index.scss";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import imageServices1 from "../../assets/images/image-services-1.jpg";

const getSectionList = (t) => ({
  hotel: { header: ServicesContents.hotel, id: "id_hotel" },
  exclusive: { header: ServicesContents.exclusive, id: "id_exclusive" },
  privileged: { header: ServicesContents.privileged, id: "id_privileged" },
});

const ServicesContents = {
  title: "SERVICES",
  header: "EXPERIENCE THE LEGENDARY SERVICE OF THE RITZ-CARLTON",
  paragraph: "Service fuels the senses, fosters wellbeing, and fulfils every wish and want of an owner. Whether expressed or not, everything can be, and is, taken care of.",
  header2: "STANDARD SERVICES",
  paragraph2: "The following services are offered inclusive to all owners.",
  paragraph5: "Owners also obtain access to exclusive privileges at the best addresses around the world, including participating The Ritz-Carlton, Edition, St. Regis, W, The Luxury Collection, and JW Marriott hotels. Privileges consist of:",
  standardServicesCol1: {
    item1: "Dedicated 24-Hour Concierge Services",
    item2: "Shopping & Services Information",
    item3: "Reservations for Restaurants, Spa, Salon, Theatre & Entertainment",
    item4: "Activity Information & Reservations",
    item5: "Procurement of Floral Arrangements",
    item6: "Travel & Vacation Planning",
    item7: "The Ritz-Carlton Hotel Reservations",
    item8: "Overnight Delivery Service"
  },
  standardServicesCol2: {
    item1: "Newspaper & Magazine Delivery",
    item2: "Mail & Package Delivery",
    item3: "Wake-up Call Service",
    item4: "Daily Trash Removal",
    item5: "Doorman Assistance",
    item6: "Management of Homeowner’s Services",
    item7: "Unlimited Use of Pool, Steam Room & Fitness Center"
  },
  standardServicesCol3: {
    item1: "Common Area Engineering Services",
    item2: "24-Hour Staffed Security",
    item3: "24-Hour Valet Parking Available at Main Entrance of Hotel",
    item4: "Airline, Private Air & Helicopter Reservations",
    item5: "Airport & Ground Transportation Arrangements",
    item6: "Car & Limousine Rental"
  },
  hotel: "HOTEL SERVICES",
  hotelHeading: "The following are additional paid-for services that are available through The Ritz-Carlton, Bay Harbor.",
  markdown_paragraph2_1: "\n\n**Housekeeping Services:**\n\n-   Vacuum & Mop Floors\n\n-   Dust Interior, Strip Beds & Change Sheets\n\n-   Wash Dishes, Clean Bathrooms, Clean Patio, Clean Mirrors\n\n-   Oven, Cooktop & Refrigerator Cleaning\n\n-   Laundry As Requested\n\n-   In-Residence Dining\n\n-   In-Residence Catering\n",
  markdown_paragraph2_2: "\n\n**ENGINEERING SERVICES:**\n\n-   Minor Electrical & Plumbing\n\n-   Simple Electronic Hook-up\n\n-   Picture Hanging & Furniture Assembly\n\n-   LED Bulb Replacement & HVAC Filter Change\n\n-   Touch-up Paint\n\n-   Bulk Trash & Move-in Trash Removal\n\n-   Management of Services (e.g. Assistance With Utilities, Managing Third-Party Providers & Collecting Dues)\n",
  markdown_paragraph2_3: "\n\n**PARTY, MEETING & EVENT SERVICES:**\n\n-   Call-A-Chef Reservations (Hotel Chef)\n\n-   Usage of Meeting & Conference Rooms\n\n**ADDITIONAL AVAILABLE SERVICES (Not Standard or Charged In HOA Dues):**\n\n-   Residential VIP Signing Services\n\n-   Restaurants & Lounge\n\n-   Priority Access to Hotel’s Restaurants, Concierge Services & Retail Shopping\n",
  markdown_paragraph2_4: "\n\n**ADDITIONAL CONCIERGE SERVICES (Provided by 3rd Party & Paid Direct):**\n\n-   Move-in Coordination With Moving Company\n\n-   Move-in Utilities Coordination\n\n-   Emergency Key Service\n\n-   Programming Key Fobs & Cards\n\n-   Radio Cards & Garage Entrance\n\n-   Party, Function & Event Planning\n\n-   Business Equipment & Rental Arrangements\n\n-   Administrative & Personal Assistant Services\n",
  markdown_paragraph2_5: "\n-   Mail & Package Shipping\n\n-   Arrival Preparations\n\n-   Laundry & Dry Cleaning\n\n-   Translation Services\n\n-   Car Detailing\n\n-   Computer Technology Assistance\n\n-   Personal Shopping\n\n-   Vacant Residence Maintenance Program\n\n-   Grocery & Food Delivery\n",
  markdown_paragraph2_6: "\n-   In-Residence Pest Control\n\n-   Nanny & Childcare Arrangements\n\n-   Dog Walking\n\n-   Dog Grooming\n\n-   Grocery Shopping\n\n-   Catering (Not Provided By Hotel)\n\n-   Personal Trainer\n\n-   In-Residence Spa Treatment & Services\n\n-   ‘While I’m Away’ Package to Service & Manage Apartments When Vacant\n",
  exclusive: "EXCLUSIVE OFFERS AVAILABLE AT THE RITZ-CARLTON, BAY HARBOR",
  standardExclusiveCol: {
    item1: "20% Discount in Food & Beverage Venues",
    item2: "20% Discount on Spa Treatments",
    item3: "30% Discount on Laundry",
    item4: "10% Discount on Banquet Services (Includes Meeting Room & Shusha Ballroom Rental Fee, Daily Meeting Packages & Cocktail/Lunch/Dinner Packages)",
    item5: "Preferred Seating in the Hotel’s Restaurant",
    item6: "Dedicated Catering Package for In-Residence Events  \u2028(e.g. Birthday Parties)",
    item7: "Special Daily Rate to Access The Ritz-Carlton Club Lounge",
    item8: "Residential VIP Signing Privileges Across Amenities"
  },
  privileged: "PRIVILEGED GLOBAL ACCESS",
  standardPrivilegedCol: {
    item1: "10% Off Regular Room Rate",
    item2: "Welcome Amenity and Note From General Manager",
    item3: "Upgrade at Time on Check-in (Based on Availability)",
    item4: "Complimentary High-Speed Internet Access",
    item5: "Daily Breakfast for Two",
    item6: "4PM Check-Out (Based on Availability)",
    item7: "Special Hotel Amenity",
    item8: "Owners Eligible for Upgrade to Platinum Elite Marriott Bonvoy Status (After Two Years of Ownership)"
  }
}

const getStandardServicesItems = (t) => ({
  standardServicesCol1: {
    item1: ServicesContents.standardServicesCol1.item1,
    item2: ServicesContents.standardServicesCol1.item2,
    item3: ServicesContents.standardServicesCol1.item3,
    item4: ServicesContents.standardServicesCol1.item4,
    item5: ServicesContents.standardServicesCol1.item5,
    item6: ServicesContents.standardServicesCol1.item6,
    item7: ServicesContents.standardServicesCol1.item7,
    item8: ServicesContents.standardServicesCol1.item8,
  },
  standardServicesCol2: {
    item1: ServicesContents.standardServicesCol2.item1,
    item2: ServicesContents.standardServicesCol2.item2,
    item3: ServicesContents.standardServicesCol2.item3,
    item4: ServicesContents.standardServicesCol2.item4,
    item5: ServicesContents.standardServicesCol2.item5,
    item6: ServicesContents.standardServicesCol2.item6,
    item7: ServicesContents.standardServicesCol2.item7,
  },
  standardServicesCol3: {
    item1: ServicesContents.standardServicesCol3.item1,
    item2: ServicesContents.standardServicesCol3.item2,
    item3: ServicesContents.standardServicesCol3.item3,
    item4: ServicesContents.standardServicesCol3.item4,
    item5: ServicesContents.standardServicesCol3.item5,
    item6: ServicesContents.standardServicesCol3.item6,
  },
});

const getStandardPrivilegedItems = (t) => ({
  standardPrivilegedCol: {
    item1: ServicesContents.standardPrivilegedCol.item1,
    item2: ServicesContents.standardPrivilegedCol.item2,
    item3: ServicesContents.standardPrivilegedCol.item3,
    item4: ServicesContents.standardPrivilegedCol.item4,
    item5: ServicesContents.standardPrivilegedCol.item5,
    item6: ServicesContents.standardPrivilegedCol.item6,
    item7: ServicesContents.standardPrivilegedCol.item7,
    item8: ServicesContents.standardPrivilegedCol.item8,
  },
});

const getStandardExclusiveItems = () => ({
  item1: ServicesContents.standardExclusiveCol.item1,
  item2: ServicesContents.standardExclusiveCol.item2,
  item3: ServicesContents.standardExclusiveCol.item3,
  item4: ServicesContents.standardExclusiveCol.item4,
  item5: ServicesContents.standardExclusiveCol.item5,
  item6: ServicesContents.standardExclusiveCol.item6,
  item7: ServicesContents.standardExclusiveCol.item7,
  item8: ServicesContents.standardExclusiveCol.item8,
});

const Services = ({ isPresentation }) => {
  const [isShowServicesModal, setShowServicesModal] = useState(false);
  const { t } = useTranslation();


  const standardServicesItems = getStandardServicesItems(t);
  const standardPrivilegedItems = getStandardPrivilegedItems(t);
  const standardExclusiveItems = getStandardExclusiveItems(t);
  const itemCountPerColumn = 4;
  const values = Object.values(standardExclusiveItems);
  const columns = Array.from({ length: 2 }, (_, index) =>
    values?.slice(index * itemCountPerColumn, (index + 1) * itemCountPerColumn)
  );

  const [activeKeys, setActiveKeys] = useState([])

  const pageRef = useRef(null)

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.OPEN_FEATURE_DETAIL) {
      handleOpenFeature(content.data.currentActiveKeys, content.data.section);
    }
    if (content.action === ACTION_NAME.CLOSE_FEATURE_DETAIL) {
      handleCloseFeature(content.data.currentActiveKeys, content.data.section);
    }
    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollTop * pageRef.current.scrollHeight;
      }
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const handleToggle = (section) => {
    if (activeKeys.includes(section)) {
      handleCloseFeature(activeKeys, section)
    } else {
      handleOpenFeature(activeKeys, section)
    }
  }

  const handleOpenFeature = (currentActiveKeys, section) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.OPEN_FEATURE_DETAIL, {
        currentActiveKeys,
        section
      })
    }
    setActiveKeys([...currentActiveKeys, section])
  }

  const handleCloseFeature = (currentActiveKeys, section) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_FEATURE_DETAIL, {
        currentActiveKeys,
        section
      })
    }
    const temp = currentActiveKeys.filter(x => x !== section)
    setActiveKeys(temp)
  }

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  return (
    <div ref={pageRef} onScroll={onScroll} className={`static-page-wf services-page ${isPresentation && "presentation"}`}>
      <TopNavTitle title={ServicesContents.title} />
      <div className="main-content">
        <div className="content-1">
          <div className="header">{ServicesContents.header}</div>
        </div>
        <img
          style={{ width: "100%", height: "100%", paddingBottom: 100 }}
          alt="imageServices1"
          src={imageServices1}
        />
        <div className="content-4">
          <div className="tg-container">
            <a
              className="tg-header"
              onClick={() => handleToggle("standardServices")}
            >
              <span >
                {ServicesContents.header2}
              </span>
              {
                activeKeys.includes("standardServices") ?
                  <ChevronUp
                    style={{ width: 31 }}
                    fill={"#2D2927"}
                  />
                  :
                  <ChevronDown
                    style={{ width: 31 }}
                    fill={"#2D2927"}
                  />
              }
            </a>
            <div
              style={{
                height: 1,
                width: "100%",
                background: "rgba(45, 41, 39, 0.50)",
              }}
            />
            <div
              className={`tg-body collapse standardServices ${activeKeys.includes("standardServices") && "show"}`}
              id="standardServices"
            >
              <div>{ServicesContents.paragraph2}</div>
            </div>
          </div>
        </div>
        <div className={`content-5 standardServices ${activeKeys.includes("standardServices") && "show"}`}>
          {Object.entries(standardServicesItems).map(([groupKey, groupVal]) => (
            <div key={groupKey} className="">
              <ul className="ctg-col">
                {Object.entries(groupVal).map(([itemKey, itemVal]) => (
                  <li key={itemKey}>
                    <div>{itemVal}</div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="content-8">
          <div className="tg-container">
            <a
              className="tg-header"
              onClick={() => handleToggle("standardHotel")}
            >
              <span>{ServicesContents.hotel}</span>
              {
                activeKeys.includes("standardHotel") ?
                  <ChevronUp
                    style={{ width: 31 }}
                    fill={"#2D2927"}
                  />
                  :
                  <ChevronDown
                    style={{ width: 31 }}
                    fill={"#2D2927"}
                  />
              }
            </a>
            <div
              style={{
                height: 1,
                width: "100%",
                background: "rgba(45, 41, 39, 0.50)",
              }}
            />
            <div className={`tg-body collapse standardHotel ${activeKeys.includes("standardHotel") && "show"}`} id="standardHotel">
              <div>{ServicesContents.hotelHeading}</div>
            </div>
          </div>
        </div>
        <div className={`content-9 standardHotel ${activeKeys.includes("standardHotel") && "show"}`}>
          <div className="content">
            <div className="content-message">
              <div className="paragraph">
                {[...Array(6)].map((_, index) => (
                  <div key={`markdown-paragraph-${index}`}>
                    <Markdown>
                      {t(`ServicesContents.markdown_paragraph2_${index + 1}`)}
                    </Markdown>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="content-6">
          <div className="tg-container">
            <a
              className="tg-header"
              onClick={() => handleToggle("standardExclusive")}
            >
              <span>
                {ServicesContents.exclusive}
              </span>
              {
                activeKeys.includes("standardExclusive") ?
                  <ChevronUp
                    style={{ width: 31 }}
                    fill={"#2D2927"}
                  />
                  :
                  <ChevronDown
                    style={{ width: 31 }}
                    fill={"#2D2927"}
                  />
              }
            </a>
            <div
              style={{
                height: 1,
                width: "100%",
                background: "rgba(45, 41, 39, 0.50)",
              }}
            />
          </div>
        </div>
        <div className={`content-7 standardExclusive ${activeKeys.includes("standardExclusive") && "show"}`}>
          {columns.map((column, columnIndex) => (
            <ul key={columnIndex} className="column">
              {column?.map((item, itemIndex) => (
                <li key={itemIndex} sx={{ marginTop: "10px", width: "50%" }}>
                  {item}
                </li>
              ))}
            </ul>
          ))}
        </div>
        <div className="content-4">
          <div className="tg-container">
            <a
              className="tg-header"
              onClick={() => handleToggle("standardPrivileged")}
            >
              <span>
                {ServicesContents.privileged}
              </span>
              {
                activeKeys.includes("standardPrivileged") ?
                  <ChevronUp
                    style={{ width: 31 }}
                    fill={"#2D2927"}
                  />
                  :
                  <ChevronDown
                    style={{ width: 31 }}
                    fill={"#2D2927"}
                  />
              }
            </a>
            <div
              style={{
                height: 1,
                width: "100%",
                background: "rgba(45, 41, 39, 0.50)",
              }}
            />
            <div
              className={`tg-body collapse standardPrivileged ${activeKeys.includes("standardPrivileged") && "show"}`}
              id="standardPrivileged"
            >
              <div className="">{ServicesContents.paragraph5}</div>
            </div>
          </div>
        </div>
        <div className={`content-5 standardPrivileged ${activeKeys.includes("standardPrivileged") && "show"}`}>
          {Object.entries(standardPrivilegedItems).map(
            ([groupKey, groupVal]) => (
              <div key={groupKey}>
                <ul className="privileged">
                  {Object.entries(groupVal).map(([itemKey, itemVal]) => (
                    <li key={itemKey}>{itemVal}</li>
                  ))}
                </ul>
              </div>
            )
          )}
        </div>
        {
          !isPresentation && <div className="bottom-top" />
        }
        <ServicesModal
          isShowServicesModal={isShowServicesModal}
          onHide={() => setShowServicesModal(false)}
        />
      </div>
    </div>);
};

export default Services;
