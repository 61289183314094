import Parser from 'html-react-parser';
import React, { useRef, useEffect, useContext, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  GoogleMap,
  useJsApiLoader,
  InfoWindow,
  Marker,
  Polygon,
  GroundOverlay,
  BicyclingLayer,
} from '@react-google-maps/api';
import { Accordion, AccordionContext, Card, useAccordionToggle } from 'react-bootstrap';
import _ from 'lodash';
import { config, mapStyles } from './map-config';
import { POIs as FAKE_DATA } from './test-data';
import socket from "../../helper/socket";

import './index.scss';
import { reqGetPoiList, reqSetActivePoi, reqSetActiveType } from '../../reduxs/locations/action';
import poi from '../../apis/api/poi';
import { RGBA_ASTC_10x5_Format } from 'three';
import { ACTION_NAME, WEBSOCKET_CHANNEL } from '../../constants/options';
import { reqSetIsShowExploreModal } from '../../reduxs/home/action';
const markerIconSVG = '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none"><rect x="0.707107" y="10.6064" width="14" height="14" transform="rotate(-45 0.707107 10.6064)" fill="white" stroke="black"/><rect x="5.30273" y="10.6064" width="7.5" height="7.5" transform="rotate(-45 5.30273 10.6064)" fill="black"/></svg>'
const activeMarkerIconSVG = '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none"><rect x="0.707107" y="10.8193" width="14" height="14" transform="rotate(-45 0.707107 10.8193)" fill="black" stroke="black"/><rect x="5.30273" y="10.8193" width="7.5" height="7.5" transform="rotate(-45 5.30273 10.8193)" fill="#E4FFFD"/></svg>'

function svgIconToURL(svg) {
  return 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg);
}

const markerIcon = {
  url: svgIconToURL(markerIconSVG),
};

const activeMarkerIcon = {
  url: svgIconToURL(activeMarkerIconSVG),
};

const activeMarkers = [];

let map;
let maps;

const onMapLoaded = (mapRef, mapsRef) => {
  map = mapRef;
  maps = mapsRef;
  map.setOptions({ styles: mapStyles.default.rules });
};

const Location = (props) => {
  const pois = useSelector((state) => state.poi.data).sort((a,b) => a.index - b.index);
  const activePoi = useSelector((state) => state.poi.activePoi);
  const activeType = useSelector((state) => state.poi.activeType);
  const dispatch = useDispatch();
  const mapRef = useRef(false);
  const pageRef = useRef(null);
  const { isPresentation } = props;
  const [activeCategory, setActiveCategory] = useState("");

  useEffect(async () => {
    dispatch(reqGetPoiList());
  }, []);

  useEffect(() => {
    // Set the color of the map
    const key = activeType === '' ? 'default' : activeType;
    const styles = mapStyles[Object.keys(mapStyles).includes(key) ? key : 'default'].rules;
    if (!!mapRef?.current?.state?.map) {
      mapRef.current.state.map.setOptions({ styles });
    }
  });

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CLICK_POI) {
      handleClickPoint(content.data.poi);
    }

    if (content.action === ACTION_NAME.CLICK_POI_ITEM) {
      handleShowItem(content.data.item);
    }

    if (content.action === ACTION_NAME.CLICK_POI_GROUP) {
      handleShowCategory(content.data.item);
    }

    if (content.action === ACTION_NAME.GOOGLE_MAP_ZOOM_CHANGED) {
      if (mapRef.current) {
        const isTV = window.screen.width > 2000;
        mapRef.current.state.map.setZoom(isTV ? content.data.zoom + 1 : content.data.zoom);
      }
    }

    if (content.action === ACTION_NAME.GOOGLE_MAP_CENTER_CHANGED) {
      if (mapRef.current) {
        mapRef.current.state.map.setCenter({
          lat: content.data.lat,
          lng: content.data.lng,
        });
      }
    }

    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      pageRef.current &&
        (pageRef.current.scrollTop =
          content.data.scrollTop * pageRef.current.scrollHeight);
    }
  };
  useEffect(() => {
    dispatch(reqGetPoiList());
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const showInfoWindow = (data) => {
    dispatch(reqSetActivePoi(data.id));
  };

  const handleClickSubPoint = (poi) => {
    // dispatch(reqSetExploreModal(poi?.modal?.id));
    dispatch(reqSetIsShowExploreModal(true));
    showInfoWindow(poi);
  };

  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = (eventKey) => {
    dispatch(reqSetActiveType(eventKey == currentEventKey ? "" : eventKey));
    eventKey === currentEventKey && dispatch(reqSetActivePoi(""));
  };

  const handleClickPoint = (poi) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_POI, {
        poi,
      });
    }
    // dispatch(reqSetExploreModal(poi?.modal?.id));
    dispatch(reqSetIsShowExploreModal(true));
    setActiveCategory("");
    showInfoWindow(poi);
  };

  const handleShowItem = (poi) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_POI_ITEM, {
        item: poi,
      });
    }
    if(poi && mapRef && mapRef.current) {
      poi.marker?.coordinates && mapRef.current.state.map.setCenter({lat: poi.marker.coordinates.lat, lng: poi.marker.coordinates.lng}); 
      poi.marker?.coordinates && mapRef.current.state.map.setZoom(18); 
    }
    decoratedOnClick(poi.group);
    handleClickSubPoint(poi);
    poi && handleShowCategory('');
  };

  const handleShowCategory = (category) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_POI_GROUP, {
        item: category,
      });
    }
    if(category && mapRef && mapRef.current) {
      mapRef.current.state.map.setCenter(
        {
          "lat": 25.86244281314848,
          "lng": -80.21436165156719,
        }
      );
      mapRef.current.state.map.setZoom(12.707504756594467);
    }
    setActiveCategory(category);
    category && handleShowItem('');
  }

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyC2fmTkp-ummiBHRE2ee4MVD_rr-pZ9fcI',
  });

  const setActivePoi = (data) => {
    dispatch(reqSetActivePoi(''));
    dispatch(reqSetActivePoi(data.id));
  };

  const clearActivePoi = () => {
    dispatch(reqSetActivePoi(''));
  };

  const getPoiNavIcon = (poi) => {
    let a = (
      <div
        className={`navigation-item type-${poi.category} ${
          activeType === poi.category ? 'active' : ''
        }`}
        dangerouslySetInnerHTML={{ __html: poi?.navigation?.svg }}
      />
    );
  };
  const renderPoiNav = () => {
    function htmlToReactWithReplace(poi) {
      const replace = (domNode) => {
        if (activeType == poi.category && domNode.name == 'circle') {
          domNode.attribs.class = 'circle active';
        }
      };
      return Parser(!!poi?.navigation?.svg ? poi?.navigation?.svg : '<div></div>', { replace });
    }

    const navGroups = pois.filter((poi) => poi.action_types.includes('navigation'));
    return (
      <ul className='list-group'>
        {navGroups.map((poi) => {
          return (
            <li
              key={`group_${poi.category}`}
              style={{ position: 'relative' }}
              className={`navigation-item type-${poi.category} ${
                activeType === poi.category ? 'active' : ''
              }`}
              onClick={() => {
                if (activeType === poi.category) {
                  dispatch(reqSetActivePoi(''));
                  dispatch(reqSetActiveType(''));
                } else {
                  dispatch(reqSetActivePoi(''));
                  dispatch(reqSetActiveType(poi.category));
                }
              }}
            >
              {htmlToReactWithReplace(poi)}
            </li>
          );
        })}
      </ul>
    );
  };

  const renderMarker = (poi, index) => {
    if (!poi.action_types.includes('marker')) return;
    if (activeCategory && poi.category !== activeCategory) {
      return;
    }
    return (
      <Marker
        key={`${poi.id}_marker`}
        position={{
          lat: poi.marker.coordinates.lat,
          lng: poi.marker.coordinates.lng,
        }}
        icon={{
          ...markerIcon,
          clickable: true,
          scaledSize: !poi.marker.icon ? new google.maps.Size(35, 35) : undefined, // increase size for default marker icon only
          path: poi.marker.path ? poi.marker.path : markerIcon.path,
          url: poi.marker.icon ? svgIconToURL(poi.marker.icon) : ((poi.id === activePoi || poi.category === activeCategory) ? activeMarkerIcon.url : markerIcon.url),
          anchor: !poi.marker.icon ? new google.maps.Point(13.5, 13.5) : undefined, // anchor 0 displaced the big icon
        }}
        onClick={(e) => {
          handleClickPoint(poi);
        }}
        onLoad={(e) => {
          activeMarkers.push(e.getPosition());
        }}
        onUnmount={(e) => {
          let lat = e.getPosition().lat();
          let lng = e.getPosition().lng();
          activeMarkers.splice(
            activeMarkers.findIndex((am) => {
              return am.lng() === lng && am.lat === lat;
            }, 1)
          );
        }}
        data={poi}
        zIndex={poi.id === activePoi || poi.category === activeCategory ? 9 : 1}
      >
        {activePoi === poi.id && poi.action_types.includes('info_window') && (
          <InfoWindow
            key={`${poi.id}_info_window`}
            onCloseClick={clearActivePoi}
            position={{
              lat: poi.marker.coordinates.lat,
              lng: poi.marker.coordinates.lng,
            }}
            options={{ closeBoxMargin: '100px 20px 2px 2px' }}
          >
            <div dangerouslySetInnerHTML={{ __html: poi.info_window.content }} />
          </InfoWindow>
        )}
      </Marker>
    );
  };

  const renderOverlay = (poi) => {
    if (!poi.action_types.includes('overlay')) return;
    return (
      <>
        <GroundOverlay
          url={poi.overlay.url}
          bounds={poi.overlay.bounds}
          opacity={0.5}
          onClick={() => {
            setActivePoi(poi);
          }}
          onDblClick={(e) => {
            e.domEvent.preventDefault();
          }}
        />
        {activePoi === poi.id && poi.action_types.includes('info_window') && (
          <InfoWindow
            onCloseClick={clearActivePoi}
            position={{
              lat: -37.81321293099207,
              lng: 144.93801607899866,
            }}
            options={{ closeBoxMargin: '100px 20px 2px 2px', padding: '100px' }}
          >
            <div dangerouslySetInnerHTML={{ __html: poi.info_window.content }} />
          </InfoWindow>
        )}
      </>
    );
  };
  const renderPolygon = (poi) => {
    if (!poi.action_types.includes('polygon')) return;
    return (
      <>
        <Polygon
          key={`${poi.id}_drawing`}
          paths={poi.polygon.coordinates}
          onClick={() => {
            setActivePoi(poi);
          }}
          onLoad={(shape) => {
            let paths = shape.getPaths();
            paths.forEach((path) => {
              let arr = path.getArray();
              arr.forEach((item) => activeMarkers.push(item));
            });
          }}
          onUnmount={(shape) => {
            let paths = shape.getPaths();
            paths.forEach((path) => {
              let arr = path.getArray();
              arr.forEach((item) => {
                let lat = item.lat();
                let lng = item.lng();
                activeMarkers.splice(
                  activeMarkers.findIndex((am) => {
                    return am.lng() === lng && am.lat === lat;
                  }, 1)
                );
              });
            });
          }}
          options={poi.polygon.options}
        />
        {poi.action_types.includes('info_window') && activePoi === poi.id && (
          <InfoWindow
            key={`${poi.id}_info_window`}
            onCloseClick={clearActivePoi}
            position={{
              lat: poi.polygon.coordinates[0].lat,
              lng: poi.polygon.coordinates[0].lng,
            }}
            options={{ closeBoxMargin: '100px 20px 2px 2px', padding: '100px' }}
          >
            <div dangerouslySetInnerHTML={{ __html: poi.info_window.content }} />
          </InfoWindow>
        )}
      </>
    );
  };

  const renderMap = () => {
    return (
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        center={config.center}
        zoom={config.zoom}
        onLoad={(map, maps) => {
          onMapLoaded(map, maps);
        }}
        options={{
          maxZoom: 18,
          minZoom: 2.5,
          disableDefaultUI: true,
          disableDoubleClickZoom: true,
          isFractionalZoomEnabled: true,
        }}
        onZoomChanged={function () {
          if (!isPresentation) {
            socket.emitUIActionEvent(ACTION_NAME.GOOGLE_MAP_ZOOM_CHANGED, {
              zoom: this.zoom
            });
          }
        }}
        onCenterChanged={function () {
          if (!isPresentation) {
            socket.emitUIActionEvent(ACTION_NAME.GOOGLE_MAP_CENTER_CHANGED, {
              lat: this.center.lat(),
              lng: this.center.lng(),
            });
          }
        }}
      >
        {/* <BicyclingLayer></BicyclingLayer> */}

        {pois.map((poi, index) => {
          if (
            (poi?.visibility?.default && activeType === '') ||
            poi?.visibility?.categories?.includes(activeType)
          )
            return (
              <React.Fragment key={index}>
                {renderMarker(poi)}
                {renderPolygon(poi)}
                {renderOverlay(poi)}
              </React.Fragment>
            );
        })}
      </GoogleMap>
    );
  };

  const renderPanel = () => {
    if (!activePoi) return;
    const poi = pois.find((poi) => poi.id === activePoi);
    if (!poi.action_types.includes('panel')) return;
    return (
      <div className='panel'>
        <div className='wrapper' dangerouslySetInnerHTML={{ __html: poi.panel.content }} />
      </div>
    );
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  return (
    <>
      <div className='wrap-list-location'>
        <div className='card-title' onClick={() => handleShowCategory('')}>POINTS OF INTEREST</div>
        <div className='card-body' ref={pageRef} onScroll={onScroll}>
            {[
              'Cafes',
              'Fine Dining & Cocktails',
              'Spa & Wellness',
              'Hotels',
              'Playgrounds & Schools',
              'Sports & Recreation',
              'Shopping & Grocery',
              'Veterinary Clinics & Dog Parks',
              'Airports',
            ].map((category) => (
              <div className='group' key={category}>
                <div className="group-title">
                  <span className={`${(activeCategory && activeCategory !== category) ? 'inactive-poi' : ''}`} onClick={() => handleShowCategory(category)}>{category}</span>
                </div>
                <div className='group-items'>
                  {pois.map((poi) => poi.category ===  category ? (
                    <li
                      className={`group-item ${((activePoi && activePoi !== poi.id) || activeCategory) ? 'inactive-poi' : ''}`}
                      key={poi.id}
                      onClick={() => handleShowItem(poi)}
                    >
                      <span className='group-item-bullet-point'>-</span>
                      <span className='group-item-value'>{poi.title}</span>
                    </li>
                  ): null)}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className='wrap-location'>
        {isLoaded && renderMap()}
        {renderPanel()}
      </div>
      {/* <h1 className="title-center-bottom f-title">Location</h1> */}
    </>
  );
};

export default Location;
