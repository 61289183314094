import React from "react";
import { Modal } from "react-bootstrap";

import closeIcon from "../../assets/images/close-white.svg";

const ErrorModal = (props) => {
  const { onClose, isShow } = props;

  return (
    <Modal
      backdropClassName="opacity0"
      className="wrap-not-found"
      show={isShow}
      onHide={onClose}
      centered
    >
      <Modal.Body className="wrap-modal-body">
        {/* <div className="close-btn">
          <img src={closeIcon} alt="close-icon" onClick={onClose} />
        </div> */}
        <div className="modal-not-found">
          <span className="title">CUSTOMER NOT FOUND</span>
          <span className="sub">
            This combination of name and email does not match any customer in
            our system.{" "}
          </span>
          <button className="common-button" onClick={onClose}>Close</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
