import { Modal } from "react-bootstrap";
import closeIcon from "../../../assets/images/close-white.svg";
import React, { useEffect, useState } from "react";
import { parseResidenceData, validationResidenceSchema } from "./utils";
import './index.scss'
import ChevronDown from '../../../assets/images/chevron-down.svg'

const UpdateResidenceModal = ({show, data, onSave, onHide}) => {
  const [formData, setFormData] = useState()
  const [isDirty, setIsDirty] = useState(false)
  const [errors, setErrors] = useState({}) 

  const formatNumber = (num) => {
    if (!num) return '';
    const number = parseFloat(num.replace(/,/g, ''));
    if (isNaN(number)) return '';
    return new Intl.NumberFormat().format(number);
  };

  useEffect(() => {
    if(show && data) {
      setFormData(parseResidenceData({...data, price: formatNumber(String(data.price)) }))
    }
  }, [show, data])

  const handleChange = (value, field) => {
    if (field === 'price') {
      value = formatNumber(value);
    }

    setFormData((currentData) => ({
      ...currentData,
      [field]: value
    }))
    if(!isDirty) {
      setIsDirty(true)
    }
  }

  const handleSubmit =  async () => {
    //reformat price
    const newForm = {
      ...formData,
      price: Number(formData.price.replace(/,/g, ''))
    }

    try {
      await validationResidenceSchema.validate(newForm, { abortEarly: false })
      setErrors({})
      if(!isDirty) return handleClose()
      await onSave(newForm)
      handleClose()
    } catch (error) {
      if(error.inner) {
        const errs = {}
        error.inner.forEach(err => {
          errs[err.path] = err.message
        })
        setErrors(errs)
      }
    }
  }

  const handleClose = () => {
    setFormData(undefined)
    setErrors({})
    setIsDirty(false)
    onHide()
  }

  return  (
    <>
      {show && <div className="close-modal-btn">
        <img src={closeIcon} alt="close-icon" onClick={handleClose} />
      </div>}
      <Modal
        className="wrap-detail-modal"
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Body className="wrap-modal-body">
          <div
            className="modal-form__title residence">
            <span className="title">Edit residence</span>
          </div>
          <div className="wrap-form">
            <div className="flex-row">
              <div className="form-info">
                <label className={errors?.name && "error"} htmlFor="name">Residence*</label>
                <div className="residence-input">
                  <p className="residence-label">Residence</p>
                  <input
                    className={errors?.name ? "input-field error" : "input-field"}
                    onChange={(e) => handleChange(e.target.value, "name")}
                    value={formData?.name}
                    id="name"
                    placeholder="Residence ####"
                  />
                  </div>

                <span>{errors?.name}</span>
              </div>
              <div className="form-info">
                <label className={errors?.availabilityStatus && "error"} htmlFor="availabilityStatus">Availability*</label>
                <div className="select-container">
                  <select
                    className={errors?.availabilityStatus ? "input-select error" : "input-select"}
                    onChange={(e) => handleChange(e.target.value, "availabilityStatus")}
                    id="availabilityStatus"
                    value={formData?.availabilityStatus}
                    placeholder="Available"
                  >
                    <option value="available">Available</option>
                    <option value="reserved">Reserved</option>
                    <option value="sold">Sold</option>
                    <option value="releasing_soon">Releasing Soon</option>
                  </select>
                  <img className="icon--down" src={ChevronDown} />

                </div>

                <span>{errors?.availabilityStatus}</span>
              </div>
            </div>
            <div className="flex-row">
              <div className="form-info" style={{position: "relative"}}>
                <label className={errors?.price && "error"} htmlFor="price">Price*</label>
                <input
                  className={errors?.price ? "input-field error" : "input-field"}
                  onChange={(e) => handleChange(e.target.value, "price")}
                  value={formData?.price}
                  id="price"
                  placeholder="$$$$$$"
                  style={{paddingLeft: formData?.price ? "8px" : 0}}
                />
                { formData?.price && <span style={{position: "absolute", color: "#000", top: "28px"}}>$</span> }
                <span>{errors?.price}</span>
              </div>
              <div className="form-info form--disabled">
                <label className={errors?.level && "error"} htmlFor="level">Floor*</label>
                <input
                  disabled={true}
                  className={errors?.level ? "input-field error" : "input-field"}
                  onChange={(e) => handleChange(e.target.value, "level")}
                  value={formData?.level}
                  id="level"
                  placeholder="2"
                />
                <span>{errors?.level}</span>
              </div>
            </div>
            <div className="flex-row">
              <div className="form-info">
                <label className={errors?.areaSqm && "error"} htmlFor="areaSqm">sq. ft.*</label>
                <input
                  className={errors?.areaSqm ? "input-field error" : "input-field"}
                  onChange={(e) => handleChange(e.target.value, "areaSqm")}
                  value={formData?.areaSqm}
                  id="areaSqm"
                  placeholder="1354"
                />
                <span>{errors?.areaSqm}</span>
              </div>
              <div className="form-info">
                <label className={errors?.bedrooms && "error"} htmlFor="bedrooms">Beds*</label>
                <input
                  className={errors?.bedrooms ? "input-field error" : "input-field"}
                  onChange={(e) => handleChange(e.target.value, "bedrooms")}
                  value={formData?.bedrooms}
                  id="bedrooms"
                  placeholder="5"
                />
                <span>{errors?.bedrooms}</span>
              </div>
            </div>
            <div className="flex-row">
              <div className="form-info form--disabled">
                <label className={errors?.bathrooms && "error"} htmlFor="bathrooms">Baths*</label>
                <input
                  disabled={true}
                  className={errors?.bathrooms ? "input-field error" : "input-field"}
                  onChange={(e) => handleChange(e.target.value, "bathrooms")}
                  value={formData?.bathrooms}
                  id="bathrooms"
                  placeholder="3"
                />
                <span>{errors?.bathrooms}</span>
              </div>
              <div className="form-info form--disabled">
                <label className={errors?.hasPool && "error"} htmlFor="hasPool">Pool*</label>
                <div className="select-container">
                  <select
                    disabled={true}
                    className={errors?.hasPool ? "input-select error" : "input-select"}
                    onChange={(e) => handleChange(e.target.value, "hasPool")}
                    id="hasPool"
                    value={formData?.hasPool}
                    placeholder="Yes"
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                  <img className="icon--down" src={ChevronDown} />
                </div>
                <span>{errors?.hasPool}</span>
              </div>
            </div>
            <div className="form-info w-100">
              <button onClick={handleSubmit}>
                Save
              </button>
            </div>
          </div>
        
        </Modal.Body>
      </Modal>
  </>
  )
}

export default UpdateResidenceModal;
