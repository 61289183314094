import { Accordion } from "react-bootstrap";
import React, { Fragment } from "react";
import "./accordion.scss";
import { UnitDetailsArrowDown, UnitDetailsArrowUp } from "../svgs/icons";

const AccordionSidebar = ({ data, activeKeys, onToggle, id }) => {
  return (
    <>
      <Accordion activeKey={activeKeys.includes(id) ? id : ""}>
        {data.map((section, key) => (
          <Fragment key={"list-group-" + key}>
            <Accordion.Toggle
              className={"accordion-button accordion-sidebar-button txt-style"}
              eventKey={id}
              onClick={() => onToggle(activeKeys, id)}
            >
              <span>{section.title}</span>
              <div className='accordion-button-icon'>
                {activeKeys.includes(id) ? <UnitDetailsArrowUp /> : <UnitDetailsArrowDown />}
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={id}>
              <ul className="list-group">
                {(section?.items || []).map((item, key2) => (
                  <li
                    key={"list-group-item-" + key2}
                    className="list-group-item"
                  >
                    <div className="list-group-item-title">
                      <div className="dot-before" />
                      <span className="me-auto">{item.title}</span>
                    </div>
                    <div className="list-group-item-body">{item.value}</div>
                  </li>
                ))}
              </ul>
            </Accordion.Collapse>
          </Fragment>
        ))}
      </Accordion>
    </>
  );
};
export default AccordionSidebar;
