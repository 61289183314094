import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reqGetListUnits,
} from "../../../reduxs/cms/action";
import Row from "./row";
import unitApi from "../../../apis/api/unit";
import { toast } from "react-toastify";
import { useIsMount } from "../../../helper/use-is-mount";
import SearchIcon from "../components/Search";
import UpdateResidenceModal from "./UpdateResidenceModal";

const CMSResidence = () => {
  const dispatch = useDispatch();
  const units = useSelector((state) => state.cms.units);
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [search, setSearch] = useState('');
  const [selectedData, setSelectedData] = useState()

  const isMount = useIsMount();

  useEffect(() => {
    getListProperties();
  }, []);

  useEffect(() => {
    if (!isMount) getListProperties();
  }, [search, isSortAsc])

  const getListProperties = async () => {
    dispatch(
      reqGetListUnits({
        search,
        sortBy: JSON.stringify({
          name: isSortAsc ? 1 : -1,
        }),
      })
    );
  };

  const renderListProperty = () => {
    return (units || []).sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => {
      return (
          <Row
            key={index}
            data={item}
            index={index}
            onClickDetail={() => setSelectedData(item)}
          />
      );
    });
  };

  const handleUpdate = async (data) => {
    try {
      await unitApi.putUnitUpdate(data.id, data)
      await getListProperties()
      toast.success("Residence updated successfully")
    } catch (error) {
      
    }
  }

  const onSearch = (e) => {
    setSearch(e.target?.value);
  } 

  return (
    <>
      <UpdateResidenceModal 
        show={!!selectedData} 
        data={selectedData} 
        onSave={handleUpdate} 
        onHide={() => setSelectedData(undefined)}
      />
      <div className="page-container">
        <div className="page-header d-flex align-items-center">
          <h1 className="f-title mb-0">RESIDENCES</h1>
        </div>
        <div className="page-body residence">
          <div className="table-responsive-custom">
            <table className="table-custom">
              <thead>
                <tr
                  style={{
                    display: "table",
                    width: "100%",
                    tableLayout: "fixed",
                  }}
                >
                  <th className="col-4">Residence</th>
                  <th className="col-4">Availability</th>
                  <th className="col-3">
                    Price
                  </th>
                  <th className="col-2">
                    Floor
                  </th>
                  <th className="col-2">
                    SQ.FT.
                  </th>
                  <th className="col-2">Beds</th>
                  <th className="col-2">Baths</th>                
                  <th className="col-3">
                    Private pool
                  </th>
                  <th className="col-2" />
                </tr>
              </thead>
              <tbody
                style={{
                  display: "block",
                  maxHeight: "calc(100vh - 400px)",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                  {renderListProperty()}
              </tbody>
            </table>
          </div>
        </div>
        {/* <SearchIcon handleInputChange={onSearch} /> */}
      </div>
    </>
  );
};

export default CMSResidence;
