import React, { useEffect } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { reqSetIsShowExpandAmenity, reqSetIsShowExploreModal } from "../../reduxs/explore-modal/action";
import parse from "html-react-parser";
import {
  reqSetActiveAreaId,
} from "../../reduxs/home/action";
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import { reqFilterUnitPrecinct } from "../../reduxs/unit-explore/action";
import { reqSetIsShowPrecinctExploreDetail } from "../../reduxs/precinct-explore/action";
import { reqSetActivePrecinctID } from "../../reduxs/transport-options/action";
import {
  reqSetActiveGalleryModal,
  reqSetIsShowGalleryModal,
} from "../../reduxs/district-future-detail/action";
import GalleryDetail from "../gallery/_gallery-detail";
import { USER_GROUP } from "../../constants/master-data";
import socket from "../../helper/socket";
import { selectedHotspot, selectedHotspot3D } from '../3d-scene/CanvasBox';
import { getImagePath } from "../../helper/media";
import VirtualModal from "./virtual-modal";

const RightPanel = (props) => {
  const { page, isPresentation } = props;
  const isShowRightPanel = useSelector(
    (state) => state.exploreModal.isShowExploreModal
  );
  const isShowExpandAmenity = useSelector(
    (state) => state.exploreModal.isShowExpandAmenity
  );
  const modal = useSelector((state) => state.exploreModal.modal);
  const dispatch = useDispatch();
  const isShowGallery = useSelector(
    (state) => state.districtdetail.isShowGalleryModal
  );
  const authUser = useSelector((state) => state.user.data);
  const customer = useSelector((state) => state.user.customer);
  const isShowAmenityVirtualTour = useSelector(
    (state) => state.exploreModal.isShowAmenityVirtualTour
  );

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CLICK_CLOSE_MODAL) {
      onClose();
    }
    if (content.action === ACTION_NAME.SHOW_AMENITY_MODAL_IMAGE) {
      handleShowModalImage();
    }
    if (content.action === ACTION_NAME.CLOSE_AMENITY_MODAL_IMAGE) {
      handleCloseModalImage();
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, []);

  const onClose = () => {
    if (selectedHotspot3D?.material?.map && selectedHotspot?.texture) {
      selectedHotspot3D.material.map = selectedHotspot.texture;
    }

    dispatch(reqSetActivePrecinctID());
    dispatch(reqSetIsShowExploreModal(false));
  };

  const handleButtonInContent = (event) => {
    const clickedEl = event.target;
    if (clickedEl.id == "btn_close_right_panel") {
      dispatch(reqSetIsShowGalleryModal(false));
      return onClose();
    }

    if (clickedEl.id == "btn_explore_precinct") {
      if (clickedEl.dataset?.area_id) {
        dispatch(reqSetActiveAreaId(clickedEl.dataset.area_id));
      } else {
        dispatch(reqSetActiveAreaId());
      }

      if (clickedEl.dataset?.precinct) {
        dispatch(reqFilterUnitPrecinct(clickedEl.dataset.precinct));
      } else {
        dispatch(reqFilterUnitPrecinct());
      }

      dispatch(reqSetIsShowPrecinctExploreDetail(true));
      onClose();
    }

    if (clickedEl.id === "btn_view_gallery") {
      if (clickedEl.innerHTML?.toLowerCase() === "view gallery") {
        clickedEl.innerHTML = "CLOSE GALLERY";
        dispatch(reqSetActiveGalleryModal(clickedEl.dataset?.gallery_id));
        dispatch(reqSetIsShowGalleryModal(true));
      } else {
        clickedEl.innerHTML = "VIEW GALLERY";
        dispatch(reqSetActiveGalleryModal());
        dispatch(reqSetIsShowGalleryModal(false));
      }
    }
  };

  const renderModalContent = () => {
    try {
      if (page === PAGES.AMENITIES_PAGE) {
        return getContentAmenities();
      }
      if (
        customer?.userGroup?.name === USER_GROUP.INVESTOR ||
        authUser?.userGroup?.name === USER_GROUP.INVESTOR
      ) {
        return parse(modal?.content || "");
      }
      return parse(modal?.content || "", {
        replace: (domNode) => {
          if (domNode.attribs?.id === "investor-details") return <></>;
        },
      });
    } catch (e) {
      console.log("Error open modal", e);
    }
  };

  const handleShowModalImage = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_AMENITY_MODAL_IMAGE);
    }
    dispatch(reqSetIsShowExpandAmenity(true));
  }

  const handleCloseModalImage = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_AMENITY_MODAL_IMAGE);
    }
    dispatch(reqSetIsShowExpandAmenity(false));
  }

  const parseContent = (content) => {
    return parse(content || "", {
      replace: ({ attribs }) => {
        if (attribs && attribs.id === "image") {
          return <img
            src={getImagePath(modal?.image)}
            alt="modal-image"
            className="modal-image"
          />;
        }
        if (attribs && attribs.id === "expand-image") {
          // return <img
          //   src={getImagePath("/images/amenities/expand-image.svg")} 
          //   alt="modal-expand-image"
          //   className="modal-expand-image"
          //   onClick={handleShowModalImage}
          // />;
        }
      }
    })
  }

  const getContentAmenities = () => {
    return parseContent(modal?.content?.en);
  };

  const handleClickWrapper = (clickedId) => {
    if (clickedId === "id-wrapper-right-panel") {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CLICK_CLOSE_MODAL)
      }
      return onClose();
    }
  };

  return (
    <>
      <TransitionGroup>
        {isShowRightPanel && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <div
              id="id-wrapper-right-panel"
              className="wrap-right-panel-out"
              onClick={(e) => {
                e.stopPropagation()
                const clickedId = e.target.id
                handleClickWrapper(clickedId)
              }}
            >
              <div
                className={`wrap-right-panel ${page === PAGES.EXPLORE_PRECINCT_PAGE && "above-timeline"
                  }`}
                onClick={(e) => handleButtonInContent(e)}
              >
                {renderModalContent()}
              </div>
            </div>
          </CSSTransition>
        )}
        {isShowGallery && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <div
              className={`wrap-gallery-modal`}
              onClick={(e) => handleButtonInContent(e)}
            >
              <GalleryDetail />
            </div>
          </CSSTransition>
        )}
        {
          isShowAmenityVirtualTour && (
            <CSSTransition timeout={1000} classNames="fade-item">
            <div>
              <VirtualModal isPresentation={isPresentation}/>
            </div>
          </CSSTransition>
          )
        }
      </TransitionGroup>
      {
        isShowExpandAmenity && (
          <div className={'media-popup-gallery'}>
            <div className={'popup-gallery-wrapper'}>
              <div className={'popup-gallery-close'} onClick={handleCloseModalImage}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='26'
                  height='24'
                  viewBox='0 0 26 24'
                  fill='none'
                >
                  <path
                    d='M1 1L25 22.8182M25 1L1 22.8182'
                    stroke='white'
                    stroke-miterlimit='10'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
              <div className='container-image'>
                <img
                  style={{ width: '100%', height: '100%' }}
                  alt={"modal-image"}
                  src={getImagePath(modal?.image)}
                  width={'100%'}
                  height={'100%'}
                />
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default RightPanel;
