import React, { useState, useEffect } from "react";
import { FormControl, Modal, Form } from "react-bootstrap";
import * as yup from 'yup';
import { toast } from "react-toastify";
import closeIcon from "../../../assets/images/close-white.svg";
import amenitiesAPI from '../../../apis/api/amenities';

const validationSchema = yup.object().shape({
	name: yup.string().trim().required("Name is a required field"),
	description: yup.string().trim().required("Description is a required field"),
});

const EditAmenityModal = ({ amenity, show, setShow, onSaveSuccess }) => {
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({}) 

	useEffect(() => {
		if (amenity) {
			setName(amenity.name);
			setDescription(amenity.description);
		}
	}, [amenity])

	const handleUpdateAmanity = async () => {
		if (!amenity) return;
		
		try {
			const data = {
				name,
				description,
			};
			setErrors({});
			const result = await validationSchema.validate(data, { abortEarly: false });
			const res = await amenitiesAPI.updateAmenity(amenity.id, result);
			if (res.data) {
				toast.success("Amenity updated successfully!"); 
				handleClose && handleClose();
				onSaveSuccess && onSaveSuccess();
			}
		} catch (err) {
			if(err.inner) {
        const errs = {}
        err.inner.forEach(err => {
          errs[err.path] = err.message
        })
        setErrors(errs);
      }
		}
	};

	const handleClose = () => {
		setShow(false);
	};

	return (
			<Modal
				className="wrap-update-amenity-modal"
				show={show}
				onHide={handleClose}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Update Amenity</Modal.Title>
				</Modal.Header>
				<Modal.Body className="wrap-modal-body">
					<div className="close-btn">
						<img src={closeIcon} alt="close-icon" onClick={handleClose} />
					</div>
					<div className="wrap-form-group">
						<Form.Group className="form-group">
							<Form.Label>Amenity Name*</Form.Label>
							<FormControl
								id="name"
								name="name"
								type="input"
								value={name}
								placeholder="Amenity Name"
								onChange={(e) => setName(e.target.value)}
							/>
							<span className="error">{errors?.name}</span>
						</Form.Group>
					</div>
					<div className="wrap-form-group">
						<Form.Group
							className="form-group"
							controlId="exampleForm.ControlTextarea1"
						>
							<Form.Label>Description*</Form.Label>
							<Form.Control
								as="textarea" rows={6}
								onChange={(e) => setDescription(e.target.value)}
								value={description}
							/>
							<span className="error">{errors?.description}</span>
						</Form.Group>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button onClick={handleUpdateAmanity}>Save</button>
				</Modal.Footer>
			</Modal>
	);
};

export default EditAmenityModal;
