import React, { useCallback, useEffect, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopNav from "../../components/top-nav";
import Loading from "../../components/loading";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useHistory } from "react-router-dom";

import Location from "../../components/location";
import FloorPlanGallery from "../../components/floor-plan-gallery";
import BottomNavigation from "../../components/bottom-navigation";
import TransportOption from "../../components/district-transport-option";
import Gallery from "../../components/gallery";
import RightPanel from "../../components/right-panel";
import UnitExplore from "../../components/unit-explore";

import {
  reqSetSelectedUnit,
  reqIsShowGallery,
  reqIsShowFloorplan,
  reqSetIsShowPrecinctDetail,
  reqIsShowUnitDetail,
  reqSetIsShowFilter
} from "../../reduxs/unit-explore/action";
import { reqSetIsShowAmenityVirtualTour, reqSetIsShowExploreModal } from "../../reduxs/explore-modal/action";
import {
  reqSetIsExpandNav,
  reqSetPage,
  reqSetActiveTransportOption,
  reqSetActiveTransportOptionDistricts,
  reqSetIsShowEndGuideTenantSession,
  reqSetActiveEndGuideTenantSessionId,
  reqSetIsShowImmerse,
  reqSetIsTransparent,
  reqSetIsPresentation,
} from "../../reduxs/home/action";
import {
  PAGES,
  LAYERS,
  WEBSOCKET_CHANNEL,
  ACTION_NAME,
} from "../../constants/options";
import { threePosition } from "../../helper/threeHeper";
import Login from "../../components/auth/Login";
import {
  reqSetIsShowPrecinctExploreDetail,
  reqSetIsShowReplayVideo,
} from "../../reduxs/precinct-explore/action";
import { reqSetActivePrecinctID } from "../../reduxs/transport-options/action";
import { setColor2 } from "../../helper/threeHeper";
import { reqSetIsShowGalleryModal } from "../../reduxs/district-future-detail/action";
import TransportOptionDistrict from "../../components/transport-options-district";
import EndGuideTenantSessionModal from "../../components/guide-sesions/end-guide-tenant-session-modal";
import { actIntroduction, setIsLoading } from "../../reduxs/scene/action";
import { reqSetCustomerProfile } from "../../reduxs/user/action";
import VideoIntro from "../../components/video-intro";
import PostSessionPopup from "../../components/post-session-popup";
import HomeGallery from "../../components/home-gallery";
import VirtualModal from "../../components/home-gallery/virtual-modal";
import BookingAppointment from "../../components/booking-appointment";
import FeatureTab from "../../components/team";
import SearchV2 from "../../components/search-v2";
import AmenitiesV2 from "../../components/amenitiesV2";
import Neighborhood from "../../components/neighborhood";
import Ppg from "../../components/ppg";
import Services from "../../components/services";
import AmenitiesOption from "../../components/amenities-option";
import GalleryLanding from "../../components/gallery-landing";
import PanoViewer from "../../components/pano-viewer";
import Views from "../../components/views";
import ViewsLevels from "../../components/views/views-levels";
import Amenities from "../../components/amenities";
import Session from "../../components/session";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import socket from "../../helper/socket";
import { isTouchDevice, clearFilterUnit } from "../../helper/utils";
import { Quaternion, Vector3 } from "three";
import { toast } from "react-toastify";
import {
  fetch3DSettings,
  fetchAssetsList,
  fetchHotspotsList,
  fetchPagesSettings,
} from '../../reduxs/scene/action';
import GLTF3DLoader from "../../components/3d-scene/GLTF3DLoader";

const ReactUI = (props) => {
  const {
    setIsIntroduction,
    controls,
    refScene,
    setActiveObjectIds,
    resetActiveColorModel,
    activeObjectIds,
    isPresentation,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const page = useSelector((state) => state.home.page);
  const isNavExpand = useSelector((state) => state.home.isNavExpand);
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const pagesSettings = useSelector((state) => state.scene.pagesSettings);
  const isLoading = useSelector((state) => state.scene.isLoading);
  const transportOptionDistricts = useSelector(
    (state) => state.home.transportOptionDistricts
  );
  const isShowReplayVideo = useSelector(
    (state) => state.precinctExplore.isShowReplayVideo
  );
  const isShowVirtualModal = useSelector(
    (state) => state.gallery.isShowVirtualModal
  );
  const isShowBookingAppointmentForm = useSelector(
    (state) => state.home.isShowBookingAppointmentForm
  );
  const customer = useSelector((state) => state.user.customer);
  const authUser = useSelector((state) => state.user.data);
  const isShowImmerse = useSelector((state) => state.home.isShowImmerse);
  const [isShowSearch, setShowSearchModal] = useState(false);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);

  const listenerSharedUIAction = ({ content }) => {
    handleApplyAction(content.action, content.data);
  };

  const listenerCameraAction = ({ content }) => {
    const POSITION_THRESHOLD = 0.15;
    if (controls.current != null) {
      const currentPos = controls.current?.object?.position;
      // get last and new position vectors
      let lastPosition = new Vector3(
        currentPos?.x,
        currentPos?.y,
        currentPos?.z,
      );
      let newPosition = new Vector3(
        content?.position?.x,
        content?.position?.y,
        content?.position?.z,
      );

      // get new quaternion
      const newQuaternion = new Quaternion(
        content?.quaternion?.x,
        content?.quaternion?.y,
        content?.quaternion?.z,
        content?.quaternion?.w,
      );

      controls.current.object.position.copy(newPosition);
      controls.current.object.quaternion.copy(newQuaternion);
      controls.current.object.zoom = content.zoom;
      controls?.current?.object?.updateProjectionMatrix();
    }
  };

  const establishSocket = (authUser) => {
    if (!authUser) return;

    // websocket.connect(authUser);
    socket.connect(authUser);

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);

      socket.on(WEBSOCKET_CHANNEL.SHARE_CAMERA_ACTION, listenerCameraAction);
    }
  };
  const handleRefresh = (event) => {
    const urlParams = new URLSearchParams(window.location.search);
    const customerID = urlParams.get('customer') || '';
    if (event.key === 'F5' || event.type === 'beforeunload') {
      if (!isPresentation && customerID) {
        handleResetCustomer();
        event.returnValue = '';
        return null;
      }
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const customerID = urlParams.get('customer') || '';
    if (customerID) {
      handleResetCustomer();
    }

    isPresentation && dispatch(reqSetIsPresentation(true));

    window.addEventListener('keydown', handleRefresh);
    window.addEventListener('beforeunload', handleRefresh);

    return () => {
      window.removeEventListener('keydown', handleRefresh);
      window.removeEventListener('beforeunload', handleRefresh);
    };
  }, []);

  useEffect(() => {
    establishSocket(authUser);

    const root = window.document.getElementById("root");

    const handleMouseMove = _.debounce((e) => {
      if (!socket.socket.connected) {
        establishSocket(authUser);
      }
    }, 150);

    const touchDevice = isTouchDevice();

    if (touchDevice) {
      root.addEventListener("touchstart", handleMouseMove);
    } else {
      root.addEventListener("mouseover", handleMouseMove);
    }

    return () => {
      if (socket.connected) {
        socket.disconnect();
      }

      if (touchDevice) {
        root.removeEventListener("touchstart", handleMouseMove);
      } else {
        root.removeEventListener("mouseover", handleMouseMove);
      }

      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
      socket.off(WEBSOCKET_CHANNEL.SHARE_CAMERA_ACTION, listenerCameraAction);
    };
  }, [authUser?.id]);

  useEffect(() => {
    document.addEventListener("REST_STATE", function (e) {
      handleReset3DFilter();
      let timeOut = setTimeout(() => {
        resetState();
        clearTimeout(timeOut);
      }, 1000);
    });
  }, []);

  useEffect(() => {
    if (selectedUnit?.["3d_asset"]?.id) {
      setActiveObjectIds([selectedUnit["3d_asset"].id]);
    }
  }, [selectedUnit]);

  const handleApplyAction = (action, data) => {
    if (action == ACTION_NAME.CLICK_CUBE_MENU) {
      return handleClickCube();
    }
    if (action == ACTION_NAME.CLICK_EXPLORE_MENU) {
      return handleClickResidences();
    }
    if (action == ACTION_NAME.CLICK_GALLERY_MENU) {
      return handleClickGallery();
    }
    if (action == ACTION_NAME.CLICK_TEAM_MENU) {
      return handleTeam();
    }
    if (action == ACTION_NAME.CLICK_LOCATION_MENU) {
      return handleLocation();
    }
    if (action == ACTION_NAME.CLICK_SEARCH_MENU) {
      return handleSearch();
    }
    if (action == ACTION_NAME.CLICK_AMENITY_MENU) {
      return handleAmenities();
    }
    if (action == ACTION_NAME.CLICK_NEIGHBORHOOD_MENU) {
      return handleNeighborhood();
    }
    if (action == ACTION_NAME.CLICK_PPG_MENU) {
      return handlePpg();
    }
    if (action == ACTION_NAME.CLICK_IMMERSER_MENU) {
      return handleClickImmerse();
    }
    if (action == ACTION_NAME.CLICK_REPLAY_VIDEO) {
      return onReplayVideo();
    }
    if (action == ACTION_NAME.CLICK_SKIP_REPLAY_VIDEO) {
      return onSkipReplayVideo();
    }
    if (action == ACTION_NAME.CLICK_UNIT) {
      return handleUnitClick(data?.unit)
    }
    if (action === ACTION_NAME.TOAST_MESSAGE) {
      toast[data.type](data.message, {
        toastId: "socket-toast"
      });
      return
    }
    if (action === ACTION_NAME.RESET_CUSTOMER) {
      console.log(ACTION_NAME.RESET_CUSTOMER)
      handleResetCustomer();
    }
  };

  const handleResetCustomer = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.RESET_CUSTOMER);
    }
    dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
    history.push(isPresentation ? '/presentation' : '/');
    dispatch(actIntroduction(true));
    dispatch(reqSetCustomerProfile(''));
  }

  const HandleSetActivePage = React.memo((props) => {
    if (props.pagesSettings == null) {
      return <div />;
    }
    const settings = props.pagesSettings.filter(
      (setting) => setting.name == page
    );
    if (settings.length == 0) {
      return <div />;
    }
    if (controls.current == null) {
      return <div />;
    }
    controls.current.currentPage = page;
    const setting = settings[0];

    setting.active_objects != null &&
      setting.active_objects.length > 0 &&
      setActiveObjectIds(setting.active_objects);
    if (setting.camera && setting.camera_look_at_position && !selectedUnit && !isPresentation) {
      const camPosition = threePosition(setting.camera);
      const camLookAtPosition = threePosition(setting.camera_look_at_position);
      controls.current.lookAtAndMovePosition(
        camLookAtPosition,
        camPosition,
        () => { }
      );
    }

    if (
      Array.isArray(setting.show_layers) &&
      Array.isArray(setting.enable_layers)
    ) {
      controls.current.hideAll();
      controls.current.disableAll();

      controls.current.showAndEnableLayer(0);
      for (let i = 0; i < setting.show_layers.length; i += 1) {
        controls.current.showLayer(setting.show_layers[i]);
      }
      for (let i = 0; i < setting.enable_layers.length; i += 1) {
        controls.current.enableLayer(setting.enable_layers[i]);
      }
    }
    return <div />;
  });
  HandleSetActivePage.displayName = "HandleSetActivePage";

  const handleMoveCamera = (object, onCompleted = () => { }) => {
    if (!object.xyz_position) return;

    const position = threePosition(object.xyz_position);

    if (object.cam_position != null) {
      const camPosition = threePosition(object.cam_position);
      const camLookAtPosition =
        object.cam_focus_point_position != null
          ? threePosition(object.cam_focus_point_position)
          : position;
      controls.current.lookAtAndMovePosition(
        camLookAtPosition,
        camPosition,
        onCompleted
      );
    }
  };
  const handleClickCube = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_CUBE_MENU);
    }
    resetState();
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
    dispatch(reqSetIsExpandNav(!isNavExpand));
  };

  const handleUnitClick = (item) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT, {
        unit: item
      })
      socket.emitUIActionEvent(ACTION_NAME.HIDE_FILTER);
      const object = item?.["3d_asset"];
      if (!controls.current || !object) return;
      handleMoveCamera(object);
    }
    dispatch(reqIsShowUnitDetail(true));
    dispatch(reqSetSelectedUnit(item.id));
    dispatch(reqSetIsShowFilter(false));
  };

  const handleClickResidences = () => {
    setActiveObjectIds([])
    dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
    if (!isPresentation) {
      // dispatch(setIsDragging3D(false));
      socket.emitUIActionEvent(ACTION_NAME.CLICK_EXPLORE_MENU);
    }
    clearFilterUnit('auto_available');
    resetState();
    dispatch(reqSetIsTransparent(false));
    dispatch(reqSetIsShowFilter(true));
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    dispatch(unitExploreAct.reqFilterUnitAvailability([{ id: 1, title: "Available", value: "available" }]));
  };

  const handleClickGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_GALLERY_MENU);
    }
    resetState();
    dispatch(reqSetPage(PAGES.GALLERY_LANDING_PAGE));
    analytics.track("View Gallery", {});
  };

  const handleTeam = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_TEAM_MENU);
    }
    resetState();
    dispatch(reqSetPage(PAGES.TEAM_PAGE));
  };

  const handleLocation = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_LOCATION_MENU);
    }
    resetState();
    dispatch(reqSetPage(PAGES.LOCATION_PAGE));
  };
  const handleSearch = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_SEARCH_MENU);
    }
    setShowSearchModal(true);
  };
  const handleAmenities = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_AMENITY_MENU);
    }
    resetState();
    dispatch(reqSetPage(PAGES.AMENITIES_PAGE_V2));
  };
  const handleNeighborhood = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_NEIGHBORHOOD_MENU);
    }
    resetState();
    dispatch(reqSetPage(PAGES.NEIGHBORHOOD_PAGE));
  };
  const handlePpg = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_PPG_MENU);
    }
    resetState();
    dispatch(reqSetPage(PAGES.PPG_PAGE));
  };

  const handleClickImmerse = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_IMMERSER_MENU);
    }
    resetState();
    // dispatch(reqSetActivePatourId('60b8a20d6be0a5d2645b1871'));
    dispatch(reqSetPage(PAGES.VIEWS_PAGE));
    // dispatch(reqSetIsShowImmerse(true));
  };

  const handleClickEndGuideTenantSession = (customerID) => {
    analytics.track("Agent Ended Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientEmail: customer?.email,
      clientPhone: customer?.mobile
    });
    resetState();
    dispatch(reqSetActiveEndGuideTenantSessionId(customerID));
    dispatch(reqSetIsShowEndGuideTenantSession(true));
    dispatch(reqSetPage(PAGES.END_GUIDE_TENANT_SESSION_PAGE));
  };

  const handleClickTenantEndSession = () => {
    analytics.track("Agent Ended Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientEmail: customer?.email,
      clientPhone: customer?.mobile
    });
    dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
    history.push("/");
    // dispatch(setIsLoading({ isLoading: true }));
    dispatch(actIntroduction(true));
    dispatch(reqSetCustomerProfile(""));
  };

  const handleClickTransportOptions = useCallback((ids, model) => {
    if (refScene.current == null) {
      return;
    }
    resetActiveColorModel(model);
    setActiveObjectIds(ids);
    if (controls.current != null) {
      let selectedHotspot = controls.current.selectedHotspot;
      if (selectedHotspot != null) {
        selectedHotspot.material.map = selectedHotspot.userData.texture;
      }
      controls.current.selectedHotspot = null;

      let selectedObject = controls.current.selectedObject;
      if (selectedObject != null) {
        setColor2(selectedObject.userData.color, selectedObject);
        selectedObject.userData.isActive = false;
      }
      controls.current.selectedObject = null;
    }
  });

  const handleClickTransportOptionDistricts = useCallback((ids, model) => {
    if (refScene.current == null) {
      return;
    }
    resetActiveColorModel(model);
    setActiveObjectIds(ids);
    if (controls.current != null) {
      let selectedHotspot = controls.current.selectedHotspot;
      if (selectedHotspot != null) {
        selectedHotspot.material.map = selectedHotspot.userData.texture;
      }
      controls.current.selectedHotspot = null;

      let selectedObject = controls.current.selectedObject;
      if (selectedObject != null) {
        setColor2(selectedObject.userData.color, selectedObject);
        selectedObject.userData.isActive = false;
      }
      controls.current.selectedObject = null;
    }
  });

  const resetState = () => {
    handleClickTransportOptions([], {});

    if (controls.current != null) {
      controls.current.needReloadSelectedHotspotId = true;
    }

    if (refScene.current != null) {
      transportOptionDistricts.forEach((tp) => {
        tp.hidden_when_not_selected.forEach((id) => {
          let object = refScene.current.getObjectByName(id);
          if (object != null) {
            object.layers.set(object.userData.layer ?? LAYERS.DISABLE);
          }
        });
      });
    }
    resetActiveColorModel({});
    setActiveObjectIds([]);
    setShowSearchModal(false);
    dispatch(reqSetActiveTransportOption([]));
    dispatch(reqSetActiveTransportOptionDistricts([]));
    dispatch(reqSetSelectedUnit(""));
    dispatch(reqIsShowGallery(false));
    dispatch(reqSetIsShowExploreModal(false));
    dispatch(reqIsShowFloorplan(false));
    dispatch(reqSetIsShowPrecinctExploreDetail(false));
    dispatch(reqSetIsShowPrecinctDetail(false));
    dispatch(reqSetActivePrecinctID(null));
    dispatch(reqSetIsShowGalleryModal(false));
    dispatch(reqSetIsShowEndGuideTenantSession(false));
    dispatch(reqSetIsShowReplayVideo(false));
    dispatch(reqSetIsShowImmerse(false));
    dispatch(unitExploreAct.reqIsShowViewLine(false));
    dispatch(reqSetIsShowAmenityVirtualTour(false))
  };

  const handleReset3DFilter = () => {
    dispatch(unitExploreAct.reqFilterUnitBedroom([]));
    dispatch(unitExploreAct.reqFilterUnitAvailability([]));
    dispatch(unitExploreAct.reqFilterUnitAspect(""));
    dispatch(unitExploreAct.reqFilterUnitPrice(""));
    dispatch(unitExploreAct.reqFilterUnitLotSize(""));
  }

  const renderTransportOptions = () => {
    if (pagesSettings == null) {
      return <div />;
    }

    const settings = pagesSettings.filter((setting) => setting.name == page);
    if (!settings.length) {
      return <div />;
    }

    const setting = settings[0];
    if (setting.features == null || !setting.features.transport_options) {
      return <div />;
    }

    return (
      <TransitionGroup>
        {
          <CSSTransition timeout={1000} classNames="fade-item">
            <TransportOption
              activeObjectIds={activeObjectIds}
              handleClickTransportOptions={handleClickTransportOptions}
            />
          </CSSTransition>
        }
      </TransitionGroup>
    );
  };

  const [isShowBottomNav, showBottomNav] = useState(true);

  const renderTransportOptionDistricts = () => {
    if (pagesSettings == null) {
      return <div />;
    }

    const settings = pagesSettings.filter((setting) => setting.name == page);
    if (!settings.length) {
      return <div />;
    }

    const setting = settings[0];
    if (
      setting.features == null ||
      !setting.features.transport_option_districts
    ) {
      return <div />;
    }

    return (
      <TransitionGroup>
        {
          <CSSTransition timeout={1000} classNames="fade-item">
            <TransportOptionDistrict
              activeObjectIds={activeObjectIds}
              refScene={refScene}
              handleClickTransportOptionDistricts={
                handleClickTransportOptionDistricts
              }
            />
          </CSSTransition>
        }
      </TransitionGroup>
    );
  };

  const renderAmenitiesOptions = () => {
    return (
      <TransitionGroup>
        {
          <CSSTransition timeout={1000} classNames="fade-item">
            <AmenitiesOption
              activeObjectIds={activeObjectIds}
              handleClickTransportOptions={handleClickTransportOptions}
            />
          </CSSTransition>
        }
      </TransitionGroup>
    );
  };

  const onReplayVideo = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_REPLAY_VIDEO);
    }
    dispatch(reqSetIsShowReplayVideo(true));
  };

  const onSkipReplayVideo = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_SKIP_REPLAY_VIDEO);
    }
    dispatch(reqSetIsShowReplayVideo(false));
  };

  const [init, setInit] = useState(false);
  useEffect(() => {
    if (init || page === PAGES.SESSION_PAGE) {
      return;
    }

    setInit(true);
    dispatch(fetch3DSettings());
    dispatch(fetchAssetsList());
    dispatch(fetchHotspotsList());
    dispatch(fetchPagesSettings());
  }, [init, page])

  return (
    <>
      <HandleSetActivePage pagesSettings={pagesSettings} page={page} />
      <TransitionGroup>
        {page == PAGES.END_GUIDE_TENANT_SESSION_PAGE && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <EndGuideTenantSessionModal resetState={resetState} />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TopNav
        isPresentation={isPresentation}
        isShow={page !== PAGES.ONBOARD_PAGE}
        isLoading={isLoading}
        handleClickCube={handleClickCube}
        handleClickGallery={handleClickGallery}
        handleLocation={handleLocation}
        handleTeam={handleTeam}
        handleSearch={handleSearch}
        handleAmenities={handleAmenities}
        handleNeighborhood={handleNeighborhood}
        handlePpg={handlePpg}
        handleClickResidences={handleClickResidences}
        handleClickImmerse={handleClickImmerse}
      />
      {page == PAGES.ONBOARD_PAGE && (
        <Loading
          isPresentation={isPresentation}
          isLoading={isLoading}
          setIsIntroduction={setIsIntroduction}
        />
      )}
      <TransitionGroup>
        {page == PAGES.LOCATION_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Location isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup>
        {isShowBottomNav && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <BottomNavigation
              isPresentation={isPresentation}
              handleClickEndGuideTenantSession={
                handleClickEndGuideTenantSession
              }
              handleClickTenantEndSession={handleClickTenantEndSession}
              setActiveObjectIds={setActiveObjectIds}
            />
          </CSSTransition>
        )}
      </TransitionGroup>

      {page === PAGES.AMENITIES_OPTION_PAGE && renderAmenitiesOptions()}
      {renderTransportOptions()}
      {renderTransportOptionDistricts()}
      <TransitionGroup>
        {page === PAGES.UNIT_EXPLORER_PAGE && <CSSTransition
          in={page === PAGES.UNIT_EXPLORER_PAGE}
          timeout={1000}
          classNames="fade-left"
          unmountOnExit
        >
          <UnitExplore
            isPresentation={isPresentation}
            handleUnitClick={handleUnitClick}
            setActiveObjectIds={setActiveObjectIds}
            handleAreaClick={(area) => { }}
          />
        </CSSTransition>}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.GALLERY_PAGE && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <Gallery />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.VIEWS_LEVEL && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <ViewsLevels />
          </CSSTransition>
        )}
      </TransitionGroup>
      <RightPanel
        isPresentation={isPresentation}
        page={page}
        handleClickTransportOptions={handleClickTransportOptions}
      />
      <Login />
      {/*<TransitionGroup>*/}
      {/*  {isShowReplayVideo && (*/}
      {/*    <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>*/}
      {/*      <VideoIntro />*/}
      {/*    </CSSTransition>*/}
      {/*  )}*/}
      {/*</TransitionGroup>*/}
      <PostSessionPopup />
      <TransitionGroup>
        {page == PAGES.GALLERY_PAGE && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <HomeGallery />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {isShowVirtualModal && (
          <CSSTransition timeout={200} classNames="fade-item">
            <VirtualModal />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {isShowBookingAppointmentForm && (
          <CSSTransition timeout={200} classNames="fade-item">
            <BookingAppointment />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.TEAM_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <FeatureTab isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {isShowSearch && (
          <CSSTransition
            timeout={200}
            classNames="fade-item"
            unmountOnExit
            resetState={() => setShowSearchModal(false)}
          >
            <SearchV2 />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.AMENITIES_PAGE_V2 && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <AmenitiesV2 isPresentation={isPresentation} />
          </CSSTransition>
        )}
        <TransitionGroup>
          {page == PAGES.NEIGHBORHOOD_PAGE && (
            <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
              <Neighborhood isPresentation={isPresentation} />
            </CSSTransition>
          )}
        </TransitionGroup>
        <TransitionGroup>
          {page == PAGES.AMENITIES_PAGE && (
            <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
              <Amenities gotoAmenityPageV2={handleAmenities} />
            </CSSTransition>
          )}
        </TransitionGroup>
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.PPG_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <Ppg isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.SERVICES_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <Services isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.GALLERY_LANDING_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <GalleryLanding isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {isShowImmerse && (
          <CSSTransition timeout={0} classNames="fade-item">
            <PanoViewer handleClosePanoViewer={() => { }} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <CSSTransition
        in={page !== PAGES.ONBOARD_PAGE}
        timeout={1000}
        classNames="fade-item"
        unmountOnExit
      >
        <div
          className={`d-flex align-items-center position-absolute wrap-bottom-titles ${isShowBottomNav && "mb-5"
            }`}
        >
          <div className="left">
            {/* {page == PAGES.LANDING_PAGE && (
              <button
                onClick={onReplayVideo}
                className="text-uppercase btn-replay"
                role="button"
              >
                <span>{t("REPLAY FILM")}</span>
              </button>
            )} */}
          </div>
        </div>
      </CSSTransition>
      <TransitionGroup>
        {isShowReplayVideo && page !== PAGES.ONBOARD_PAGE && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <VideoIntro isPresentation={isPresentation} onSkipReplayVideo={onSkipReplayVideo} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page === PAGES.VIEWS_PAGE && (
          <CSSTransition timeout={500} classNames="fade-item" unmountOnExit>
            <Views />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.SESSION_PAGE && (
          <CSSTransition timeout={200} classNames="fade-item" unmountOnExit>
            <Session isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <Suspense fallback={null}>
        <GLTF3DLoader />
      </Suspense>
    </>
  );
};

export default ReactUI;
