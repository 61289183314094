import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  BEDROOM_OPTION,
  ASPECT_OPTION,
  AVAILABILITY_OPTIONS,
  WEBSOCKET_CHANNEL,
  ACTION_NAME,
} from '../../constants/options';
import SideNav from '../sidenav';
import * as unitExploreAct from '../../reduxs/unit-explore/action';
import _ from 'lodash';
import FilterGroupRange from '../filter-group-range';
import classNames from 'classnames';
import { debounce } from 'lodash';
import socket from '../../helper/socket';

const UnitFilter = (props) => {
  const { isPresentation, isTransparent, setActiveObjectIds } = props;
  const dispatch = useDispatch();
  const refFilterRangeLot = useRef();
  // const ref = useRef([]);
  const pageRef = useRef(null);

  useEffect(() => {
    function handleUnitExplorerPageEvent(e) {
      onResetAllFilter({ auto_available: e?.detail === 'auto_available' });
    }
    document.addEventListener("UNIT_EXPLORER_PAGE", handleUnitExplorerPageEvent);
    return () => {
      document.removeEventListener("UNIT_EXPLORER_PAGE", handleUnitExplorerPageEvent);
    };
  }, []);
  const isShowUnitList = useSelector(
    (state) => state.unitExplore.isShowUnitList
  );
  const isShowFilter = useSelector((state) => state.unitExplore.isShowFilter);
  const filterUnitBedroom = useSelector(
    (state) => state.unitExplore.filterUnitBedroom
  );
  const filterUnitPrice = useSelector(
    (state) => state.unitExplore.filterUnitPrice
  );
  const filterUnitLotSize = useSelector(
    (state) => state.unitExplore.filterUnitLotSize
  );
  const filterUnitAvailability = useSelector(
    (state) => state.unitExplore.filterUnitAvailability
  );
  const filterUnitPool = useSelector(
    (state) => state.unitExplore.filterUnitPool
  );

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CLICK_UNIT_FILTER) {
      handleApplyFilter(content.data);
    } else if (content.action == ACTION_NAME.CLEAR_ALL_FILTERS) {
      onResetAllFilter(content.data?.options || {});
    } else if (content.action == ACTION_NAME.HIDE_FILTER) {
      onHideFilter();
    }

    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollTop * pageRef.current.scrollHeight;
      }
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const handleApplyFilter = debounce((data) => {
    const filterName = data.name;
    const item = data?.item;

    switch (filterName) {
      case 'FILTER_BEDROOM':
        onChangeBedroom(data.checked, data.item, data.filterUnitBedroom);
        break;
      case 'FILTER_UNIT_POOL':
        onChangeUnitPool(data.value, data.filterUnitPool);
        break;
      case 'FILTER_PRICE':
        onChangePrice(data.price);
        break;
      case 'FILTER_LOT_SIZE':
        onChangeLotSize(data.filterLotSize);
        break;
      case 'FILTER_AVAILABILITY':
        onChangeAvailability(data.checked, item, data.filterUnitAvailability);
        break;
      default:
        break;
    }
  }, 500);

  // const unChecked = () => {
  //   if (!ref.current) return;

  //   for (let i = 0; i < ref.current.length; i++) {
  //     if (ref.current[i]) {
  //       ref.current[i].checked = false;
  //     }
  //   }
  // };

  const onChangeBedroom = (checked, item, filterUnitBedroom) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item,
        checked,
        filterUnitBedroom,
        name: 'FILTER_BEDROOM',
      });
    }
    if (_.find(filterUnitBedroom, (el) => el.id === item.id)) {
      const newBedRoom = filterUnitBedroom.filter((i) => i.id !== item.id);
      dispatch(unitExploreAct.reqFilterUnitBedroom(newBedRoom));
    } else {
      dispatch(
        unitExploreAct.reqFilterUnitBedroom([...filterUnitBedroom, item])
      );
    }
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
  };

  const onChangeRoomType = (e, item, filterUnitRoomType) => {
    if (e.target.checked) {
      dispatch(
        unitExploreAct.reqFilterUnitRoomType([...filterUnitRoomType, item])
      );
    } else {
      dispatch(
        unitExploreAct.reqFilterUnitRoomType(
          filterUnitRoomType.filter((i) => i.id !== item.id)
        )
      );
    }
  };
  const onChangePrice = (data) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        price: data,
        name: 'FILTER_PRICE',
      });
    }
    dispatch(unitExploreAct.reqFilterUnitPrice(data));
    if (!isShowUnitList) {
      dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    }
  };

  const onChangeLotSize = (data) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        filterLotSize: data,
        name: 'FILTER_LOT_SIZE',
      });
    }
    dispatch(unitExploreAct.reqFilterUnitLotSize(data));
    if (!isShowUnitList) {
      dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    }
  };

  const onChangeUnitPool = (value, filterUnitPool) => {
    let newVal = value === filterUnitPool ? undefined : value;
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        value: newVal,
        filterUnitPool: filterUnitPool,
        name: 'FILTER_UNIT_POOL',
      });
    }
    if (newVal !== undefined) {
      dispatch(unitExploreAct.reqSetIsShowUnitList(true));
    }
    dispatch(unitExploreAct.reqFilterUnitPool(newVal));
  };
  const onChangeAvailability = (checked, item, filterUnitAvailability) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_FILTER, {
        item: item,
        filterUnitAvailability,
        checked,
        name: 'FILTER_AVAILABILITY',
      });
    }
    if (checked) {
      dispatch(
        unitExploreAct.reqFilterUnitAvailability([
          ...filterUnitAvailability,
          item,
        ])
      );
    } else {
      dispatch(
        unitExploreAct.reqFilterUnitAvailability(
          filterUnitAvailability.filter((i) => i.id !== item.id)
        )
      );
    }
    dispatch(unitExploreAct.reqSetIsShowUnitList(true));
  };

  const onResetAllFilter = (options = {}) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLEAR_ALL_FILTERS, {
        options,
      });
    }
    // unChecked();
    if (options?.auto_available) {
      dispatch(unitExploreAct.reqSetIsShowUnitList(true));
      dispatch(unitExploreAct.reqFilterUnitAvailability([{ id: 1, title: "Available", value: "available" }]));
    } else {
      dispatch(unitExploreAct.reqFilterUnitAvailability([]));
      dispatch(unitExploreAct.reqSetIsShowUnitList(false));
    }
    dispatch(unitExploreAct.reqFilterUnitBedroom([]));
    dispatch(unitExploreAct.reqFilterUnitAspect(''));
    dispatch(unitExploreAct.reqFilterUnitPool());
    dispatch(unitExploreAct.reqFilterUnitPrice(''));
    dispatch(unitExploreAct.reqFilterUnitLotSize(''));
    dispatch(unitExploreAct.reqSetSelectedUnit(""));
  };

  const onHideFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.HIDE_FILTER);
    }
    dispatch(unitExploreAct.reqSetIsShowFilter(false));
  };

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop:
          event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  };

  if (isPresentation) return null;  // BN-1775

  return (
    <SideNav
      position='left'
      heading={"FILTERS"}
      footer={
        <div className='btn-bottom'>
          <span
            onClick={() => {
              onResetAllFilter();
            }}
            className={classNames(
              'text-center mt-1 text-btn',
              (isShowUnitList && isShowFilter) ||
                filterUnitBedroom.length ||
                filterUnitPrice !== '' ||
                filterUnitLotSize !== '' ||
                filterUnitAvailability.length
                ? ''
                : 'disable'
            )}
          >
            CLEAR FILTERS
          </span>
          <span onClick={onHideFilter} className={`text-center mt-1 text-btn ${isShowUnitList ? '' : 'disable'}`}>
            HIDE FILTERS
          </span>
        </div>
      }
      id='filter'
      isDark
      isTransparent={isTransparent}
    >
      <div
        ref={pageRef}
        className="disable-scroll-bar filter-panel"
        onScroll={onScroll}
      >
        {/* filter avaibility */}
        <div className='filter-group'>
          <h2 className='heading'>AVAILABILITY</h2>
          <ul className='list-group availability flex flex-column justify-content-between flex-wrap'>
            {AVAILABILITY_OPTIONS.map((item, key) => (
              <li
                className={`list-group-item border-none`}
                key={`filter-item-${key}`}
              >
                <div className="pretty p-svg p-plain">
                  <input
                    // ref={(element) => {
                    //   ref.current[key] = element;
                    // }}
                    onChange={(e) =>
                      onChangeAvailability(
                        e.target.checked,
                        item,
                        filterUnitAvailability
                      )
                    }
                    type="checkbox"
                    checked={filterUnitAvailability
                      .map((avaibility) => avaibility.value)
                      .includes(item.value)}
                  />
                  <div className='state'>
                    <img className='svg' src='icons/checkbox.svg' />
                    <label>{item.title}</label>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* filter celling height */}

        <FilterGroupRange
          label={{
            title: "PRICE RANGE",
            min: 'MIN',
            max: 'MAX',
          }}
          filterValue={{
            min: filterUnitPrice?.min,
            max: filterUnitPrice?.max,
          }}
          icon="-----"
          max={20_000_000}
          onChange={onChangePrice}
          customeClass='wrapper-price'
        />

        {/* filter celling height */}
        <FilterGroupRange
          ref={refFilterRangeLot}
          label={{
            title: 'sq. ft.',
            min: 'MIN',
            max: 'MAX',
          }}
          max={5000}
          icon={'----'}
          filterValue={{
            min: filterUnitLotSize?.min,
            max: filterUnitLotSize?.max,
          }}
          onChange={onChangeLotSize}
          customeClass='wrapper-size'
        />

        {/* filter bedrooms  */}
        <div className='filter-group'>
          <h2 className='heading'>BEDROOMS</h2>
          <ul className='list-group flex flex-row justify-content-between flex-wrap'>
            {BEDROOM_OPTION.map((item, key) => (
              <li
                className="bedroom-item list-group-item border-none"
                key={key}
              >
                <div className="pretty p-svg p-plain">
                  <input
                    onChange={(e) =>
                      onChangeBedroom(e.target.checked, item, filterUnitBedroom)
                    }
                    type="checkbox"
                    checked={filterUnitBedroom
                      .map((item) => item.value)
                      .includes(item.value)}
                  />

                  <div className="state">
                    <img className="svg" src="icons/checkbox.svg" />
                    <label>{item.value}</label>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* filter aspect */}
        <div className="filter-group aspect">
          <h2 className="heading">Private Pool</h2>
          <ul className="list-group flex flex-row justify-content-between flex-wrap">
            <li className="list-group-item border-none private-pool" key={'yes'}>
              <div className="pretty p-svg p-plain">
                <input
                  onChange={(e) => onChangeUnitPool(true, filterUnitPool)}
                  type="checkbox"
                  checked={filterUnitPool === true}
                />

                <div className="state">
                  <img className="svg" src="icons/checkbox.svg" />
                  <label>Yes</label>
                </div>
              </div>
            </li>
            <li className="list-group-item border-none" key={'no'}>
              <div className="pretty p-svg p-plain">
                <input
                  onChange={(e) => onChangeUnitPool(false, filterUnitPool)}
                  type="checkbox"
                  checked={filterUnitPool === false}
                />
                <div className="state">
                  <img className="svg" src="icons/checkbox.svg" />
                  <label>No</label>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </SideNav>
  );
};

export default UnitFilter;
