import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PopupGallery from "../media/popup-gallery";
import {
  reqSetActiveGalleryId,
  reqSetIsTransparent,
  reqSetPage,
} from "../../reduxs/home/action";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";
import socket from "../../helper/socket";

const VirtualTour = ({isPresentation, isHidden}) => {
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unitExplore.selectedUnit);
  const page = useSelector((state) => state.home.page);

  const isShowViewLine = useSelector(
    (state) => state.unitExplore.isShowViewLine
  );

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.ENABLE_TRANSPARENT_MODE) {
      enableTransparent();
    }
    if (content.action === ACTION_NAME.DISABLE_TRANSPARENT_MODE) {
      showFilter();
    }
    if (content.action === ACTION_NAME.CLOSE_UNIT_VIEWLINE) {
      hideViewLines();
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const showFilter = () => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.DISABLE_TRANSPARENT_MODE)
    }
    dispatch(reqSetIsTransparent(false));
  };

  const hideViewLines = () => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_VIEWLINE)
    }
    dispatch(unitExploreAct.reqIsShowViewLine(false));
    dispatch(reqSetIsTransparent(false));
    dispatch(reqSetActiveGalleryId(""));

    if (page === PAGES.VIEWS_LEVEL) dispatch(reqSetPage(PAGES.VIEWS_PAGE));
    else dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
  };

  const enableTransparent = () => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.ENABLE_TRANSPARENT_MODE)
    }
    dispatch(reqSetIsTransparent(true))
  }

  return (
    <>
      <div className="virtual-tour">
        <PopupGallery
          isPresentation={isPresentation}
          listImage={unit?.view?.media}
          onClose={hideViewLines}
          isClientImages={true}
          show={isShowViewLine}
          isShowPreNextSlide={false}
          isShowViewLine={isShowViewLine}
          nameUnit={unit?.name}
        >
          {/* <div className="viewlines-note">
            *THESE VIEWLINE IMAGES ARE PER FLOOR
          </div> */}
        </PopupGallery>
      </div>
    </>
  );
};

export default VirtualTour;
