import * as Types from './type';
import transportOptionApi from '../../apis/api/transportOption';
import subPrecinctApi from '../../apis/api/subPrecinct';

const actSetPage = (data) => ({
  type: Types.SET_PAGE,
  data,
});

const actSetIsShowImmerse = (data) => ({
  type: Types.SET_IS_SHOW_IMMERSE,
  data,
});

const actSetIsExpandNav = (data) => ({
  type: Types.SET_IS_EXPAND_NAV,
  data,
});

const actSetActivePatourId = (data) => ({
  type: Types.SET_ACTIVE_PANTOUR_ID,
  data,
});

const actSetIsShowExploreModal = (data) => ({
  type: Types.SET_IS_SHOW_EXPLORE_MODAL,
  data,
});

const actSetIsTransparent = (data) => ({
  type: Types.SET_IS_TRANSPARENT,
  data,
});

const actSetActiveGalleryId = (data) => ({
  type: Types.SET_ACTIVE_GALLERY_ID,
  data,
});

const actSetActiveUnit = (data) => ({
  type: Types.SET_ACTIVE_UNIT,
  data,
});

const actSetActiveAreaId = (data) => ({
  type: Types.SET_ACTIVE_AREA_ID,
  data,
});

const actSetIsShowSubPrecinctDetail = (data) => ({
  type: Types.SET_IS_SHOW_SUB_PRECINCT_DETAIL,
  data,
});

const actActiveDistrictYear = (data) => ({
  type: Types.SET_ACTIVE_DISTRICT_YEAR,
  data,
});

const actSetIsShowLoginModal = (data) => ({
  type: Types.SET_IS_SHOW_LOGIN_MODAL,
  data,
});

const actSetTransportOptions = (data) => ({
  type: Types.SET_TRANSPORT_OPTIONS,
  data,
});

const actSetTransportOptionDistricts = (data) => ({
  type: Types.SET_TRANSPORT_OPTION_DISTRICTS,
  data,
});

const actSetSubPrecincts = (data) => ({
  type: Types.SET_SUB_PRECINCTS,
  data,
});

const actSetActiveTransportOption = (data) => ({
  type: Types.SET_ACTIVE_TRANSPORT_OPTION,
  data,
});

const actSetActiveTransportOptionDistricts = (data) => ({
  type: Types.SET_ACTIVE_TRANSPORT_OPTION_DISTRICTS,
  data,
});

const actSetIsShowEndGuideTenantSession = (data) => ({
  type: Types.SET_IS_SHOW_END_GUIDE_TENANT_SESSION,
  data,
});

const actSetActiveEndGuideTenantSessionId = (data) => ({
  type: Types.SET_ACTIVE_END_GUIDE_TENANT_SESSION_ID,
  data,
});

const setIsShowBookingAppointmentForm = (data) => ({
  type: Types.SET_IS_SHOW_BOOKING_APPOINTMENT_FORM,
  data,
});

export const reqSetIsShowBookingAppointmentForm = (data) => (dispatch) => {
  return dispatch(setIsShowBookingAppointmentForm(data));
};

export const reqSetPage = (data) => (dispatch) => {
  return dispatch(actSetPage(data));
};

export const reqSetIsShowImmerse = (data) => (dispatch) => {
  return dispatch(actSetIsShowImmerse(data));
};

export const reqSetIsExpandNav = (data) => (dispatch) => {
  return dispatch(actSetIsExpandNav(data));
};

export const reqSetActivePatourId = (data) => (dispatch) => {
  return dispatch(actSetActivePatourId(data));
};

export const reqSetIsShowExploreModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowExploreModal(data));
};

export const reqSetIsTransparent = (data) => (dispatch) => {
  return dispatch(actSetIsTransparent(data));
};

export const reqSetActiveGalleryId = (data) => (dispatch) => {
  return dispatch(actSetActiveGalleryId(data));
};

export const reqSetActiveUnit = (data) => (dispatch) => {
  return dispatch(actSetActiveUnit(data));
};

export const reqSetActiveAreaId = (data) => (dispatch) => {
  return dispatch(actSetActiveAreaId(data));
};

export const reqSetIsShowSubPrecinctDetail = (data) => (dispatch) => {
  return dispatch(actSetIsShowSubPrecinctDetail(data));
};

export const reqSetActiveDistrictYear = (data) => (dispatch) => {
  return dispatch(actActiveDistrictYear(data));
};

export const reqSetIsShowLoginModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowLoginModal(data));
};

export const reqSetTransportOptions = (data) => (dispatch) => {
  return transportOptionApi.getAmenitiesOptions().then((data) => {
    dispatch(actSetTransportOptions(data));
  });
};

export const reqSetTransportOptionDistricts = (data) => (dispatch) => {
  return transportOptionApi.getTransportOptionDistricts().then((data) => {
    dispatch(actSetTransportOptionDistricts(data));
  });
};

export const reqSetSubPrecincts = (data) => (dispatch) => {
  return subPrecinctApi.getSubPrecincts().then((data) => {
    dispatch(actSetSubPrecincts(data));
  });
};

export const reqSetActiveTransportOption = (data) => (dispatch) => {
  return dispatch(actSetActiveTransportOption(data));
};

export const reqSetActiveTransportOptionDistricts = (data) => (dispatch) => {
  return dispatch(actSetActiveTransportOptionDistricts(data));
};

export const reqSetIsShowEndGuideTenantSession = (data) => (dispatch) => {
  return dispatch(actSetIsShowEndGuideTenantSession(data));
};

export const reqSetActiveEndGuideTenantSessionId = (data) => (dispatch) => {
  return dispatch(actSetActiveEndGuideTenantSessionId(data));
};

const actSetIsPresentation = (data) => ({
  type: Types.SET_IS_PRESENTATION,
  data,
});

export const reqSetIsPresentation = (data) => (dispatch) => {
  return dispatch(actSetIsPresentation(data));
};
