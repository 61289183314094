import React, { useState } from "react";

const Row = (props) => {
  const { data, onClickDetail } = props;
  const {
    name,
    level,
    availabilityStatus,
    areaSqm,
    bedrooms,
    hasPool,
    price,
    bathrooms,
  } = data;

  const formatMoney = (price) => {
    if (!price) return 0;
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumSignificantDigits: Math.trunc(Math.abs(price)).toFixed().length,
    });
    return formatter.format(price);
  };

  return (
    <tr
      style={{
        display: "table",
        width: "100%",
        tableLayout: "fixed",
      }}
    >
      <td className="col-4">Residence {name}</td>
      <td className="col-4 text-capitalize">{availabilityStatus.replace("_", " ")}</td>
      <td className="col-3">{formatMoney(price)}</td>
      <td className="col-2 text-capitalize">{level}</td>
      <td className="col-2">{areaSqm}</td>
      <td className="col-2">{bedrooms}</td>
      <td className="col-2">{bathrooms}</td>
      <td className="col-3">{hasPool ? "Yes": "No"}</td>
      <td className="col-1" style={{ verticalAlign: "middle" }}>
        <div className="actionButton" onClick={onClickDetail}>
          <img src={`/icons/dots.svg`} alt="" />
        </div>
      </td>
    </tr>
  );
}

export default Row;
