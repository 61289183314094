import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { reqSetPage } from "../../reduxs/home/action";
import { GALLERY_TYPE, PAGES } from "../../constants/options";
import { actIntroduction } from "../../reduxs/scene/action";
import { reqGetCustomerProfile, reqSetCustomerProfile } from "../../reduxs/user/action";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import img1 from "../../assets/images/post-session-image-1.png";
import img2 from "../../assets/images/post-session-image-2.png";
import img3 from "../../assets/images/post-session-image-3.png";
import img4 from "../../assets/images/post-session-image-4.png";
import img5 from "../../assets/images/post-session-image-5.png";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import socket from "../../helper/socket";
import SessionFooter from "./session-footer";
import { reqGetCustomerPreference } from "../../reduxs/precinct-explore/action";
import { useParams } from "react-router-dom";
import FloorplanModal from "./FloorplanModal";
import { formatMoney, numberWithCommas } from "../../helper/unit";
import { reqGetListGalleries } from '../../reduxs/cms/action';
import { getUploadedServerMediaUrl } from '../../helper/media';
import { reqGetGalleryList } from '../../reduxs/gallery/action';

const Session = ({ isPresentation }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.user.customer);
  const customerPreference = useSelector(
    (state) => state.precinctExplore.customerPreference
  );
  const galleries = useSelector((state) => state.gallery.galleries);
  const paths = history.location.pathname.split("/");
  const params = useParams();
  const customerId = params.customerId || paths[paths.length - 1];

  const pageRef = useRef(null);

  const [activeFloorplanMedia, setActiveFloorplanMedia] = useState();

  useEffect(() => {
    if (!isPresentation) {
      if (!customer) {
        dispatch(reqGetCustomerProfile(customerId));
      } else {
        socket.emitUIActionEvent(ACTION_NAME.SYNC_CUSTOMER_END_SESSION, {
          customerId: customer.id,
        });
      }
    }
    if (customer?.id) {
      dispatch(reqGetCustomerPreference(customer?.id));
    }
  }, [customer]);

  useEffect(() => {
    if (galleries?.length) {
      return;
    }
    dispatch(reqGetGalleryList());
  }, [])

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollPercentage *
          (pageRef.current.scrollHeight - pageRef.current.clientHeight);
      }
    }
    if (content.action === ACTION_NAME.SYNC_CUSTOMER_END_SESSION) {
      dispatch(reqGetCustomerProfile(content.data.customerId));
    }
    if (content.action === ACTION_NAME.CLICK_END_SESSION) {
      handleCloseSession();
    }
    if (content.action === ACTION_NAME.SET_ACTIVE_FLOORPLAN) {
      handleOpenFloorplan(content.data.floorplan);
    }
    if (content.action === ACTION_NAME.CLOSE_ACTIVE_FLOORPLAN) {
      handleCloseFloorplan();
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const onScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollPercentage: scrollTop / (scrollHeight - clientHeight),
      });
    }
  };

  const onCloseSession = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_END_SESSION);
    }
    handleCloseSession();
  };

  const handleCloseSession = () => {
    dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
    history.push(isPresentation ? "/presentation" : "/");
    // dispatch(actIntroduction(true));
    dispatch(reqSetCustomerProfile(""));
  };

  const handleOpenFloorplan = (floorplan) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SET_ACTIVE_FLOORPLAN, { floorplan });
    }
    setActiveFloorplanMedia(floorplan);
  };

  const handleCloseFloorplan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_ACTIVE_FLOORPLAN);
    }
    setActiveFloorplanMedia(undefined);
  };

  return (
    <>
      <FloorplanModal
        key={activeFloorplanMedia?.[0]?.id}
        mediaImages={activeFloorplanMedia}
        onClose={handleCloseFloorplan}
        isPresentation={isPresentation}
      />
      <div
        ref={pageRef}
        onScroll={onScroll}
        className="static-page-wf sessionPage"
      >
        <div className="scroll-view">
          <div className="content-1">
            <div className="main-image" />
            <span className="scroll-text">SCROLL TO EXPLORE</span>
            <div className="main-text">
              {customer?.name ? `${customer.name}'s` : "your"} collection of
              <br />
              one-of-a-kind water front
              <br />
              residences that has been
              <br />
              thoughtfully considered
              <br />
              during your experience with
              <br />
              the bay harbor towers
            </div>
            {!params.customerId && (
              <div className="save-close-btn" onClick={onCloseSession}>
                SAVE AND CLOSE
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="10"
                  viewBox="0 0 33 10"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1704_3784)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M30.293 5.50048H0.5V4.50048H30.293L27.146 1.35448L27.854 0.646484L31.854 4.64648L32.207 5.00048L31.854 5.35448L27.854 9.35448L27.146 8.64648L30.293 5.50048Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1704_3784">
                      <rect
                        width="32"
                        height="10"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            )}
          </div>
          <div className="preferences-list">
            {customerPreference?.units?.length &&
              customerPreference.units.map((unit, i) => {
                if (!galleries?.length) {
                  return;
                }
                unit.floorPlan = galleries.filter((gallery) => gallery.type === GALLERY_TYPE.FLOOR_PLANS && gallery.unit?.id === unit.id);

                return (
                  <div className="ct-wrapper">
                    <div
                      className={`content-preference ${i % 2 === 1 ? "reverse" : ""}`}
                    >
                      <div className="residence-info">
                        <div className="r-title">
                          RESIDENCE
                          <br />
                          {unit.name}
                        </div>
                        <div className="r-content">
                          <div className="r-feats">
                            {(unit.bedrooms || unit.bathrooms) && (
                              <div className="r-feats-item">
                                {unit.bedrooms && unit.bedrooms > 0
                                  ? `${unit.bedrooms} Bedroom + Den`
                                  : ""}
                                {unit.bathrooms && unit.bathrooms > 0
                                  ? ` / ${unit.bathrooms} Bathroom`
                                  : ""}
                              </div>
                            )}
                            {unit.price && (
                              <div className="r-feats-item">
                                Price — {formatMoney(unit.price)}
                              </div>
                            )}
                            <div className="r-feats-item">
                              Lot Size — {numberWithCommas(unit.areaSqm)} SF / {Math.round(unit.areaSqm * 0.092903)} SM
                            </div>
                            {/* <div className="r-feats-item">
                            Interior — 2,815 SF / 261 SM
                          </div>
                          <div className="r-feats-item">
                            Exterior — 269 SF / 24 SM
                          </div> */}
                            {unit.hasPool && (
                              <div className="r-feats-item">Private Pool</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="divider"> </div>
                      <img
                        className="preference-floorplan"
                        onClick={() =>
                          handleOpenFloorplan(unit.floorPlan?.flatMap((gallery) => gallery.media))
                        }
                        alt="imgFloorplan"
                        src={getUploadedServerMediaUrl(unit.floorPlan?.[0]?.media[0]?.path)}
                      />
                    </div>
                  </div>
                )
              })}
          </div>
          <div className="session-images">
            <div className="session-image-wrapper-1">
              <img className="session-image-1" src={img1} />
              <img className="session-image-2" src={img2} />
            </div>
            <div className="session-image-wrapper-2">
              <img className="session-image-3" src={img3} />
            </div>
            <div className="session-image-wrapper-3">
              <img className="session-image-4" src={img4} />
              <img className="session-image-5" src={img5} />
            </div>
          </div>
          <SessionFooter />
        </div>
      </div>
    </>
  );
};

export default Session;
