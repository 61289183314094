import React, { useEffect, useRef, useState, createRef } from 'react';
import { Canvas, useThree, extend, useFrame, useLoader } from '@react-three/fiber';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import * as THREE from 'three';
import { CustomControls } from './CustomControls';
import { OrbitCustomControls } from './OrbitCustomControls';
import FPSStats from 'react-fps-stats';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";
import testObjectApi from '../../apis/api/test-object';
import { Html, useProgress, useHelper } from '@react-three/drei';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as dat from "dat.gui";
import { DirectionalLightHelper, HemisphereLightHelper, PointLightHelper, SpotLightHelper } from 'three';
import { Sky } from './Sky';

extend({ CustomControls });
extend({ OrbitCustomControls });
extend({ Sky })

const TestScene = React.memo((props) => {
  const { refScene } = props;
  const lightGroup = useRef();
  const [fbxObjects, setFbxObjects] = useState([]);
  const [glfxObjects, setGlfxObjects] = useState([]);
  const [glbObjects, setGlbObjects] = useState([]);
  const [assets, setAssets] = useState([]);
  const controls = useRef();
  const fbxGroup = useRef();
  const [skyConfig, setSkyConfig] = useState({
    turbidity: 14,
    rayleigh: 3,
    mieCoefficient: 0.004,
    mieDirectionalG: 0.9,
    elevation: 0,
    azimuth: 0,
    exposure: 1.0,
    visible: true
  });

  const LightType = {
    directional: 'directional',
    ambient: 'ambient',
    hemisphere: 'hemisphere',
    point: 'point',
    spot: 'spot',
  }

  const [lightDatas, setLightDatas] = useState([{
    "type": LightType.directional,
    "color": "#ffffff",
    "position": { "x": 20, "y": 20, "z": 20},
    "intensity": 1,
    "shadow": true,
    "shadowBias": 0.0,
    "shadowNormalBias": 0.0,
    "shadowRadius": 1.0,
    "index": 0
  }]);
  var gui;

  const sky = useRef();
  const sun = new THREE.Vector3(0, 0.0, 0);

  const lightRefs = lightDatas.map(() => createRef());


  // MARK: GUI

  const addNew = {
    addDirectionLight: function() {
    },
    addAmbientLight: function() {
    },
    addHemisphereLight: function() {
    },
    addPointLight: function() {
    },
    addSpotLight: function() {
    },
  }

  const action = {
    exportJson: function() {
      const jsonExport = {
        "sky": skyConfig,
        "lights": lightDatas,
        "assets": assets
      }

      const filename = 'data.json';
      const jsonStr = JSON.stringify(jsonExport);

      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    importJson: function() {
      let input = document.createElement('input');
      input.type = 'file';
      input.multiple = false;
      input.accept = "application/json";

      function readFile(reader, file) {
        const deferredResult = new Promise(resolve => {
          reader.addEventListener('load', function getResult() {
            resolve(reader.result)
            reader.removeEventListener('load', getResult)
          })
        })

        reader.readAsText(file)

        return deferredResult
      }

      input.onchange = async () => {
        let files = Array.from(input.files);
        if (files.length > 0) {
          let file = files[0];
          const result = await readFile(new FileReader(), file);
          const data = JSON.parse(result);

          if (data.sky) {
            setSkyConfig(data.sky);
          }

          if (data.lights) {
            setLightDatas(data.lights);
          }

          if (data.assets) {
            setAssets(data.assets);
          }
        }
      };

      input.click();
    }
  }

  const addNewDirectionalLight = () => {
    const index = lightDatas.length;
    const newLightData = newDirectionLight();
    newLightData.index = index;

    const newLightDatas = lightDatas.concat([newLightData]);
    setLightDatas(newLightDatas);
  }

  const addNewAmbientLight = () => {
    const index = lightDatas.length;
    const newLightData = newAmbientLight();
    newLightData.index = index;

    const newLightDatas = lightDatas.concat([newLightData]);
    setLightDatas(newLightDatas);
  }

  const addNewHemisphereLight = () => {
    const index = lightDatas.length;
    const newLightData = newHemisphereLight();
    newLightData.index = index;

    const newLightDatas = lightDatas.concat([newLightData]);
    setLightDatas(newLightDatas);
  }

  const addNewPointLight = () => {
    const index = lightDatas.length;
    const newLightData = newPointLight();
    newLightData.index = index;

    const newLightDatas = lightDatas.concat([newLightData]);
    setLightDatas(newLightDatas);
  }

  const addNewSpotLight = () => {
    const index = lightDatas.length;
    const newLightData = newSpotLight();
    newLightData.index = index;

    const newLightDatas = lightDatas.concat([newLightData]);
    setLightDatas(newLightDatas);
  }

  const newDirectionLight = () => {
    return {
      "type": LightType.directional,
      "color": "#ffffff",
      "position": { "x": 20, "y": 20, "z": 20},
      "intensity": 1,
      "shadow": true,
      "shadowBias": 0.0,
      "shadowNormalBias": 0.0,
      "shadowRadius": 1.0
    }
  };

  const newAmbientLight = () => {
    return {
      "type": LightType.ambient,
      "color": "#ffffff",
      "intensity": 1
    }
  };

  const newHemisphereLight = () => {
    return {
      "type": LightType.hemisphere,
      "color": "#ffffff",
      "groundColor": "#ffffff",
      "position": { "x": 0, "y": 1, "z": 0},
      "intensity": 1
    }
  };

  const newPointLight = () => {
    return {
      "type": LightType.point,
      "color": "#ffffff",
      "position": { "x": 10, "y": 10, "z": 10},
      "intensity": 1,
      "distance": 0,
      "decay": 1,
      "shadow": true
    }
  };

  const newSpotLight = () => {
    return {
      "type": LightType.spot,
      "color": "#ddff0a",
      "intensity": 1,
      "position": { "x": -10, "y": 10, "z": -10},
      "distance": 0,
      "decay": 1,
      "shadow": true,
      "angle": 0.263,
      "penumbra": 0
    }
  };

  function skyGuiChanged() {

    if (sky == undefined || sky.current == undefined) {
      return;
    }

    const uniforms = sky.current.material.uniforms;
    uniforms[ 'turbidity' ].value = skyConfig.turbidity;
    uniforms[ 'rayleigh' ].value = skyConfig.rayleigh;
    uniforms[ 'mieCoefficient' ].value = skyConfig.mieCoefficient;
    uniforms[ 'mieDirectionalG' ].value = skyConfig.mieDirectionalG;

    const phi = THREE.MathUtils.degToRad( 90 - skyConfig.elevation );
    const theta = THREE.MathUtils.degToRad( skyConfig.azimuth );

    sun.setFromSphericalCoords( 1, phi, theta );

    uniforms[ 'sunPosition' ].value.copy( sun );

    sky.current.exposure = skyConfig.exposure;
    sky.current.visible = skyConfig.visible;
  }

  console.log('Version 1.33.3');

  function RenderLights() {
    for (var i = 0; i < lightDatas.length; i++) {
      if (lightDatas[i].type == LightType.directional) {
        useHelper(lightRefs[i], DirectionalLightHelper);
      } else if (lightDatas[i].type == LightType.hemisphere) {
        useHelper(lightRefs[i], HemisphereLightHelper);
      } else if (lightDatas[i].type == LightType.point) {
        useHelper(lightRefs[i], PointLightHelper);
      } else if (lightDatas[i].type == LightType.spot) {
        useHelper(lightRefs[i], SpotLightHelper);
      }
    }

    return (
      <group ref={lightGroup}>
        {lightDatas.map((item, index) => {
          if (item.type == LightType.directional) {
            return <directionalLight
              ref={lightRefs[index]}
              intensity={item.intensity}
              key={index}
              name={`${item.type}${item.index}`}
              castShadow={item.shadow}
              color={item.color}
              position={[item.position.x, item.position.y, item.position.z]}
              shadow-mapSize-height={2048}
              shadow-mapSize-width={2048}
              shadow-camera-near={100}
              shadow-camera-far={4000}
              shadow-camera-left={-2000}
              shadow-camera-right={2000}
              shadow-camera-top={2000}
              shadow-camera-bottom={-2000}
              shadow-autoUpdate={false}
              shadow-bias={item.shadowBias}
              shadow-normalBias={item.shadowNormalBias}
              shadow-radius={item.shadowRadius}
            />
          } else if (item.type == LightType.ambient) {
            return <ambientLight
              intensity={item.intensity}
              key={index}
              name={`${item.type}${item.index}`}
              color={item.color}
            />
          } else if (item.type == LightType.hemisphere) {
            return <hemisphereLight
              ref={lightRefs[index]}
              intensity={item.intensity}
              key={index}
              position={[item.position.x, item.position.y, item.position.z]}
              name={`${item.type}${item.index}`}
              color={item.color}
              groundColor={item.groundColor}
            />
          } else if (item.type == LightType.point) {
            return <pointLight
              ref={lightRefs[index]}
              intensity={item.intensity}
              key={index}
              position={[item.position.x, item.position.y, item.position.z]}
              name={`${item.type}${item.index}`}
              color={item.color}
              distance={item.distance}
              decay={item.decay}
              castShadow={item.shadow}
            />
          } else if (item.type == LightType.spot) {
            return <spotLight
              ref={lightRefs[index]}
              intensity={item.intensity}
              key={index}
              position={[item.position.x, item.position.y, item.position.z]}
              name={`${item.type}${item.index}`}
              color={item.color}
              distance={item.distance}
              decay={item.decay}
              castShadow={item.shadow}
              angle={item.angle}
              penumbra={item.penumbra}
            />
          }
        })}
      </group>
    );
  };

  const SkyModel = React.memo(() => {
    const {
      gl,
    } = useThree();

    return <sky ref={sky} scale={[450000, 450000, 450000]} />;
  });
  SkyModel.displayName = 'SkyModel';

  const main = {
    layerString: "0"
  }

  function RenderGUI() {

    gui = new dat.GUI();

    gui.add(main, 'layerString').onChange(function() {
      if (controls != null && controls.current != null) {
        let layers = main.layerString.split(",");
        controls.current.hideAll();

        for (var i = 0; i < lightDatas.length; i++) {
          lightRefs[i].current.layers.disableAll();
        }
        layers.push(0);

        layers.forEach(element => {
          controls.current.showLayer(element);
          for (var i = 0; i < lightDatas.length; i++) {
            lightRefs[i].current.layers.enable(element);
          }
        });
      }
    });

    const skyFolder = gui.addFolder('Sky');
    skyFolder.add( skyConfig, 'turbidity', 0.0, 20.0, 0.1 ).onChange( skyGuiChanged );
    skyFolder.add( skyConfig, 'rayleigh', 0.0, 4, 0.001 ).onChange( skyGuiChanged );
    skyFolder.add( skyConfig, 'mieCoefficient', 0.0, 0.1, 0.001 ).onChange( skyGuiChanged );
    skyFolder.add( skyConfig, 'mieDirectionalG', 0.0, 1, 0.001 ).onChange( skyGuiChanged );
    skyFolder.add( skyConfig, 'elevation', 0, 90, 0.1 ).onChange( skyGuiChanged );
    skyFolder.add( skyConfig, 'azimuth', - 180, 180, 0.1 ).onChange( skyGuiChanged );
    skyFolder.add( skyConfig, 'exposure', 0, 1, 0.0001 ).onChange( skyGuiChanged );
    skyFolder.add( skyConfig, 'exposure', 0, 1, 0.0001 ).onChange( skyGuiChanged );
    skyFolder.add( skyConfig, 'visible').onChange( skyGuiChanged );

    const newFolder = gui.addFolder('Add new');

    newFolder.add(addNew, 'addDirectionLight').onChange( addNewDirectionalLight );
    newFolder.add(addNew, 'addAmbientLight').onChange( addNewAmbientLight );
    newFolder.add(addNew, 'addHemisphereLight').onChange( addNewHemisphereLight );
    newFolder.add(addNew, 'addPointLight').onChange( addNewPointLight );
    newFolder.add(addNew, 'addSpotLight').onChange( addNewSpotLight );

    const lightFolder = gui.addFolder('Lights');
    lightDatas.forEach(data => {
      const lightId = `${data.type} ${data.index}`
      let folder = lightFolder.addFolder(lightId);
      data.remove = function() {
        let newLights = lightDatas.slice(0);
        newLights = newLights.filter(item => `${item.type} ${item.index}` != lightId);
        setLightDatas(newLights);
      }

      folder.add(data, 'intensity', 0, 10, 0.05).onChange(function() {
        let light = lightGroup.current.children.find((e) => e.name == `${data.type}${data.index}`);
        light.intensity = data.intensity;
      });

      folder.addColor(data, 'color').onChange( function ( val ) {

        let light = lightGroup.current.children.find((e) => e.name == `${data.type}${data.index}`);
        light.color.set(val);
      });

      if (data.type == LightType.directional || data.type == LightType.point || data.type == LightType.hemisphere || data.type == LightType.spot) {
        let positionFolder = folder.addFolder('position');
        const maxPositionValue = 500;
        positionFolder.add(data.position, 'x', -maxPositionValue, maxPositionValue, 0.1).onChange(function() {
          let light = lightGroup.current.children.find((e) => e.name == `${data.type}${data.index}`);
          light.position.x = data.position.x;
        });
        positionFolder.add(data.position, 'y', -maxPositionValue, maxPositionValue, 0.1).onChange(function() {
          let light = lightGroup.current.children.find((e) => e.name == `${data.type}${data.index}`);
          light.position.y = data.position.y;
        });
        positionFolder.add(data.position, 'z', -maxPositionValue, maxPositionValue, 0.1).onChange(function() {
          let light = lightGroup.current.children.find((e) => e.name == `${data.type}${data.index}`);
          light.position.z = data.position.z;
        });
      }

      if (data.type == LightType.directional || data.type == LightType.point || data.type == LightType.spot) {
        folder.add(data, 'shadow').onChange(function() {
          let light = lightGroup.current.children.find((e) => e.name == `${data.type}${data.index}`);
          light.castShadow = data.shadow;
        });
      }

      if (data.type == LightType.directional) {

        folder.add(data, 'shadowBias', 0, 10.0, 0.0001).onChange(function() {
          let light = lightGroup.current.children.find((e) => e.name == `${data.type}${data.index}`);
          light.shadow.bias = data.shadowBias;
        });

        folder.add(data, 'shadowNormalBias', 0, 10.0, 0.0001).onChange(function() {
          let light = lightGroup.current.children.find((e) => e.name == `${data.type}${data.index}`);
          light.shadow.normalBias = data.shadowNormalBias;
        });

        folder.add(data, 'shadowRadius', 0, 10.0, 0.0001).onChange(function() {
          let light = lightGroup.current.children.find((e) => e.name == `${data.type}${data.index}`);
          light.shadow.radius = data.shadowRadius;
        });
      } else if (data.type == LightType.hemisphere) {
        folder.addColor(data, 'groundColor').onChange( function ( val ) {
          let light = lightGroup.current.children.find((e) => e.name == `${data.type}${data.index}`);
          light.groundColor.set(val);
        });

      } else if (data.type == LightType.point) {
        folder.add(data, 'distance', 0, 200.0, 0.001).onChange(function() {
          let light = lightGroup.current.children.find((e) => e.name == `${data.type}${data.index}`);
          light.distance = data.distance;
        });

        folder.add(data, 'decay', 0, 10.0, 0.001).onChange(function() {
          let light = lightGroup.current.children.find((e) => e.name == `${data.type}${data.index}`);
          light.decay = data.decay;
        });
      } else if (data.type == LightType.spot) {
        folder.add(data, 'angle', -Math.PI/2, Math.PI/2, 0.001).onChange(function() {
          let light = lightGroup.current.children.find((e) => e.name == `${data.type}${data.index}`);
          light.angle = data.angle;
        });

        folder.add(data, 'penumbra', 0, 1.0, 0.001).onChange(function() {
          let light = lightGroup.current.children.find((e) => e.name == `${data.type}${data.index}`);
          light.penumbra = data.penumbra;
        });
      }

      folder.add(data, 'remove');

    });

    const assetsFolder = gui.addFolder('Assets');
    (assets ?? []).forEach(data => {
      let folder = assetsFolder.addFolder(`${data.id}`);

      folder.add(data, '3d_filename');

      folder.add(data, 'receive_shadow').onChange(function() {
        let object3d = fbxGroup.current.children.find((e) => e.name == `${data.id}`);
        if (object3d == undefined) {
          return
        }
        object3d.receiveShadow = data.receive_shadow;
      });

      folder.add(data, 'cast_shadow').onChange(function() {
        let object3d = fbxGroup.current.children.find((e) => e.name == `${data.id}`);
        if (object3d == undefined) {
          return
        }
        object3d.castShadow = data.cast_shadow;
      });

      folder.add(data, 'layerString').onChange(function() {
        let object3d = fbxGroup.current.children.find((e) => e.name == `${data.id}`);
        if (object3d == undefined) {
          return
        }
        data.layer = data.layerString.split(",");

        object3d.layers.disableAll();
        data.layer.forEach(element => {
          object3d.layers.enable(element);
        });
      });

      folder.add(data, 'visible').onChange(function() {
        let object3d = fbxGroup.current.children.find((e) => e.name == `${data.id}`);
        if (object3d == undefined) {
          return
        }
        object3d.visible = data.visible;
      });
    });

    gui.add(action, 'exportJson');
    gui.add(action, 'importJson');

    return (
      <group dispose={() => {
        gui.destroy();
      }} />
    );
  }

  useEffect(async () => {
    let result = await testObjectApi.getAssetsList();
    var a = [];
    var b = [];
    var c = [];
    var d = [];
    for (let i = 0; i < result.data.length; i++) {
      let data = result.data[i];

      if (data.layers == undefined) {
        data.layers = [0];
        data.layerString = "0"
      } else {
        data.layerString = data.layers.join();
      }
      data.visible = true;

      if (data['3d_filename'].endsWith('fbx')) {
        a.push(data);
      } else if (data['3d_filename'].endsWith('gltf')) {
        b.push(data);
      } else if (data['3d_filename'].endsWith('glb')) {
        c.push(data);
      }
      d.push(data);
    }
    setFbxObjects(a);
    setGlfxObjects(b);
    setGlbObjects(c);
    setAssets(d);
  }, []);

  function TestCoordinates() {
    var numbers = [];
    for (var i = 10; i < 1000; i += 10) {
      numbers.push(i);
    }
    return (
      <group>
        {numbers.map((item, key) => {
          return (
            <group key={key}>
              <mesh scale={[1, 1, 1]} position={[item, 0, 0]}>
                <boxBufferGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'black'} />
              </mesh>
              <mesh scale={[1, 1, 1]} position={[0, item, 0]}>
                <boxBufferGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'black'} />
              </mesh>
              <mesh scale={[1, 1, 1]} position={[0, 0, item]}>
                <boxBufferGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'black'} />
              </mesh>
            </group>
          );
        })}
      </group>
    );
  }

  function Loader() {
    const { progress } = useProgress();
    return (
      <Html center style={{ color: '#fff' }}>
        {progress.toFixed(3)}%
      </Html>
    );
  }

  function FbxModel() {
    var url_string = window.location.href;
    var url = new URL(url_string);

    var name = url.searchParams.get('name');
    if (name == null) {
      name = 'test';
    }

    let optimize_draw_call = url.searchParams.get('draw_call');
    if (optimize_draw_call == null) {
      optimize_draw_call = true;
    }

    let use_texture = url.searchParams.get('use_texture');
    if (use_texture == null) {
      use_texture = true;
    }

    let color = url.searchParams.get('color');
    if (color == null) {
      color = '999999';
    }

    const [...fbxModels] = useLoader(
      FBXLoader,
      fbxObjects.map((item) => `${process.env.REACT_APP_API_URL}/binyan/${item['3d_filename']}`)
    );

    //if (light.current != null) {
    ///  light.current.shadow.needsUpdate = true;
    //}


    return (
      <group ref={fbxGroup}>
        {fbxModels.map((item, index) => {
          const object3d = item.clone(true);
          const object = fbxObjects[index];

          const castShadow = object.cast_shadow;
          const receiveShadow = object.receive_shadow;

          let geometries = [];
          let material = new THREE.MeshStandardMaterial();

          material.roughness = 0.95;
          material.metalness = 0.6;

          object3d.traverse(function (child) {
            child.castShadow = castShadow;
            child.receiveShadow = receiveShadow;
            if (child instanceof THREE.Mesh) {
              var geometry = child.geometry.clone();
              let matrix = new THREE.Matrix4();
              let position = new THREE.Vector3();
              let scale = new THREE.Vector3();
              let quaternion = new THREE.Quaternion();

              child.matrix.decompose(position, quaternion, scale);

              if (scale.y < 0.0) {
                scale.y = -scale.y;
              }
              if (scale.x < 0.0) {
                scale.x = -scale.x;
              }
              if (scale.z < 0.0) {
                scale.z = -scale.z;
              }

              matrix.compose(position, quaternion, scale);
              geometry.applyMatrix4(matrix);

              geometries.push(geometry);

              if (use_texture) {
                if (child.material instanceof THREE.Material) {
                  material = child.material.clone();
                } else if (child.material instanceof Array) {
                  if (child.material.length > 0) {
                    material = child.material[child.material.length - 1].clone();
                  }
                }
              }
            }
          });
          const newColor = new THREE.Color(`#${color}`);
          if (!use_texture) {
            material.color = newColor;
          }
          let scene;

          if (optimize_draw_call == true) {
            let mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(geometries, false);
            scene = new THREE.Mesh(mergedGeometry, material);
          } else {
            scene = object3d;
          }
          scene.castShadow = castShadow;
          scene.receiveShadow = receiveShadow;

          return (
            <primitive
              key={index}
              visible={object.visible}
              name={object.id}
              layers={object.layers}
              object={scene}
              dispose={null}
              position={[0.0, 0.0, 0.0]}
              scale={[1, 1, 1]}
              rotation={[0, 0, 0]}
            />
          );
        })}
      </group>
    );
  }

  function GltfModel() {
    const [...gltfModels] = useLoader(
      GLTFLoader,
      glfxObjects.map((item) => `https://binyan-api.testbox.com.au/binyan/${item['3d_filename']}`)
    );
    return (
      <group ref={refScene}>
        {gltfModels.map((item, index) => {
          const object3d = item;
          return (
            <primitive
              key={index}
              object={object3d.scene}
              dispose={null}
              position={[0.0, 0.0, 0.0]}
              scale={[1, 1, 1]}
              rotation={[0, 0, 0]}
            />
          );
        })}
      </group>
    );
  }

  function GlbModel() {
    const [...glbModels] = useLoader(
      GLTFLoader,
      glbObjects.map((item) => `https://binyan-api.testbox.com.au/binyan/${item['3d_filename']}`)
    );
    return (
      <group ref={refScene}>
        {glbModels.map((item, index) => {
          const object3d = item;
          return (
            <primitive
              key={index}
              object={object3d.scene}
              dispose={null}
              position={[0.0, 0.0, 0.0]}
              scale={[1, 1, 1]}
              rotation={[0, 0, 0]}
            />
          );
        })}
      </group>
    );
  }

  const CameraControls = () => {
    const {
      camera,
      gl: { domElement },
    } = useThree();

    camera.near = 0.01;
    camera.far = 10000;
    camera.position.copy(new THREE.Vector3(80, 80, 0));
    camera.lookAt(new THREE.Vector3(0,0,0));
    camera.updateProjectionMatrix();

    useFrame(() => {
      if (controls != null && controls.current != null) {
        controls.current.update();
      }
    });


    /*
    return (
      <OrbitControls
        ref={controls}
        args={[camera, domElement]}
      />
    );*/

    return (
      <orbitCustomControls
        ref={controls}
        args={[camera, domElement]}
        disabledUpdate={false}
        neverUpdate={false}
        autoRotate={false}
        enableDamping={true}
        maxDistance={6640}
        minDistance={2}
        minZoom={100}
        maxZoom={2000}
      />
    );

    return (
      <customControls
        ref={controls}
        args={[camera, domElement]}
        disabledUpdate={false}
        neverUpdate={false}
        autoRotate={false}
        enableDamping={true}
        maxDistance={6640}
        minDistance={2}
        minZoom={100}
        maxZoom={2000}
        //minPolarAngle={0}
        //maxPolarAngle={Math.PI / 2}
        rotateSpeed={0.2}
      />
    );
  };

  return (
    <>
      <Canvas gl={{
        outputEncoding: THREE.sRGBEncoding ,
        shadowMap: {
          autoUpdate : false,
          type : THREE.PCFSoftShadowMap,
        },
        logarithmicDepthBuffer : true,
        outputEncoding : THREE.sRGBEncoding,
      }}
              shadowMap pixelRatio={window.devicePixelRatio}
              camera={{
                fov: 10.4,
              }}>
        <CameraControls />
        {<RenderGUI /> }
        {true && <RenderLights /> }
        <React.Suspense fallback={<Loader />}>
          <group>
            {true && <FbxModel />}
            {true && <GltfModel />}
            {true && <GlbModel />}
          </group>
          {true && <SkyModel />}
        </React.Suspense>
        <axesHelper args={[1000]} />
        <TestCoordinates />
      </Canvas>
      <FPSStats />
    </>
  );
});
TestScene.displayName = 'TestScene';

export default TestScene;
