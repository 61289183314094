import React, { useState, useEffect } from "react";
import { FormControl, Modal, Form } from "react-bootstrap";
import * as yup from 'yup';
import { toast } from "react-toastify";
import closeIcon from "../../../assets/images/close-white.svg";
import customersAPI from '../../../apis/api/customer';
import { useSelector } from "react-redux";
import ChevronDown from '../../../assets/images/chevron-down.svg'

const validationSchema = yup.object().shape({
	firstname: yup.string().trim().required("First name is a required field"),
	surname: yup.string().trim().required("Last name is a required field"),
	email: yup.string().trim().email("Invalid email format").required("Email is a required field"),
});

const EditCustomerModal = ({ customer, show, setShow, onSaveSuccess }) => {
	const [firstname, setFirstName] = useState('');
	const [surname, setSurName] = useState('');
	const [email, setEmail] = useState('');
	const [agent,setAgent] = useState('');
	const [isShowDeleteCustomerModal, setIsShowDeleteCustomerModal] = useState(false);
  const [errors, setErrors] = useState({}) 

  const agentList = useSelector((state) => state.cms.agents);

	useEffect(() => {
		if (customer) {
			setFirstName(customer.firstname || '');
			setSurName(customer.surname || '');
			setEmail(customer.email || '');
			setAgent(customer?.agent?.id || agentList?.[0]?.id);
		}
	}, [customer])

	const handleUpdateCustomer = async () => {
		if (!customer) return;
		
		try {
			const data = {
				firstname,
				surname,
				email,
				agent,
			};
			setErrors({});
			const result = await validationSchema.validate(data, { abortEarly: false });
			const res = await customersAPI.updateCustomer(customer.id, result);
			if (res.data) {
				toast.success("Customer updated successfully!"); 
				handleClose && handleClose();
				onSaveSuccess && onSaveSuccess();
			}
		} catch (err) {
			if(err.inner) {
        const errs = {}
        err.inner.forEach(err => {
          errs[err.path] = err.message
        })
        setErrors(errs);
      }
		}
	};

	const handleDeleteCustomer = async () => {
		if (!customer) return;

		try {
			const res = await customersAPI.deleteCustomer(customer.id);
			if (res.data) {
				toast.success("Customer deleted successfully!");
				handleCloseDeleteCustomerModal();
				handleClose && handleClose();
				onSaveSuccess && onSaveSuccess();
			}
		} catch (err) {
			setError(err);
			toast.error(err.message);
		}
	}

	const handleOpenDeleteCustomerModal = () => {
		setIsShowDeleteCustomerModal(true);
	}

	const handleClose = () => {
		setShow(false);
	};

	const handleCloseDeleteCustomerModal = () => {
		setIsShowDeleteCustomerModal(false);
	}


	if (isShowDeleteCustomerModal) {
		return (
			<Modal
				className="wrap-delete-customer-modal"
				show={isShowDeleteCustomerModal}
				onHide={handleCloseDeleteCustomerModal}
				centered
			>
				<div className="wrap-content">
					<Modal.Header closeButton>
						<Modal.Title>Delete Customer?</Modal.Title>
					</Modal.Header>
					<Modal.Body className="wrap-modal-body">
						<div className="close-btn">
							<img src={closeIcon} alt="close-icon" onClick={handleCloseDeleteCustomerModal} />
						</div>
						<div className="confirm">
							Are you sure you want to delete {firstname} {surname}?
						</div>
					</Modal.Body>
				</div>
				<Modal.Footer>
					<div onClick={handleDeleteCustomer}>YES, DELETE</div>
				</Modal.Footer>
			</Modal>
		);
	}

	return (
		<Modal
			className="wrap-update-customer-modal"
			show={show}
			onHide={handleClose}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Edit Customer</Modal.Title>
			</Modal.Header>
			<Modal.Body className="wrap-modal-body">
				<div className="close-btn">
					<img src={closeIcon} alt="close-icon" onClick={handleClose} />
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>First Name*</Form.Label>
						<FormControl
							id="firstname"
							name="firstname"
							type="input"
							value={firstname}
							placeholder="First Name"
							onChange={(e) => setFirstName(e.target.value)}
						/>
						<span className="error">{errors?.firstname}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>Last Name*</Form.Label>
						<FormControl
							id="surname"
							name="surname"
							type="input"
							value={surname}
							placeholder="Last Name"
							onChange={(e) => setSurName(e.target.value)}
						/>
						<span className="error">{errors?.surname}</span>
					</Form.Group>
				</div>	
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>Email*</Form.Label>
						<FormControl
							id="email"
							name="email"
							type="input"
							value={email}
							placeholder="Email"
							onChange={(e) => setEmail(e.target.value)}
						/>
						<span className="error">{errors?.email}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>SALES AGENT*</Form.Label>
						<div className="selector">
							<select
								className={errors?.agent && "error"}
								onChange={(e) => setAgent(e.target.value)}
								id="sales-agent"
								value={agent}
								placeholder="Sales Agent"
							>
								{
									agentList?.length && agentList.map(a => (
										<option key={a.id} value={a.id}>{a.name}</option>
									))
								}
							</select>
							<img className="icon--down" src={ChevronDown} />
						</div>
						<span>{errors?.agent}</span>
					</Form.Group>	
				</div>
			</Modal.Body>
			<Modal.Footer>
				<span className="save" onClick={handleUpdateCustomer}>Save</span>
				<span className="delete" onClick={handleOpenDeleteCustomerModal}>DELETE CUSTOMER</span>
			</Modal.Footer>
		</Modal>
	);
};

export default EditCustomerModal;
