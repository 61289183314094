import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "./style.scss";
import customerAPi from "../../../apis/api/customer";
import ErrorModal from "../../guide-sesions/error-modal"
import { toast } from "react-toastify";
import profileImage from "../../../assets/images/profile.png";
import newProfileImage from "../../../assets/images/new-profile.png";
import Select from 'react-select';
import { reqSetIsShowListInvestorModal } from "../../../reduxs/guide-session/action";
import socket from "../../../helper/socket";
import {ACTION_NAME, PAGES, WEBSOCKET_CHANNEL} from "../../../constants/options";
import { LongRightArrowIcon } from "../../svgs/icons";

import "./style.scss";
import { reqSetPage } from "../../../reduxs/home/action";
import { SelectDropdown } from '../../select-dropdown';

const Profile = (props) => {
  const { startDiscover, handleCancel, agents, isPresentation } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [emailAddress, setEmailAddress] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [agent, setAgent] = useState()
  const [isShowNotFound, setShowNotFound] = useState(false);
  const [isCreate, showCreate] = useState(false);
  const [isErrFirstName, setErrFirstName] = useState(false);
  const [isErrLastName, setErrLastName] = useState(false);
  const [isErrEmail, setErrEmail] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [listUserSelect, setListUserSelect] = useState([]);
  const isShowListInvestorModal = useSelector(
    (state) => state.guideSession.isShowListInvestorModal
  );
  const isShowListConsultantModal = useSelector(
    (state) => state.guideSession.isShowListConsultantModal
  );
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    dispatch(reqSetIsShowListInvestorModal(true));

    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, []);

  useEffect(() => {
    if (isShowListInvestorModal) {
      getListInvestor();
    }
    if (isShowListConsultantModal) {
      getListConsultant();
    }
  }, [isShowListInvestorModal, isShowListConsultantModal]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CREATE_PROFILE_SUCCESS) {
      onStartUserSession(content?.data?.user);
    }
  };

  const getListInvestor = async () => {
    const result = await customerAPi.getInvestorList();
    setListUserSelect(result?.data || []);
  };

  const getListConsultant = async () => {
    const result = await customerAPi.getInvestorList();
    setListUserSelect(result?.data || []);
  };

  const userSelectType = useSelector(
    (state) => state.guideSession.userSelectType
  );

  // const getListInvestor = async () => {
  //     const data = {
  //         firstname: firstName,
  //         surname: lastName,
  //         email: emailAddress,
  //     };

  //     const result = await customerAPi.findCustomer(data);
  //     if (result?.data) {
  //         onStartUserSession(result.data);
  //     } else {
  //         setShowNotFound(true);
  //     }
  // };

  const onStartUserSession = (user) => {
    user?.id && history.push({
      search: `?customer=${user.id}`,
    });
    analytics.track("Start Session", {
      customerType: userSelectType,
      customerFirstName: user?.firstname,
      customerLastName: user?.surname,
      customerEmail: user?.email,
    });

    return startDiscover(user?.id);
  };

  const handleSubmitUser = () => {
    // if (isShowListInvestorModal) {
    //     await getListInvestor();
    // }
    // if (isShowListConsultantModal) {
    //     await getListConsultant();
    // }
    handleValidate();
  };

  const handleValidate = async (isCreate) => {
    if (isCreate) {
      onCreateNewProfile();
    } else {
      const selectedUser = listUserSelect.find(user => user.id === customerSelected);
      await onStartUserSession(selectedUser);
    }
  }

  const onCreateNewProfile = async () => {
    try {
      let userGroup = "606d6f356af09f50f367ee23";

      const data = {
        userGroup: userGroup,
        email: emailAddress,
        firstname: firstName,
        surname: lastName,
        // name: name,
        // company: company,
        address: "",
        mobile: "",
        countryCode: "",
        agent
      };

      const res = await customerAPi.createCustomer(data);
      if (res && !res.data) {
        return toast.error("Customer's email exists!");
      }

      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CREATE_PROFILE_SUCCESS, {
          user: res.data,
        });
      }

      analytics.track("Agent Created Client", {
        agentId: res.data?.id,
        clientId: res.data?.id,
        clientFistName: res.data?.firstname,
        clientEmail: res.data?.email,
        clientSurname: res.data?.surname,
      });

      onStartUserSession(res?.data);

      toast.success(`Created successfully!`);
    } catch (err) {
      toast.error(`Customer's email already exists`);
    }
  }

  const handleCreateCustomer = async () => {
    handleValidate(true);
  };

  const handleExploreNow = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_CUBE_MENU);
    }
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
  }

  const handleResetData = () => {
    setEmailAddress("");
    setFirstName('');
    setLastName('');
    setAgent("")
    setErrEmail(false);
    setIsEmailInvalid(false)
    setErrFirstName(false);
    setErrLastName(false);
  };

  const createProfile = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!firstName) setErrFirstName(true);
    if (!lastName) setErrLastName(true);
    if (!emailAddress) setErrEmail(true);

    if (!emailPattern.test(emailAddress)) {
      setIsEmailInvalid(true)
      return
    }

    if (firstName && lastName && emailAddress) handleCreateCustomer();
    return;
  }

  const [customerSelected, setCustomerSelected] = useState(null);

  const ErrMessage = () => <p className="error-message">This field is required</p>
  const InvalidEmailMessage = () => <p className="error-message">Email not valid</p>

  const renderCreateProfile = () => {
    return (
      <div id="select-profile-wrapper">
        <div className="info-container">
          <div className="title-wrapper">
            <h2 className="title">NEW PROFILE</h2>
          </div>
          <div className="select-customer">
            <p>First time at Bay Harbor Towers? Enter your details below to create a new customer profile.</p>
            <div className="input-group">
              <div className="input-wrapper">
                <input className={isErrFirstName ? "error input-element" : "input-element"} type="text" placeholder="FIRST NAME" name="firstName" value={firstName} onChange={e => { setFirstName(e.target.value); setErrFirstName(false) }} />
                {isErrFirstName && <ErrMessage />}
                {firstName && <span className="place-holder">FIRST NAME</span>}
              </div>
              <div className="input-wrapper">
                <input className={isErrLastName ? "error input-element" : "input-element"} type="text" placeholder="LAST NAME" name="lastName" value={lastName} onChange={e => { setLastName(e.target.value); setErrLastName(false) }} />
                {isErrLastName && <ErrMessage />}
                {lastName && <span className="place-holder">LAST NAME</span>}
              </div>
              <div className="input-wrapper email-input">
              <input className={(isErrEmail || isEmailInvalid) ? "error input-element" : "input-element"} type="text" placeholder="EMAIL" name="email" value={emailAddress} onChange={e => {setEmailAddress(e.target.value); setErrEmail(false); setIsEmailInvalid(false)}}/>
                {isErrEmail ? <ErrMessage/> : (
                  <>
                    {isEmailInvalid && (
                      <InvalidEmailMessage />
                    )}
                  </>
                )}
                {emailAddress && <span className="place-holder">EMAIL</span>}
              </div>
              <div className="input-wrapper sales-agent">
                <Select
                  className={"wrap-select-sales"}
                  classNamePrefix="select"
                  defaultValue={{
                    value: '',
                    label: 'SALES AGENT (OPTIONAL)',
                  }}
                  isDisabled={false}
                  isSearchable={false}
                  options={agents?.map(({ id, firstname }) => ({ value: id, label: firstname }))}
                  name="type  "
                  onChange={(item) => { setAgent(item.value) }}
                  components={{
                    DropdownIndicator: SelectDropdown,
                  }}
                />
              </div>
            </div>
            <button onClick={createProfile} className="select-profile">SUBMIT</button>
            <div className="button-group">
              <button onClick={() => { showCreate(false); handleResetData(); }}>CANCEL</button>
              <button onClick={() => handleExploreNow()} className="explore-btn mr-0">
                EXPLORE NOW
                <LongRightArrowIcon fill="#242422" />
              </button>
            </div>
          </div>
        </div>
        <div className='divider'/>
        <img height="100%" src={newProfileImage} alt="new-profile-image" />
      </div>
    )
  }

  const renderInputProfile = () => {
    return (
    <div id="select-profile-wrapper">
      <div className="info-container">
        <div className="title-wrapper">
          <h2 className="title">SELECT<br />PROFILE</h2>
        </div>
        <div className="select-customer">
          <p>Select the customer profile for today's session</p>
          <input type="text" placeholder="SEARCH ..." value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
          <ul className="name-wrapper">
            {
              listUserSelect
                ?.filter((user) => {
                  if (searchInput == "") {
                    return user;
                  } else if (
                    user?.name
                      .toLowerCase()
                      .includes(searchInput.toLocaleLowerCase())
                  ) {
                    return user;
                  }
                })
                .map(({ id, name }) => (
                  <li className={customerSelected === id ? 'active' : ''} onClick={() => setCustomerSelected(id)} key={id}>{name}</li>
                ))
            }
          </ul>
          <button className="select-profile" onClick={handleSubmitUser} disabled={!customerSelected}>SELECT PROFILE</button>
          <div className="button-group">
            <div>
              <button onClick={() => handleCancel()}>CANCEL</button>
              <button onClick={() => showCreate(true)}>CREATE A NEW PROFILE</button>
            </div>
            <button onClick={() => handleExploreNow()} className="explore-btn mr-0">
              EXPLORE NOW
              <LongRightArrowIcon fill="#242422" />
            </button>
          </div>
        </div>
      </div>
      <div className='divider'/>
      <img height="100%" src={profileImage} alt="create-profile-image"/>
    </div>)
  }

  return (
    <div id="customer-profile"
      style={{ backgroundImage: `url("uploads/images/bg-holding.png")` }}>
      {isCreate ? renderCreateProfile() : renderInputProfile()}
      {isShowNotFound && (
        <ErrorModal
          isShow={isShowNotFound}
          onClose={() => setShowNotFound(false)}
        />
      )}

    </div>
  );
};

export default Profile;
