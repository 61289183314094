import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import TopNavCMS from "./cms-top-nav";
import CMSCustomers from "./customers";
import CMSContent from './contents';
import CMSFrequentlyAQS from "./faq";
import "./index.scss";
import CMSResidence from "./residence";
import CMSAmenities from "./amenities";
import CMSAnalytics from "./analytics";
import CMSProfile from "./profile";

const CMS = (props) => {
  const { roles, authMiddleware } = props;
  const history = useHistory();

  useEffect(() => {
    authMiddleware(roles, history);
  }, []);

  return (
    <div className="wrap-cms-page">
      <TopNavCMS />
      <Switch>
        <Route
          exact
          path="/cms"
          render={() => {
            return <Redirect to="/cms/residence" />;
          }}
        />
        <Route path="/cms/content">
          <CMSContent />
        </Route>
        <Route path="/cms/faqs">
          <CMSFrequentlyAQS />
        </Route>
        <Route path="/cms/residence">
          <CMSResidence />
        </Route>
        <Route path="/cms/customers">
          <CMSCustomers />
        </Route>
        <Route path="/cms/amenities">
          <CMSAmenities />
        </Route>
        <Route path="/cms/analytics">
          <CMSAnalytics />
        </Route>
        <Route path="/cms/profile">
          <CMSProfile />
        </Route>
      </Switch>
    </div>
  );
};
export default CMS;
