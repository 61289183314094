import "./index.scss";
import socket from "../../helper/socket";
import React, { useEffect, useRef, useState } from "react";
import imageNeighborhood1 from "../../assets/images/image-amenities-1-logo.png";
import imageNeighborhood2 from "../../assets/images/neighborhood-2-1.png";
import imageNeighborhood3 from "../../assets/images/neighborhood-3.png";
import imageNeighborhood4 from "../../assets/images/neighborhood-4.png";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";

const neighborhoodInfoList = [
  {
    title: 'WALKABILITY',
    header: 'A SHORT \nSTROLL',
    images: [imageNeighborhood2],
    infoList: [
      'Cafe’s',
      'Restaurants',
      'Parks',
      'Shopping',
      'Schools',
      'Beaches',
    ],
  },
  {
    title: 'SHOP & EAT',
    header: 'exclusive \nshopping \nand dining',
    images: [imageNeighborhood3],
    infoList: [
      'Bal Harbour Shops',
      'Aba Mediterranean',
      'Carpaccio',
      'Makoto',
      'Le Zoo',
      'Pura Vida',
    ]
  },
  {
    title: 'WATER ACTIVITIES',
    header: 'waterways \nand \nplaygrounds',
    images: [imageNeighborhood4],
    infoList: [
      'Boating',
      'Ocean boardwalk',
      'Marina',
      'Sandy beaches',
      'Beach club access',
    ]
  }
];

const Neighborhood = ({ isPresentation }) => {

  const pageRef = useRef(null);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollPercentage * (pageRef.current.scrollHeight - pageRef.current.clientHeight);
      }
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const onScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollPercentage: scrollTop / (scrollHeight - clientHeight),
      });
    }
  }

  return (
    <div ref={pageRef} onScroll={onScroll} className={`static-page-wf neighborhood-page ${isPresentation && "presentation"}`}>
      <div className="main-content">
        <div className="content-wrapper bg-cyan">
          <div className='content-wrapper content-1-wrapper'>
            <div className="content-1">
              <div className="residence-info">
                <div className="residence-para">
                  <div className="title">the epitome <br />of a well <br />lived life</div>
                  <div className="para">
                    Immerse yourself in pristine beaches, upscale shopping, diverse culinary delights, and maritime adventures all within a few steps of your front door.
                  </div>
                </div>
                <div className="ct-image-1">
                  <img
                    alt="imageNeighborhood1"
                    src={imageNeighborhood1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-wrapper bg-white">
          {neighborhoodInfoList.map((info, index) =>
          (
            <div className={`content-body index-${index} ${index % 2 === 0 ? '' : 'reverse'}`} key={info.title}>
              <div className='content-images'>
                {info.images.map((image, imageIndex) => 
                  <div key={imageIndex} className={`content-image index-${index}`} style={{backgroundImage: `url(${image})`}} />
                )}
              </div>
              <div className='divider' />
              <div className={`content-info index-${index}`}>
                <span className='content-title'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
                    <circle cx="4" cy="4.5" r="4" fill="#242422" />
                  </svg>
                  <span>{info.title}</span>
                </span>
                <span className='content-header'>
                  {info.header}
                </span>
                <div className='content-info-list'>
                  {info.infoList.map((text) => (
                    <span className='info-text' key={text}>{text}</span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {
        !isPresentation && <div className="bottom-top" />
      }
    </div>
  );
};

export default Neighborhood;
