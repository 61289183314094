export const getMediaUrl = (path) => {
  return `./uploads${path}`;
};

export const convertCamelcaseToString = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const getImagePath = (path) => {
  if (!path) return "/images/image.png";

  return `/uploads${path}`;
};

export const getUploadedMediaUrl = (path) => {
  return `./uploads${path}`;
};

export const getUploadedServerMediaUrl = (path) => {
  if (!path) return '';
  
  return `${process.env.REACT_APP_API_URL}${path}`;
}
