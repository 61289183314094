/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { reqSetIsTransparent } from "../../reduxs/home/action";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import CMSExtraLeft from "../../assets/images/cms-extra-left.svg";
import CMSExtraRight from "../../assets/images/cms-extra-right.svg";
import { getUploadedMediaUrl, getUploadedServerMediaUrl } from "../../helper/media";
import "./index.scss";
import socket from "../../helper/socket";
import { ACTION_NAME, GALLERY_TYPE, WEBSOCKET_CHANNEL } from "../../constants/options";
import CloseIcon from "../../assets/images/close.svg";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import iconArrowBg from "../../assets/images/bg-arrow.svg";
import { reqGetGalleryList } from "../../reduxs/gallery/action";
import galleryApi from '../../apis/api/gallery';
const FloorPlanGallery = (props) => {
  const { floorPlanGalleryRef, isPresentation } = props;
  const [media, setMedia] = useState([]);

  const transformComponentRef = useRef(null);

  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const dispatch = useDispatch();
  const galleries = useSelector((state) => state.gallery.galleries);

  useEffect(async () => {
    if (!selectedUnit || !galleries) {
      return;
    }

    const galleryMedia = galleries.filter((gallery) => gallery.type === GALLERY_TYPE.FLOOR_PLANS && gallery.unit?.id === selectedUnit.id).flatMap((gallery) => gallery.media);
    const activeMedia = galleryMedia.filter((media) => media.isActive);
    setMedia(activeMedia);
  }, [selectedUnit]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.ZOOM_OUT_FLOORPLAN) {
          if (!transformComponentRef.current) return;
          if (content.data.state) {
            const ratioHeight = window.screen.height / content.data.height;
            const ratioWidth = (window.screen.width * 0.7) / content.data.width;
            const marginWidth = ratioHeight > 1 ? 0.98 : 1.03;
            const marginHeight = ratioHeight > 1 ? 1.3 : 0.98;
            return transformComponentRef.current.setTransform(
              content.data.state.positionX * ratioWidth * marginWidth,
              content.data.state.positionY * ratioHeight * marginHeight,
              content.data.state.scale === 1
                ? 1
                : content.data.state.scale * (1 / marginWidth)
            );
          }
        }

        if (content.action === ACTION_NAME.ENABLE_TRANSPARENT_MODE) {
          return enableTransparent();
        }

        if (content.action === ACTION_NAME.DISABLE_TRANSPARENT_MODE) {
          return showFilter();
        }
        if (content.action === ACTION_NAME.NEXT_IMAGE) {
          onMoveImagePresentation(content.data.idxActive);
        }
        if (content.action === ACTION_NAME.PREV_IMAGE) {
          onMoveImagePresentation(content.data.idxActive);
        }
      });
    }
  }, [isPresentation]);

  const showFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.DISABLE_TRANSPARENT_MODE);
    }
    dispatch(reqSetIsTransparent(false));
  };

  const enableTransparent = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.ENABLE_TRANSPARENT_MODE);
    }
    dispatch(reqSetIsTransparent(true));
  };

  const hideFloorplan = () => {
    if (!isTransparent) {
      enableTransparent();
    } else {
      handleCloseBtn();
    }
  };

  const handleCloseBtn = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_FLOORPLAN);
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(reqSetIsTransparent(false));
  };

  const mediaImages = media.filter((item) => item.isActive);
  const refCurrentIdxActive = useRef(0);
  const [showGalleryData, setShowGalleryData] = useState({
    idxActive: 0,
  });

  const onMoveImagePresentation = (idxActive) => {
    setShowGalleryData({
      idxActive: idxActive,
    });
    transformComponentRef.current.resetTransform();
  };

  const onNextImage = (data) => {
    if (refCurrentIdxActive.current === data?.length - 1) {
      setShowGalleryData({
        idxActive: 0,
      });
      refCurrentIdxActive.current = 0;
    } else {
      setShowGalleryData((v) => ({
        idxActive: v?.idxActive + 1,
      }));
      refCurrentIdxActive.current += 1;
    }
    transformComponentRef.current.resetTransform();
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.NEXT_IMAGE, {
        idxActive: refCurrentIdxActive.current,
      });
    }
  };

  const onPrevImage = (data) => {
    if (refCurrentIdxActive.current === 0) {
      let idx = data?.length - 1;
      setShowGalleryData({
        idxActive: idx,
      });
      refCurrentIdxActive.current = idx;
    } else {
      setShowGalleryData((v) => ({
        idxActive: v?.idxActive - 1,
      }));
      refCurrentIdxActive.current -= 1;
    }

    transformComponentRef.current.resetTransform();
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PREV_IMAGE, {
        idxActive: refCurrentIdxActive.current,
      });
    }
  };


  return (
    <TransformWrapper
      ref={transformComponentRef}
      maxScale={2.5}
      minPositionX={window.screen.width * 1.55 * -1}
      minPositionY={window.screen.height * 1.7 * -1}
      maxPositionX={window.screen.width * 1.55}
      maxPositionY={window.screen.height * 1.7}
      wheel={{
        step: 0.1,
      }}
      pinch={{
        step: 0.1,
      }}
      onTransformed={(ref, state) => {
        if (!isPresentation) {
          socket.emitUIActionEvent(ACTION_NAME.ZOOM_OUT_FLOORPLAN, {
            state,
            height: window.screen.height,
            width: window.screen.width,
          });
        }
      }}
    >
      {({ zoomIn, zoomOut }) => (
        <>
          {isShowFloorplan && isTransparent && (
            <img src={CloseIcon} alt="CloseIcon" onClick={handleCloseBtn} />
          )}
          <div className="wrap-floor-plan-gallery">
            <TransformComponent
              contentStyle={{
                height: "100%",
                width: "100vw",
              }}
              wrapperStyle={{
                height: "100%",
                width: "100vw",
                background: "black",
              }}
            >
              {mediaImages?.length ? (
                mediaImages.map((item, key) => {
                  if (key === showGalleryData.idxActive) {
                    return (
                      <img
                        key={key}
                        src={getUploadedServerMediaUrl(encodeURI(item?.path))}
                        alt="floorplan"
                        className="floorplan-item"
                      />
                    );
                  }
                })
              ) : (
                <div className="floorplan-item img-not-found">
                  Image Not Found
                </div>
              )}
            </TransformComponent>
          </div>
          <div className="wrap-zoom-btn">
            {isShowFloorplan && (
              <>
                {isTransparent && (
                  <div>
                    <img src={CMSExtraLeft} alt="cms-extra-left" />
                  </div>
                )}
                <div
                  onClick={hideFloorplan}
                  className={classNames({
                    "m-none": !isTransparent,
                  })}
                >
                  <span>
                    {isTransparent ? "CLOSE FLOORPLAN" : "CLOSE PANELS"}
                  </span>
                  {/* <img className="rotate-image" src="/icons/arrow.svg" alt="" /> */}
                </div>
                {!isTransparent && (
                  <div>
                    <img
                      src={CMSExtraRight}
                      alt="cms-extra-right"
                      className={classNames({
                        "ml-0": !isTransparent,
                      })}
                    />
                  </div>
                )}
              </>
            )}
            {isTransparent && (
              <>
                <div onClick={showFilter}>
                  <span>{"REOPEN PANELS"}</span>
                </div>
                <div>
                  <img src={CMSExtraRight} alt="cms-extra-right" />
                </div>
              </>
            )}
          </div>
          <>
              {mediaImages?.length !== 1 && (
                <div>
                  <div
                    className="fancy-btn-prev cursor-pointer"
                    onClick={() => onPrevImage(mediaImages)}
                  >
                    <img src={iconArrowBg} alt="next" className="next-arrow" />
                  </div>
                  <div
                    className="fancy-btn-next cursor-pointer"
                    onClick={() => onNextImage(mediaImages)}
                  >
                    <img src={iconArrowBg} alt="next" className="pre-arrow" />
                  </div>
                </div>
              )}
            </>
        </>
      )}
    </TransformWrapper>
  );
};

export default FloorPlanGallery;
