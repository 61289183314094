import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import closeIcon from "../../../assets/images/close-white.svg";
import preferenceApi from '../../../apis/api/preference';
import LoadingIcon from "../../../components/loading-icon";
import { formatMoney } from "../../../helper/unit";

const Row = ({ data }) => {

	return (
		<div className={`content-row`}>
			<div>Residence {data?.name}</div>
			<div className="availability">{data?.availabilityStatus}</div>
			<div>{formatMoney(data?.price) || 0}</div>
			<div>{data?.level}</div>
			<div>{data?.areaSqm}</div>
			<div>{data?.bedrooms}</div>
			<div>{data?.bathrooms}</div>
			<div>{data?.hasPool ? 'Yes' : 'No'}</div>
		</div>
	);
}

const CustomerFavoritesModal = ({ customer, show, setShow }) => {
	const [preference, setPreference] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		customer && getCustomerPreference();
	}, [customer]);

	const getCustomerPreference = async () => {
		try {
			setLoading(true);
			const res = await preferenceApi.getCustomerPreference({
				customer: customer?.id,
			});
			setLoading(false);
			setPreference(res.data);
		} catch (error) {
			setLoading(false);
		}
	}
	
	const handleClose = () => {
		setShow(false);
	};

	const renderListProperty = () => {
    return (preference?.units || []).map((item, index) => {
      return (
				<Row key={item?.id} data={item} />
      );
    });
  };

	const renderGallery = () => {
		if (!preference || !preference?.units) return null;
		const sampleGalleries = preference?.units[0]?.view?.media || [];
		return (
			<>
				<div className="gallery-title">CONTENT</div>
				<div className="gallery">
					{
						sampleGalleries.slice(0, 3).map((item, index) => {
							return (
								<div key={item.id} className="gallery-item">
									<img src={`/uploads/${item.path}`} />
									<span>Image Title</span>
								</div>
							);
						})
					}
				</div>
			</>
		);
	}

	const renderContent = () => {
		if (loading) return <LoadingIcon />;

		if (!preference || !preference?.units || !preference?.units?.length) return (
			<div className="content">No favorites found.</div>
		);

		return (
			<div className="content">
				<div className="content-header">
					<div>RESIDENCE</div>
					<div>AVAILABILITY</div>
					<div>PRICE</div>
					<div>floor</div>
					<div>SQ.FT.</div>
					<div>BEDS</div>
					<div>BATHS</div>
					<div>PRIVATE POOL</div>
				</div>
				<div className="content-list">{renderListProperty()}</div>
				{/* {renderGallery()} */}
			</div>
		);
	}

	return (
		<Modal
			className="wrap-customer-favorites-modal"
			show={show}
			onHide={handleClose}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>{customer?.name}’s Favorites</Modal.Title>
			</Modal.Header>
			<Modal.Body className="wrap-modal-body">
				<div className="close-btn">
					<img src={closeIcon} alt="close-icon" onClick={handleClose} />
				</div>
				{renderContent()}
			</Modal.Body>
		</Modal>
	);
};

export default CustomerFavoritesModal;
