import "./index.scss";
import socket from "../../helper/socket";
// import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import image1 from "../../assets/images/developer.svg";
import image2 from "../../assets/images/architect.svg";
import image3 from "../../assets/images/interior.svg";
import image4 from "../../assets/images/landscape.svg";
import image5 from "../../assets/images/salesmarketing.svg";
import image6 from "../../assets/images/branding.svg";
import icBayHarbor from "../../assets/images/logo-bay-harbor.png";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";

let infoDev = [
  'Founded by Ari Pearl, with over 20 years of experience',
  'PPG portfolio has 2,500+ residential units and hospitality projects throughout South Florida',
  'Recent success across condo, multi-family, hotel/resort, marina & golf courses',
  'Bringing highly amenitized properties to market are their specialty',
  'PPG has a proven track record of on-time delivery',
  'Deep knowledge about the Bay Harbor Islands customer and strong relationship with local government'
];

let infoArchitects = [
  'Kobi Karp has over 2 decades of hospitality, retail and high-rise residential development experience',
  'They have designed over $36B in mixed-use commercial, residential, and multi-family worldwide',
  'Kobi Karp & Ari Pearl have collaborated with great success on multiple projects'
];

let infoInteriorDesign = [
  'Interiors by Steven G. has been in business for over 40 years, and is a full-service design firm helmed by Steven Gurowitz',
  'They have designed notable luxury residential projects, restaurants, hotels, commercial spaces, condos, and private homes in South Florida'
];

let infoLandscaping = [
  'Founded by Carolina Monteiro in 2017',
  'Experience in boutique hotels, larger developments and private residences',
  'Creates timeless outdoor spaces that seamlessly integrate indoor-outdoor living'
];

let infoSalesMarketing = [
  'Fortune Development Sales is the premier, exclusive on-site sales and marketing representative for third-party development projects in South Florida.',
  'Led by visionary founder Edgardo Defortuna, Fortune has 18 offices around the world, with nearly 1,000 associates and a worldwide brokerage network.',
  'The company’s current development portfolio includes many of the most prominent residential properties in South Florida including Jade Signature, The Ritz-Carlton Residences, Sunny Isles, Auberge Beach Residences & Spa Fort Lauderdale, and Hyde Resort & Residences Hollywood.'
];

let infoBranding = [
  'Krinsky Design specializes in strategic branding and design solutions for exceptional real estate development.',
  'Providing extensive experience and creative prowess, their customized solutions ensure success.'
]

const listItems = [
  { infos: infoDev, title: 'Developer', headings: ['PPG', 'Development'], imageSrc: image1, id: 'developer-section' },
  { infos: infoArchitects, title: 'Architect',  headings: ['KOBI KARP', 'ARCHITECTS'], imageSrc: image2, id: 'architect-section' },
  { infos: infoInteriorDesign, title: 'Interior design', headings: ['INTERIORS BY', 'STEVEN G.'], imageSrc: image3, id: 'interior-design-section' },
  { infos: infoLandscaping, title: 'Landscaping', headings: ['CLAD'], imageSrc: image4, id: 'landscaping-section' },
  { infos: infoSalesMarketing, title: 'Sales & marketing', headings: ['FORTUNE', 'DEVELOPMENT', 'SALES'], imageSrc: image5, id: 'sales-marketting-section' },
  { infos: infoBranding, title: 'Branding', headings: ['KRINSKY', 'DESIGN'], imageSrc: image6, id: 'branding-section' },
]

const navItems = [
  {
    text: 'DEVELOPER',
    id: 'developer-section'
  }, 
  {
    text: 'ARCHITECT',
    id: 'architect-section'
  },
  {
    text: 'INTERIOR DESIGN',
    id: 'interior-design-section'
  },
  {
    text: 'LANDSCAPING',
    id: 'landscaping-section'
  },
  {
    text: 'SALES & MARKETING',
    id: 'sales-marketting-section'
  },
  {
    text: 'BRANDING',
    id: 'branding-section'
  }
]

const Item = ({ infos, headings, imageSrc, textLeft, id, title }) => {
  return (
    <div className="content-wrapper bg-white content-div" id={id}>
      <div className="sub-content">
        <div className={`content-container ${textLeft ? '' : 'reverse'}`}>
          <div className={textLeft ? "l-wrapper" : "r-wrapper"}>
            <div className="section-title-container">
              <span className="dot" />
              <p className="section-title">{title}</p>
            </div>
            <div className="c-title">
              {
                headings.map((heading, i) => (
                  <div key={i}>{heading}<br /></div>
                ))
              }
            </div>
            <ul className="c-info-list">
              {
                infos.map((info, i) => (
                  <li key={i} className="c-info-item">{info}</li>
                ))
              }
            </ul>
          </div>
          <div>
            <img src={imageSrc} alt="developer" />
          </div>
        </div>
      </div>
    </div>
  )
}

const Ppg = ({ isPresentation }) => {
  const pageRef = useRef(null);
  const [visibleDivId, setVisibleDivId] = useState('developer-section');
  const idRef = useRef('developer-section')

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollTop * pageRef.current.scrollHeight;
      }
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  const onNavClick = (id) => {
    const section = document.getElementById(`${id}`);
    section.scrollIntoView({       
      behavior: "smooth",
      block: "start", 
    });
  }
  
  useEffect(() => {
    function isInContainerViewport(container, element) {
      var containerRect = container.getBoundingClientRect();
      var elementRect = element.getBoundingClientRect();
      return (
          elementRect.top >= containerRect.top &&
          elementRect.top <= containerRect.top + container.clientHeight * 0.3
      );
    }

    function isBottomReached(container) {
      return container.scrollTop + container.clientHeight >= container.scrollHeight;
    } 

    function handleScroll() {
      var container = document.getElementById('ppg-page'); 
      var divs = container.querySelectorAll('.content-div'); 

      if (isBottomReached(container)) {
        idRef.current = 'branding-section'
        setVisibleDivId('branding-section')
        return 
      }

      divs.forEach(function(div) {
          if (isInContainerViewport(container, div)) {
            const id = div.id
            if (id && idRef.current !== id) {
              idRef.current = id
              setVisibleDivId(id)
            }
          }
      });
    }
    var container = document.getElementById('ppg-page');
    container.addEventListener('scroll', handleScroll);

    return () => {
      var container = document.getElementById('ppg-page');
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    }
  }, [])

  return (
    <div ref={pageRef} onScroll={onScroll} id='ppg-page' className={`static-page-wf ppg-page ${isPresentation && "presentation"}`}>
      <div className={`header ${isPresentation && "presentation"}`}>
        {navItems.map((item) => (
          <div key={item.id} onClick={() => onNavClick(item.id)} className={item.id === visibleDivId ? "header-item header-item--active" : "header-item"}>{item.text}</div>
        ))}
      </div>
      <div className="content-wrapper bg-white">
        <div className="content-1">
          <div className="landing-info">
            <div className="landing-header">
              DEVELOPED BY AN<br />
              EXPERIENCED TEAM<br />
              OF VISIONARIES
            </div>
          </div>
          <div className="ct-image-logo">
            <img
              style={{ width: "100%", height: "100%", objectFit: 'cover' }}
              alt="icBayHarbor"
              src={icBayHarbor}
            />
          </div>
        </div>
      </div>
      {
        listItems.map((item, i) => (
          <Item  
            key={i} 
            {...item} 
            textLeft={i % 2 === 1} 
            idx={i}
          />
        ))
      }
      {
        !isPresentation && <div className="bottom-top" />
      }
    </div>
  );
};

export default Ppg;
