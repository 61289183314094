export const unitGalleryPenthouseImages = [
  {
    isActive: true,
    type: "image",
    path: "/media/media_images/image_gallery/gallery-interiors-01-ph.jpeg",
    order: 15,
    id: "653075625d6a9ed09e667e21",
  },
  {
    isActive: true,
    type: "image",
    path: "/media/media_images/image_gallery/gallery-interiors-03-ph.jpeg",
    order: 14,
    createdAt: "2024-01-09T01:35:31.093Z",
    updatedAt: "2024-01-09T01:36:13.207Z",
    id: "653075625d6a9ed09e667e22",
  },
  {
    isActive: true,
    type: "image",
    path: "/media/media_images/image_gallery/gallery-interiors-09-ph.jpeg",
    order: 14,
    createdAt: "2024-01-09T01:35:31.093Z",
    updatedAt: "2024-01-09T01:36:13.207Z",
    id: "653075625d6a9ed09e667e22",
  },
];


export const unitGalleryImages = [
  {
    isActive: true,
    type: "image",
    path: "/media/media_images/image_gallery/BINYAN_PPG2747_BayHarborIsland2_S310_EXT_Hero_FINAL_2K.jpg",
    order: 15,
    id: "653075625d6a9ed09e667e21",
  },
  {
    isActive: true,
    type: "image",
    path: "/media/media_images/image_gallery/BINYAN_PPG2747_BayHarborIsland2_S320_EXT_HeroWater_Final_2K.jpg",
    order: 14,
    createdAt: "2024-01-09T01:35:31.093Z",
    updatedAt: "2024-01-09T01:36:13.207Z",
    id: "653075625d6a9ed09e667e22",
  },
  {
    isActive: true,
    type: "image",
    path: "/media/media_images/image_gallery/BINYAN_PPG2747_BayHarborIsland2_S330_EXT_Aerial_FINAL_2K.jpg",
    order: 14,
    createdAt: "2024-01-09T01:35:31.093Z",
    updatedAt: "2024-01-09T01:36:13.207Z",
    id: "653075625d6a9ed09e667e22",
  },
  {
    isActive: true,
    type: "image",
    path: "/media/media_images/image_gallery/BINYAN_PPG2747_BayHarborIsland2_S340_EXT_RooftopPool_Final_2K.jpg",
    order: 14,
    createdAt: "2024-01-09T01:35:31.093Z",
    updatedAt: "2024-01-09T01:36:13.207Z",
    id: "653075625d6a9ed09e667e22",
  },
];
