import React, { useEffect, useMemo, useRef } from "react";
import SideNav from "../sidenav";
import { useDispatch, useSelector } from "react-redux";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import { classNames } from "../../helper/utils";
import { groupBy } from "lodash";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";

const UnitList = (props) => {
  const dispatch = useDispatch();
  const { handleUnitClick, isPresentation } = props;
  const refs = useRef({});
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const filteredUnits = useSelector((state) => state.unitExplore.filteredUnits);
  const isShowFilter = useSelector((state) => state.unitExplore.isShowFilter);
  const pageRef = useRef(null);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.EXPLORE_SHOW_FILTER) {
      onShowFilter();
    }

    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollTop * pageRef.current.scrollHeight;
      }
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, []);

  const onShowFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.EXPLORE_SHOW_FILTER)
    }
    dispatch(unitExploreAct.reqSetIsShowFilter(true));
  }

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }


  const renderUnitGroup = useMemo(() => {
    const unitGroup = groupBy(filteredUnits || [], "level");
    return (
      <>
        {unitGroup &&
          (Object.keys(unitGroup) || []).map((valueKey, key) => {
            const items = unitGroup[valueKey];
            return (
              <li
                className={"list-group-item list-group-item-unit-list"}
                key={key}
              >
                <div>
                  <p className={"level-title"}>{`Floor ${valueKey}`}</p>
                  <ul>
                    {(items || []).map((item, key2) => {
                      return (
                        <li
                          className={`list-group-item-unit-content ${selectedUnit?.id && selectedUnit?.id != item.id
                            ? "inactive"
                            : ""
                            }`}
                          key={"item-" + key2}
                          onClick={() => handleUnitClick(item)}
                          ref={(ref) => (refs.current[item.id] = ref)}
                        >
                          {`- `}
                          {item.name.includes("Penthouse") || item.name.includes("Bayhouse")
                            ? item.name
                            : `Residence ${item.name}`}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
            );
          })}
      </>
    );
  }, [filteredUnits, selectedUnit]);

  if (isPresentation) return null;  // BN-1775

  return (
    <SideNav heading={"RESIDENCES"} position="left" id="lots" isDark={false}>
      <ul ref={pageRef} className="list-group scroll-unit disable-scroll-bar" onScroll={onScroll}>{renderUnitGroup}</ul>
      <div className="btn-bottom">
        <span
          onClick={() => {
            !isShowFilter && onShowFilter()
          }}
          className={classNames(
            ("text-center mt-1", isShowFilter ? "show-filter" : "")
          )}
        >
          SHOW FILTERS
        </span>
      </div>
    </SideNav>
  );
};

export default UnitList;
