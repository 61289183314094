import "./index.scss";
import socket from "../../helper/socket";
import amenityApi from '../../apis/api/amenities';
import ServicesModal from "./component/services-modal";
import React, { useEffect, useRef, useState } from "react";
import imageAmenities1 from "../../assets/images/image-amenities-1-logo.png";
import imageAmenities2 from "../../assets/images/amenities-2-1.png";
import imageAmenities3 from "../../assets/images/amenities-3.png";
import imageAmenities3_1 from "../../assets/images/amenities-3-1.png";
import imageAmenities4 from "../../assets/images/amenities-4.png";
import imageAmenities4_1 from "../../assets/images/amenities-4-1.png";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";

const amenitiesInfoList = [
  {
    title: "amenities",
    header: "an Endless \nsuite of luxury amenities",
    images: [imageAmenities2],
    infoList: [
      "Private marina with 13 boat slips",
      "Relaxing hammock garden",
      "Outdoor garden terrace",
      "State-of-the-art fitness center with bespoke Paragon® equipment",
      "Yoga studio",
    ],
  },
  {
    title: "health & wellness",
    header: "revitalize \nyour mind, \nbody and soul",
    images: [imageAmenities3_1, imageAmenities3],
    infoList: [
      "50' heated saltwater indoor lap pool and hot/cold plunge",
      "Wellness spa with sauna, steam, massage treatment rooms",
    ],
  },
  {
    title: "rooftop resort",
    header: "a private \nrooftop \nretreat",
    images: [imageAmenities4, imageAmenities4_1],
    infoList: [
      "Infinity-edge pool, poolside cabanas, and chaise loungers",
      "Fully appointed dual summer kitchens and barbecue grills, accompanied by al fresco dining areas",
      "Unwind in the serene, oversized spa hot tub while soaking in breathtaking sunsets",
    ],
  },
];

const AmenitiesV2 = ({isPresentation}) => {
  // const { t } = useTranslation();
  const [isShowServicesModal, setShowServicesModal] = useState(false);

  const pageRef = useRef(null);

  useEffect(async () => {
    getAmenitiesList();
  }, []);

  const getAmenitiesList = async () => {
    try {
      const res = await amenityApi.getAmenitiesList();
      setAmenities(res.data);
    } catch(err) {
    }
  }
  
  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.PAGE_SCROLL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop =
          content.data.scrollTop * pageRef.current.scrollHeight;
      }
    }
  };
  useEffect(() => {
    if(isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
  }, [isPresentation])

  const onScroll = (event) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL, {
        scrollTop: event.currentTarget.scrollTop / event.currentTarget.scrollHeight,
      });
    }
  }

  return (
    <div ref={pageRef} onScroll={onScroll} className={`static-page-wf amenities-page ${isPresentation && "presentation"}`}>
      <div className="main-content">
        <div className="content-wrapper bg-cyan">
          <div className='content-wrapper content-1-wrapper'>
            <div className="content-1">
              <div className="residence-info">
                <div className="residence-para">
                  <div className="title">A PREMIER<br/>COLLECTION OF<br/>AMENITIES</div>
                  <div className="para">
                    Bay Harbor Towers offers a wealth of luxurious amenities for your comfort and convenience, including a 24/7 concierge and valet service.
                  </div>
                </div>
                <div className="ct-image-1">
                  <img
                    alt="imageAmenities1"
                    src={imageAmenities1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-wrapper bg-white">
          {amenitiesInfoList.map((info, index) => 
          (
            <div className={`content-body ${index % 2 === 0 ? '' : 'reverse'}`} key={info.title}>
              <div className='content-images'>
                {info.images.map((image, imageIndex) => 
                  <div key={imageIndex} className={`content-image index-${index}`} style={{backgroundImage: `url(${image})`}} />
                )}
              </div>
              <div className='divider' />
              <div className={`content-info index-${index}`}>
                <span className='content-title'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
                    <circle cx="4" cy="4.5" r="4" fill="#242422"/>
                  </svg>
                  <span>{info.title}</span>
                </span>
                <span className='content-header'>
                  {info.header}
                </span>
                <div className='content-info-list'>
                  {info.infoList.map((text) => (
                    <span className='info-text' key={text}>{text}</span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {
        !isPresentation && <div className="bottom-top" />
      }
      <ServicesModal
        isShowServicesModal={isShowServicesModal}
        onHide={() => setShowServicesModal(false)}
      />
    </div>
  );
};

export default AmenitiesV2;
