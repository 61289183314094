import { formatMoney } from '../../helper/unit';
import React, { forwardRef, useEffect, useState, useCallback } from 'react';

const FilterGroupRange = (
  { onChange, label, filterValue, icon = '$$$$', max, customeClass = '' },
  ref
) => {
  const [value, setValue] = useState(filterValue);

  useEffect(() => {
    setValue(filterValue);
  }, [filterValue]);

  const onChangeValue = (e) => {
    let data = {
      ...filterValue,
    };
    const maxValue = filterValue.max || max;
    const minvalue = filterValue.min || 0;
    let name = e.target.name;
    let value = e.target.value;
    try {
      value = parseInt(value);
    } catch (error) {
      value = 0;
    }
    if (name === 'min') {
      if (!value || value < 0) {
        value = 0;
      }
      if (value >= maxValue) {
        value = maxValue;
      }
      data = {
        max: maxValue,
        min: value,
      };
    }
    if (name === 'max') {
      if (!value) {
        value = 0;
      }
      if (value > max) {
        value = max;
      }
      if (value <= minvalue) {
        value = minvalue;
      }
      data = {
        max: value,
        min: minvalue,
      };
    }
    onChange && onChange(data);
  };
  return (
    <div className="filter-group">
      <span className="heading">{label.title}</span>
      <div className={`d-flex justify-content-between flex-col gap-2 ${customeClass}`}>
        <div className="input-with-label">
          <small className="input-label">{label.min}</small>
          <input
            name="min"
            value={
              value?.min != null
                ? icon === '$$$$'
                  ? formatMoney(value.min)
                  : value.min
                : 0
            }
            onChange={onChangeValue}
            className="form-control rounded-0"
            type="number"
            placeholder={icon}
          />
        </div>
        <div className="input-with-label">
          <small className="input-label">{label.max}</small>
          <input
            name="max"
            value={
              value?.max != null
                ? icon === '$$$$'
                  ? formatMoney(value.max)
                  : value.max
                : icon === '$$$$'
                  ? formatMoney(max)
                  : max
            }
            onChange={onChangeValue}
            className="form-control rounded-0"
            type="number"
            placeholder={icon}
          />
        </div>
      </div>
    </div>
  );
};
export default forwardRef(FilterGroupRange);
