import React from 'react';
import img1 from "../../assets/images/image-post-session-1.png";
import img2 from "../../assets/images/image-post-session-2.png";
import img3 from "../../assets/images/image-post-session-3.png";
import img4 from "../../assets/images/image-post-session-4.png";
import imageFiller from "../../assets/images/image-post-session-filler.png";

const FavoritesOfCustomer = ({ items }) => {

    const renderUnitDetail = () => {
        const units = items || [];

        return units.map((item, i) => (
            <div key={`cp${i}`}>
                <div className="content-preference">
                    <div className="residence-left">
                        <img alt="imgFloorplan" src={`/uploads${item.floorPlan.thumbnail}`} />
                    </div>
                    <div className="residence-right">
                        <div className="residence-info">
                            <div className="r-title">
                                <hr />
                                <span>RESIDENCE<br />{item.name}</span>
                            </div>
                            <div className="r-content">
                                <hr />
                                <div className="r-feats">
                                    {(item.bedrooms || item.bathrooms) && (
                                        <div className="r-feats-item">
                                            {(item.bedrooms && item.bedrooms > 0) ? `${item.bedrooms} Bedroom + Den` : ''}
                                            {(item.bathrooms && item.bathrooms > 0) ? ` / ${item.bathrooms} Bathroom` : ''}
                                        </div>
                                    )}
                                    {(item.price) && (
                                        <div className="r-feats-item">Price — ${item.price}</div>
                                    )}
                                    <div className="r-feats-item">Lot Size — 3,084 SF / 285 SM</div>
                                    <div className="r-feats-item">Interior — 2,815 SF / 261 SM</div>
                                    <div className="r-feats-item">Exterior — 269 SF / 24 SM</div>
                                    {(item.hadPool) && (
                                        <div className="r-feats-item">Private Pool</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="residence-img-container">
                            {([img1, img2, img3, img4]?.map((img, index) => (
                                <div className="r-img" key={index}>
                                    <img alt={`img${index}`} src={img} />
                                </div>
                            )))}
                        </div>
                    </div>
                </div>
                {(i !== (units.length - 1)) && (
                    <div className="filler-img">
                        <img alt={`filler-${i}`} src={imageFiller} />
                    </div>
                )}
            </div>
        ))
    }

    return renderUnitDetail()
}

export default FavoritesOfCustomer;
