import * as yup from 'yup';
import Select from 'react-select';
import { toast } from "react-toastify";
import supportApi from '../../../apis/api/support';
import React, { useEffect, useState } from "react";
import { FormControl, Modal, Form } from "react-bootstrap";
import closeIcon from "../../../assets/images/close-white.svg";
import { SUPPORT_QUESTION_TYPES } from "../../../constants/master-data";

const validationSchema = yup.object().shape({
	questionType: yup.string().trim().required("Question type is a required field"),
	subject: yup.string().trim().required("Subject is a required field"),
	message: yup.string().trim().required("Message cannot be empty"),
});

const RequestSupportModal = ({ show, setShow, user, onSubmitSuccess = () => { } }) => {
	const [questionType, setQuestionType] = useState();
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');
	const [errors, setErrors] = useState({});

	useEffect(() => {
		setQuestionType('');
	}, []);

	const handleSubmit = async () => {
		try {
			const data = {
				questionType,
				subject,
				message,
			};
			setErrors({});
			const result = await validationSchema.validate(data, { abortEarly: false });

			const payload = {
				request: {
					requester: {
						name: user.name,
					},
					subject: result.subject,
					comment: {
						body: `${result.questionType}\n${result.message}`,
					},
				},
			};

			const res = await supportApi.sendSupportTicket(payload);
			if (res?.request?.id) {
				toast.success("Request submitted successfully!");
				handleClose && handleClose();
				onSubmitSuccess && onSubmitSuccess();
			} else {
				toast.error(res.message)
			}
		} catch (err) {
			if (err.inner) {
				const errs = {}
				err.inner.forEach(err => {
					errs[err.path] = err.message
				})
				setErrors(errs);
			} else {
				toast.error("Failed to send request. Please try again!");
			}
		}
	};

	const handleClose = () => {
		setShow(false);
	};

	return (
		<Modal
			className="wrap-request-support-modal"
			show={show}
			onHide={handleClose}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>REQUEST SUPPORT</Modal.Title>
			</Modal.Header>
			<Modal.Body className="wrap-modal-body">
				<div className="close-btn">
					<img src={closeIcon} alt="close-icon" onClick={handleClose} />
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label className="mb-5">Question Type*</Form.Label>
						<Select
							placeholder="Question Type"
							name="questionType"
							className="question-type-select"
							classNamePrefix="select"
							value={questionType ? { value: questionType, label: `About ${questionType} ?` } : null}
							onChange={(e) => setQuestionType(e.value)}
							options={SUPPORT_QUESTION_TYPES}
							menuPortalTarget={document.getElementsByClassName("modal-content")[0]}
							styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
						/>
						<span className="error">{errors?.questionType}</span>
					</Form.Group>
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>Subject*</Form.Label>
						<FormControl
							id="surname"
							name="surname"
							className="font-poppins"
							type="input"
							value={subject}
							placeholder={"Subject"}
							onChange={(e) => setSubject(e.target.value)}
						/>
						<span className="error">{errors?.subject}</span>
					</Form.Group>
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>Message*</Form.Label>
						<FormControl
							id="address"
							name="address"
							type="input"
							className="font-poppins"
							value={message}
							placeholder="What do you need help with?"
							onChange={(e) => setMessage(e.target.value)}
						/>
						<span className="error">{errors?.message}</span>
					</Form.Group>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="finish" onClick={handleSubmit}>SUBMIT</div>
			</Modal.Footer>
		</Modal>
	);
};

export default RequestSupportModal;
