import React, { useEffect, useMemo, useRef } from "react";
import SideNav from "../sidenav";
import "./index.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import {
  reqSetActiveGalleryId,
  reqSetIsTransparent,
  reqSetActiveUnit
} from "../../reduxs/home/action";
import AccordionSidebar from "../accordion-sidebar";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import { AVAILABILITY_STATUS_ENUM } from "../../constants/master-data";
import { numberWithCommas } from "../../helper/utils";

const UnitDetail = ({ isPresentation }) => {
  const dispatch = useDispatch();
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const isShowGallery = useSelector((state) => state.unitExplore.isShowGallery);
  const isShowViewLine = useSelector(
    (state) => state.unitExplore.isShowViewLine
  );

  const unit = useSelector((state) => state.unitExplore.selectedUnit);
  const activeKeys = useSelector((state) => state.unitExplore.activeDetailKeys)
  const pageRef = useRef(null);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.SHOW_UNIT_FLOORPLAN) {
      handleShowFloorplan();
    }

    if (content.action === ACTION_NAME.CLOSE_UNIT_FLOORPLAN) {
      handleCloseFloorplan();
    }

    if (content.action === ACTION_NAME.SHOW_UNIT_GALLERY) {
      handleShowGallery();
    }

    if (content.action === ACTION_NAME.CLOSE_UNIT_GALLERY) {
      handleCloseGallery();
    }

    if (content.action === ACTION_NAME.SHOW_UNIT_VIEWLINE) {
      handleShowVirtualTour();
    }

    if (content.action === ACTION_NAME.CLOSE_UNIT_VIEWLINE) {
      handleCloseViewLine();
    }

    if (content.action === ACTION_NAME.CLICK_UNIT_DETAIL_INFO) {
      handleClickUnitDetail(content.data.currentActiveKeys, content.data.key);
    }

    if (content.action === ACTION_NAME.PAGE_SCROLL_UNIT_DETAIL) {
      if (pageRef.current && content.data) {
        pageRef.current.scrollTop = content.data.scrollPercentage * (pageRef.current.scrollHeight - pageRef.current.clientHeight);
      }
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const handleShowFloorplan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_UNIT_FLOORPLAN)
    }
    dispatch(unitExploreAct.reqIsShowViewLine(false));
    dispatch(unitExploreAct.reqIsShowGallery(false));
    let timeout = setTimeout(() => {
      dispatch(unitExploreAct.reqIsShowFloorplan(true));
      dispatch(reqSetIsTransparent(true));
      clearTimeout(timeout);
    }, 300);
  };

  const handleCloseFloorplan = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_FLOORPLAN)
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(reqSetIsTransparent(false));

  };

  const handleShowGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_UNIT_GALLERY)
    }
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    dispatch(unitExploreAct.reqIsShowViewLine(false));
    let timeout = setTimeout(() => {
      dispatch(reqSetActiveGalleryId(unit?.gallery?.id));
      dispatch(reqSetActiveUnit(unit))
      dispatch(unitExploreAct.reqIsShowGallery(!isShowGallery));
      dispatch(reqSetIsTransparent(true));
      clearTimeout(timeout);
    }, 300);
  };

  const handleShowVirtualTour = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SHOW_UNIT_VIEWLINE)
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(unitExploreAct.reqIsShowFloorplan(false));
    let timeout = setTimeout(() => {
      dispatch(reqSetActiveGalleryId(unit?.virtualTour?.id));
      dispatch(unitExploreAct.reqIsShowViewLine(true));
      dispatch(reqSetIsTransparent(true));
      clearTimeout(timeout);
    }, 300);
  };

  const handleCloseViewLine = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_VIEWLINE)
    }
    dispatch(unitExploreAct.reqIsShowViewLine(false));
  }

  const handleCloseGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_GALLERY)
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
  };

  const handleClickUnitDetail = (currentActiveKeys, key) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_UNIT_DETAIL_INFO, {
        currentActiveKeys,
        key
      })
    }

    if (currentActiveKeys.includes(key)) {
      dispatch(unitExploreAct.reqCloseUnitDetailInfo(key))
    } else {
      dispatch(unitExploreAct.reqShowUnitDetailInfo(key))
    }
  }

  const onScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget;
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PAGE_SCROLL_UNIT_DETAIL, {
        scrollPercentage: scrollTop / (scrollHeight - clientHeight),
      });
    }
  }

  const renderBtnFloorplan = () => {
    if (isShowFloorplan) {
      return (
        <li
          onClick={() => handleCloseFloorplan()}
          className="list-group-item text-uppercase text-decoration-underline"
        >
          CLOSE FLOORPLAN
        </li>
      );
    }

    return (
      <li
        onClick={() => handleShowFloorplan()}
        className="list-group-item text-uppercase"
      >
        VIEW FLOORPLAN
      </li>
    );
  };

  const renderBtnGallery = () => {
    if (isShowGallery) {
      return (
        <li
          onClick={() => handleCloseGallery()}
          className="list-group-item text-uppercase"
        >
          CLOSE GALLERY
        </li>
      );
    }

    return (
      <li
        onClick={() => handleShowGallery()}
        className="list-group-item text-uppercase"
      >
        VIEW GALLERY
      </li>
    );
  };

  const renderBtnVirtualTour = () => {
    if (isShowViewLine) {
      return (
        <li
          onClick={() => handleCloseViewLine()}
          className="list-group-item text-uppercase"
        >
          CLOSE VIEWLINES
        </li>
      );
    }

    return (
      <li
        onClick={() => handleShowVirtualTour()}
        className="list-group-item text-uppercase"
      >
        VIEW VIEWLINES
      </li>
    );
  };

  const formatMoney = (price) => {
    if (!price) return 0;

    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumSignificantDigits: Math.trunc(Math.abs(price)).toFixed().length
    });

    return formatter.format(price).substring(1) + ' USD';
  };

  return (
    <SideNav
      position="right"
      isDark={false}
      id="sidenavDetail"
      heading={
        (unit?.name && !String(unit.name).toLowerCase().includes('bayhouse'))
          ? 'RESIDENCE'
          : ' '
      }
      icon={unit?.name}
      footer={
        <div className="wrap-btn-detail mt-auto">
          {/*<img src={upgrade} alt="upgrade" className="wrap-btn-detail-img" />*/}
          <ul className="list-group mb-0">
            {renderBtnFloorplan()}
            {renderBtnGallery()}
            {renderBtnVirtualTour()}
          </ul>
        </div>
      }
    >
      <div ref={pageRef} onScroll={onScroll} className={"sidenav__wrapper accordion-sidebar scroll-detail disable-scroll-bar"}>
        <div className="details">
          <AccordionSidebar
            id="unit-detail-detail"
            onToggle={handleClickUnitDetail}
            activeKeys={activeKeys}
            data={[
              {
                title: 'DETAILS',
                items: [
                  {
                    title: 'Availability',
                    value: <span>{
                      unit?.availabilityStatus
                        ? AVAILABILITY_STATUS_ENUM[String(unit.availabilityStatus).toUpperCase()]
                        : ''
                    }</span>
                  },
                  // {
                  //   title: 'Price',
                  //   value: <span>{unit?.price}</span>
                  // },
                  {
                    title: 'SQ. FT.',
                    value: <span>{numberWithCommas(unit?.areaSqm)}</span>
                  },
                  {
                    title: 'Bedrooms',
                    value: <span>{unit?.bedrooms}</span>
                  },
                  {
                    title: 'Private Pool',
                    value: <span>{unit?.hasPool ? 'Yes' : 'No'}</span>
                  },
                ]
              }
            ]}
          />
        </div>
      </div>
    </SideNav>
  );
};

export default UnitDetail;
