import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import jwtAuth from "../../apis/utils/jwtAuth";
import icHome from "../../assets/images/logo-bay-harbor-white.svg";

const TopNavCMS = (props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const getActiveClass = (path) => {
    return path === pathname ? "active" : "";
  };

  const logout = () => {
    jwtAuth.removeToken();
    history.push("/holding");
  };
  return (
    <div className="wrap-top-nav show">
      <a className="cube-scene" href='/'>
        <img src={icHome} alt="icon-cube" />
      </a>
      <ul className="right">
        <li className={getActiveClass("/cms/residence")}>
          <Link to="/cms/residence" className="text-decoration-none">
            <div>Units</div>
          </Link>
        </li>
        <li className={getActiveClass("/cms/customers")}>
          <Link to="/cms/customers" className="text-decoration-none">
            <div>CUSTOMERS</div>
          </Link>
        </li>
        <li
          className={getActiveClass("/cms/content")}
        >
          <Link to="/cms/content" className="text-decoration-none">
            <div>GALLERY</div>
          </Link>
        </li>
        <li
          className={getActiveClass("/cms/faqs")}
        >
          <Link to="/cms/faqs" className="text-decoration-none">
            <div>FAQS</div>
          </Link>
        </li>
        <li onClick={() => logout()}>
          <div>LOG OUT</div>
        </li>
      </ul>
    </div>
  );
};
export default TopNavCMS;
