/* eslint-disable no-unused-vars */
import React from 'react';
import './reset-password.scss';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { validatePassword } from '../../helper/validation';
import authApi from '../../apis/api/auth';
import { toast } from 'react-toastify';
import logo from "../../assets/images/logo.svg";
import classNames from "classnames";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPassword = () => {
  const query = useQuery();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [message, setMessage] = useState({});
  const [dirty, setDirty] = useState({});

  useEffect(() => {
    if (!dirty.password) return;
    validatePasswordFunc()
  }, [password, dirty.password]);

  useEffect(() => {
    if (!dirty.passwordConfirmation) return;
    validatePasswordConfirmFunc()
  }, [passwordConfirmation, dirty.passwordConfirmation]);

  const validatePasswordFunc = () => {
    if (validatePassword(password) && dirty.password) {
      delete message.password;
      setMessage({ ...message });
      return true;
    } else {
      message.password = "Invalid password"
      setMessage({ ...message });
      return false;
    }
  }

  const validatePasswordConfirmFunc = () => {
    if (password === passwordConfirmation && dirty.passwordConfirmation) {
      delete message.passwordConfirmation;
      setMessage({ ...message });
      return true;
    } else {
      message.passwordConfirmation = 'Password confirmation not match';
      setMessage({ ...message });
      return false;
    }
  }

  const handleResetPassword = async () => {
    if (!validatePasswordFunc() || !validatePasswordConfirmFunc()) return;
    try {
      const res = await authApi.resetPassword({
        password: password,
        password_confirmation: passwordConfirmation,
        token: query.get('token'),
      });
      if (res.data) {
        toast.success("Password reset successfully");
        history.push('/holding');
      } else {
        toast.error("Link is expired")
      }
    } catch (error) {
    }
  };

  return (
    <div className="wrap-holding-page h-100">
      <div className={`modal-form`}>
        <img src={logo} className="logo" />
        <div className="modal-form__body">
          <input
            type="password"
            onChange={(e) => {
              setDirty({ ...dirty, password: true })
              setPassword(e.target.value)
            }}
            className={classNames("form-input", {
              "spacing": !message.password
            })}
            id="email"
            placeholder="Password"
          />
          {message.password && <p className="text-danger spacing mt-2">{message.password}</p>}
          <input
            type="password"
            onChange={(e) => {
              setDirty({ ...dirty, passwordConfirmation: true })
              setPasswordConfirmation(e.target.value)
            }}
            className={classNames("form-input", {
              "spacing": !message.passwordConfirmation
            })}
            id="email"
            placeholder="Password Confirmation"
          />
          {message.passwordConfirmation && <p className="text-danger spacing mt-2">{message.passwordConfirmation}</p>}
          <div className="bg-button-bottom">
            <button
              type="submit"
              onClick={handleResetPassword}
              className="btn-holding-login"
            >
              <span className="txt-login">Enter</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
