import * as type from './type';

const initialState = {
  modal: '',
  isShowExploreModal: false,
  isShowExpandAmenity: false,
  isShowAmenityVirtualTour: false
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.SET_EXPLORE_MODAL:
      return {
        ...state,
        modal: action.data.data,
      };
    case type.SET_IS_SHOW_EXPLORE_MODAL:
      return {
        ...state,
        isShowExploreModal: action.data,
      };
    case type.SET_IS_SHOW_EXPAND_AMENITY:
      return {
        ...state,
        isShowExpandAmenity: action.data,
      };
    case type.SET_IS_SHOW_AMENITY_VIRTUAL_TOUR:
      return {
        ...state,
        isShowAmenityVirtualTour: action.data,
      };
    default:
      return state;
  }
};
