import { SelectDownArrow } from '../svgs/icons';
import { components } from 'react-select';

export const SelectDropdown = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <SelectDownArrow />
        </components.DropdownIndicator>
    );
};
