import React, { useEffect, useMemo, useRef } from "react";
import UnitList from "./UnitList";
import UnitDetail from "./UnitDetail";
import UnitFilter from "./UnitFilter";
import "./index.scss";
import FloorPlanGallery from "../floor-plan-gallery";
import Gallery from "../gallery";
import VirtualTour from "../virtual-tour";
import { useSelector } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useDispatch } from "react-redux";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import { debounce, get } from "lodash";
import { reqSetIsTransparent } from '../../reduxs/home/action';
import socket from '../../helper/socket';
import { ACTION_NAME, WEBSOCKET_CHANNEL } from '../../constants/options';
import PopupGallery from '../media/popup-gallery';
import {unitGalleryImages, unitGalleryPenthouseImages} from './unit-gallery-images';
import { reqGetGalleryList } from "../../reduxs/gallery/action";
const MAX_PRICE = 20_000_000;
const MAX_SQFT = 5_000;
const UnitExplore = (props) => {
  const { handleUnitClick, setActiveObjectIds, isPresentation } = props;
  const dispatch = useDispatch();
  const floorPlanGalleryRef = useRef();

  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const isShowGallery = useSelector((state) => state.unitExplore.isShowGallery);
  const isShowViewLine = useSelector(
    (state) => state.unitExplore.isShowViewLine
  );
  const isShowUnitDetail = useSelector(
    (state) => state.unitExplore.isShowUnitDetail
  );
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isShowFilter = useSelector((state) => state.unitExplore.isShowFilter);
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const units = useSelector((state) => state.unitExplore.units);
  const filteredUnits = useSelector((state) => state.unitExplore.filteredUnits);

  const getGalleryImages = () => {
    const isPenthouse = get(selectedUnit, 'name', '')?.includes('Penthouse');

    if (isPenthouse) {
      return unitGalleryPenthouseImages;
    } else {
      return unitGalleryImages;
    }
  };


  const filterUnitBedroom = useSelector(
    (state) => state.unitExplore.filterUnitBedroom
  );
  const filterUnitAspect = useSelector(
    (state) => state.unitExplore.filterUnitAspect
  );
  const filterUnitRoomType = useSelector(
    (state) => state.unitExplore.filterUnitRoomType
  );
  const filterUnitPrice = useSelector(
    (state) => state.unitExplore.filterUnitPrice
  );
  const filterUnitLotSize = useSelector(
    (state) => state.unitExplore.filterUnitLotSize
  );
  const filterUnitAvailability = useSelector(
    (state) => state.unitExplore.filterUnitAvailability
  );
  const filterUnitPool = useSelector(
    (state) => state.unitExplore.filterUnitPool
  );
  const unitQuery = useSelector((state) => state.unitExplore.unitQuery);
  const isShowUnitList = useSelector(
    (state) => state.unitExplore.isShowUnitList
  );

  const isFiltered = useMemo(() => {
    return (
      filterUnitPool !== undefined ||
      filterUnitAspect ||
      filterUnitBedroom.length ||
      filterUnitPrice?.max ||
      filterUnitPrice?.min ||
      filterUnitRoomType.length ||
      filterUnitLotSize?.max ||
      filterUnitLotSize?.min ||
      filterUnitAvailability.length
    );
  }, [filterUnitPool, filterUnitAspect, filterUnitBedroom, filterUnitPrice, filterUnitRoomType, filterUnitLotSize, filterUnitAvailability])

  useEffect(() => {
    if (isFiltered) {
      setActiveObjectIds(
        filteredUnits.map((item) => {
          return item["3d_asset"]?.id;
        })
      );
    } else if (selectedUnit) {
      // const unitFiltereds = units.filter((item) => item.id == selectedUnit.id);
      // if (unitFiltereds.length)
      //   setActiveObjectIds([unitFiltereds[0]["3d_asset"]?.id]);
    } else {
      dispatch(unitExploreAct.reqSetIsShowUnitList(false))
      setActiveObjectIds([]);
    }
  }, [filteredUnits]);

  const handleGetUnit = () => {
    dispatch(unitExploreAct.reqGetUnitList(unitQuery));
  }

  const handleFilterUnit = debounce(() => {
    if (!units && !units.length) return;
    let filterUnits = [...units];
    filterUnits = filterUnits.filter((unit) => {
      if (filterUnitAspect && unit.aspect != filterUnitAspect.value) return false

      if (filterUnitBedroom.length) {
        const bedroomValue = filterUnitBedroom.map(x => x.value)
        if (!bedroomValue.includes(unit.bedrooms)) return false
      }

      if (filterUnitAvailability.length) {
        const availabilityValue = filterUnitAvailability.map(x => x.value)
        if (!availabilityValue.includes(unit.availabilityStatus)) return false
      }

      if (filterUnitPrice) {
        if (filterUnitPrice.min && unit.price < filterUnitPrice.min) return false
        if (filterUnitPrice.max && unit.price > filterUnitPrice.max) return false
      }

      if (filterUnitLotSize) {
        if (filterUnitLotSize.min && unit.areaSqm < filterUnitLotSize.min) return false
        if (filterUnitLotSize.max && unit.areaSqm > filterUnitLotSize.max) return false
      }

      if (filterUnitPool !== undefined) {
        return unit.hasPool === filterUnitPool
      }

      return unit
    })
    dispatch(unitExploreAct.reqSetFilteredUnit(filterUnits))
  }, 200)

  useEffect(() => {
    handleGetUnit()
    dispatch(reqGetGalleryList());
  }, [])

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     handleGetUnit()
  //   }, 20000)
  //   return () => clearInterval(timer)
  // }, [])

  useEffect(() => {
    handleFilterUnit();
  }, [
    filterUnitAspect,
    filterUnitBedroom,
    filterUnitPrice,
    filterUnitLotSize,
    filterUnitAvailability,
    filterUnitPool,
    units
  ]);

  const hideGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_GALLERY);
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(reqSetIsTransparent(false));
  };

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CLOSE_UNIT_GALLERY) {
      hideGallery();
    }
  };

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
  }, [isPresentation]);

  return (
    <>
      <div className={`wrap-sidenav`}>
        <CSSTransition
          in={isShowFilter}
          timeout={500}
          classNames="fade-left"
          unmountOnExit
        >
          <UnitFilter isPresentation={isPresentation} isTransparent={isTransparent} setActiveObjectIds={setActiveObjectIds} />
        </CSSTransition>
        <div
          className={`float-start position-relative`}
        >
          <CSSTransition
            in={!isTransparent && isShowUnitList}
            timeout={500}
            classNames="fade-left"
            unmountOnExit
          >
            <UnitList isPresentation={isPresentation} handleUnitClick={handleUnitClick} />
          </CSSTransition>
        </div>
      </div>
      <CSSTransition
        in={!!selectedUnit && isShowUnitDetail}
        timeout={500}
        classNames="fade-item"
        unmountOnExit
      >
        <div
          className={`wrap-sidenav wrap-sidenav--right ${isTransparent ? "invisible" : ""
            }`}
        >
          <UnitDetail isPresentation={isPresentation} />
        </div>
      </CSSTransition>

      <CSSTransition
        in={isShowFloorplan}
        timeout={1000}
        classNames="fade-item"
        unmountOnExit
      >
        <FloorPlanGallery isPresentation={isPresentation} floorPlanGalleryRef={floorPlanGalleryRef} />
      </CSSTransition>

      <TransitionGroup>
        {isShowGallery && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <PopupGallery
              isPresentation={isPresentation}
              show={isShowGallery}
              listImage={getGalleryImages()}
              isClientImages={true}
              onClose={hideGallery}
            />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup>
        {isShowViewLine && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <VirtualTour isPresentation={isPresentation} />
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

export default UnitExplore;
