import React from 'react';
import { Provider } from 'react-redux';
import { Router } from './routers/Router';
import store from './reduxs/store';
import './App.scss';
import { ToastContainer, toast } from 'react-toastify';
import './i18n/i18n'


const App = () => (
  <Provider store={store}>
    <Router />
    <ToastContainer pauseOnFocusLoss={false}/>
  </Provider>
);

export default App;
