import React, { useState, useEffect } from "react";
import { FormControl, Modal, Form } from "react-bootstrap";
import * as yup from 'yup';
import { toast } from "react-toastify";
import closeIcon from "../../../assets/images/close-white.svg";
import customersAPI from '../../../apis/api/customer';
import { useSelector } from "react-redux";

const validationSchema = yup.object().shape({
	firstname: yup.string().trim().required("First name is a required field"),
	surname: yup.string().trim().required("Last name is a required field"),
	email: yup.string().trim().email("Invalid email format").required("Email is a required field"),
	agent: yup.string().trim().required("Sales Agent is a required field"),
});

const AddCustomerModal = ({ show, setShow, onCreateSuccess }) => {
	const [firstname, setFirstName] = useState('');
	const [surname, setSurName] = useState('');
	const [email, setEmail] = useState('');
	const [agent,setAgent] = useState('');
  const [errors, setErrors] = useState({});

  const agents = useSelector((state) => state.cms.agents);

	useEffect(() => {
		if (agents?.[0]?.id) {
			setAgent(agents?.[0]?.id);
		}
	}, [agents]);

	const handleAddNewCustomer = async () => {		
		try {
			const data = {
				userGroup: "610a4a3351400773f94bfa89", //tenant
				firstname,
				surname,
				email,
				agent,
			};
			setErrors({});
			const result = await validationSchema.validate(data, { abortEarly: false });
			const res = await customersAPI.createCustomer(result);
			
			if (res.data) {
				toast.success("Customer created successfully!"); 
				handleClose && handleClose();
				onCreateSuccess && onCreateSuccess();
			} else {
				toast.error(res.message)
			}
		} catch (err) {
			if(err.inner) {
        const errs = {}
        err.inner.forEach(err => {
          errs[err.path] = err.message
        })
        setErrors(errs);
      }
		}
	};

	const handleClose = () => {
		setShow(false);
	};

	return (
		<Modal
			className="wrap-create-customer-modal"
			show={show}
			onHide={handleClose}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>CREATE NEW CUSTOMER</Modal.Title>
			</Modal.Header>
			<Modal.Body className="wrap-modal-body">
				<div className="close-btn">
					<img src={closeIcon} alt="close-icon" onClick={handleClose} />
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>First Name*</Form.Label>
						<FormControl
							id="firstname"
							name="firstname"
							type="input"
							value={firstname}
							placeholder="First Name"
							onChange={(e) => setFirstName(e.target.value)}
						/>
						<span className="error">{errors?.firstname}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>Last Name*</Form.Label>
						<FormControl
							id="surname"
							name="surname"
							type="input"
							value={surname}
							placeholder="Last Name"
							onChange={(e) => setSurName(e.target.value)}
						/>
						<span className="error">{errors?.surname}</span>
					</Form.Group>
				</div>
				<div className="wrap-form-group">
					<Form.Group>
						<Form.Label>Email*</Form.Label>
						<FormControl
							id="email"
							name="email"
							type="input"
							value={email}
							placeholder="Email"
							onChange={(e) => setEmail(e.target.value)}
						/>
						<span className="error">{errors?.email}</span>
					</Form.Group>
					<Form.Group>
						<Form.Label>SALES AGENT*</Form.Label>
						<div className="selector">
							<select
								className={errors?.agent && "error"}
								onChange={(e) => setAgent(e.target.value)}
								id="sales-agent"
								value={agent}
								placeholder="Sales Agent"
							>
								{
									agents?.length && agents.map(c => (
										<option key={c.id} value={c.id}>{c.name}</option>
									))
								}
							</select>
						</div>
						<span className="error">{errors?.agent}</span>
					</Form.Group>	
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="finish" onClick={handleAddNewCustomer}>SAVE</div>
			</Modal.Footer>
		</Modal>
	);
};

export default AddCustomerModal;
