import * as Types from './type';
import unitApi from '../../apis/api/unit';
import areaApi from '../../apis/api/area';

import { groupBy } from 'lodash'
const actGetUnitList = (data) => ({
  type: Types.GET_UNIT_LIST,
  data,
});

const actGetUnitArea = (data) => ({
  type: Types.GET_UNIT_AREA_LIST,
  data,
});

const actIsShowFloorplan = (data) => ({
  type: Types.SET_IS_SHOW_FLOORPLAN,
  data,
});

const actIsShowGallery = (data) => ({
  type: Types.SET_IS_SHOW_GALLERY,
  data,
});

const actIsShowUnitDetail = (data) => ({
  type: Types.SET_IS_SHOW_UNIT_DETAIL,
  data,
});

const actSetSelectedUnit = (data) => ({
  type: Types.SET_SELECTED_UNIT,
  data,
});

const actSetUnitQuery = (data) => ({
  type: Types.SET_UNIT_QUERY,
  data,
});

const actSetIsShowFilter = (data) => ({
  type: Types.SET_IS_SHOW_FILTER,
  data,
});

const actSetIsShowPrecinctDetail = (data) => ({
  type: Types.SET_IS_SHOW_PRECINCT_DETAIL,
  data,
});

const actSetIsShowUnitList= (data) => ({
  type: Types.SET_IS_SHOW_UNIT_LIST,
  data,
});

const actShowUnitDetailInfo = (data) => ({
  type: Types.SHOW_UNIT_DETAIL_INFO,
  data
})

const actCloseUnitDetailInfo = (data) => ({
  type: Types.CLOSE_UNIT_DETAIL_INFO,
  data
})

export const reqGetUnitList = (query) => (dispatch) => {
  return unitApi
    .getUnitList(query)
    .then((data) => {
      dispatch(actGetUnitList(data));
    })
    .catch(() => {});
};

export const reqGetUnitArea = (query) => (dispatch) => {
  return areaApi
    .getAreasList(query)
    .then((data) => {
      dispatch(actGetUnitArea(data));
    })
    .catch(() => {});
};

export const reqSetSelectedUnit = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actSetSelectedUnit(''));
  }

  return unitApi
    .getUnitDetail(id)
    .then((data) => {
      dispatch(actSetSelectedUnit(data));
    })
    .catch(() => {});
};

export const reqFilterUnitGrossRent = (data) => (dispatch) => {
  return dispatch(actFilterUnitGrossRent(data));
};

export const reqFilterUnitCentre = (data) => (dispatch) => {
  return dispatch(actFilterUnitCentre(data));
};

export const reqFilterUnitLevel = (data) => (dispatch) => {
  return dispatch(actFilterUnitLevel(data));
};

export const reqFilterUnitArea = (data) => (dispatch) => {
  return dispatch(actFilterUnitArea(data));
};
export const reqFilterUnitPrecinct = (data) => (dispatch) => {
  return dispatch(actFilterUnitPrecinct(data));
};

export const reqIsShowFloorplan = (data) => (dispatch) => {
  return dispatch(actIsShowFloorplan(data));
};

export const reqIsShowGallery = (data) => (dispatch) => {
  return dispatch(actIsShowGallery(data));
};

export const reqIsShowUnitDetail = (data) => (dispatch) => {
  return dispatch(actIsShowUnitDetail(data));
};

export const reqSetUnitQuery = (data) => (dispatch) => {
  return dispatch(actSetUnitQuery(data));
};

export const reqSetIsShowFilter = (data) => (dispatch) => {
  return dispatch(actSetIsShowFilter(data));
};

export const reqSetIsShowPrecinctDetail = (data) => (dispatch) => {
  return dispatch(actSetIsShowPrecinctDetail(data));
};

const actFilterUnitAspect = (data) => ({
  type: Types.FILTER_UNIT_ASPECT,
  data,
});

export const reqFilterUnitAspect = (data) => (dispatch) => {
  return dispatch(actFilterUnitAspect(data));
};

const actFilterUnitBedroom = (data) => ({
  type: Types.FILTER_UNIT_BEDROOM,
  data,
});

export const reqFilterUnitBedroom = (data) => (dispatch) => {
  return dispatch(actFilterUnitBedroom(data));
};

const actFilterUnitPool = (data) => ({
  type: Types.FILTER_UNIT_POOL,
  data,
});

export const reqFilterUnitPool = (data) => (dispatch) => {
  return dispatch(actFilterUnitPool(data))
};

const actFilterUnitPrice = (data) => ({
  type: Types.FILTER_UNIT_PRICE,
  data,
});

export const reqFilterUnitPrice = (data) => (dispatch) => {
  return dispatch(actFilterUnitPrice(data));
};

const actIsShowViewLine = (data) => ({
  type: Types.SET_IS_SHOW_VIEW_LINE,
  data,
});

export const reqIsShowViewLine = (data) => (dispatch) => {
  return dispatch(actIsShowViewLine(data));
};

const actFilterUnitLotSize = (data) => ({
  type: Types.FILTER_UNIT_LOT_SIZE,
  data,
});


export const reqSetIsShowUnitList = (data) => (dispatch) => {
  return dispatch(actSetIsShowUnitList(data));
};

export const reqFilterUnitLotSize = (data) => (dispatch) => {
  return dispatch(actFilterUnitLotSize(data));
};

const actFilterUnitRoomType = (data) => ({
  type: Types.FILTER_UNIT_ROOM_TYPE,
  data,
});

export const reqFilterUnitRoomType = (data) => (dispatch) => {
  return dispatch(actFilterUnitRoomType(data));
};

const actFilterUnitAvailability = (data) => ({
  type: Types.FILTER_UNIT_AVAILABILITY,
  data,
});

export const reqFilterUnitAvailability = (data) => (dispatch) => {
  return dispatch(actFilterUnitAvailability(data));
};

export const reqShowUnitDetailInfo = (data) => (dispatch) => {
  return dispatch(actShowUnitDetailInfo(data))
}

export const reqCloseUnitDetailInfo = (data) => (dispatch) => {
  return dispatch(actCloseUnitDetailInfo(data))
}

const actSetFilteredUnit = (data) => ({
  type: Types.SET_FILTERED_UNIT,
  data
})

export const reqSetFilteredUnit = (data) => (dispatch) => {
  return dispatch(actSetFilteredUnit(data))
}
