import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getUploadedMediaUrl } from '../../helper/media';
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from '../../constants/options';
import galleryApi from '../../apis/api/gallery';
import { useDispatch } from 'react-redux';
import { reqSetActivePatourId, reqSetIsTransparent, reqSetPage } from '../../reduxs/home/action';
import { useSelector } from 'react-redux';
import * as unitExploreAct from '../../reduxs/unit-explore/action';
import socket from '../../helper/socket';
import closeIcon from '../../assets/images/close-v3.svg'
import ChevronLeft from '../../assets/images/left-arrow.svg';
import ChevronRight from '../../assets/images/right-arrow.svg';

const Gallery = ({ isPresentation }) => {
  const [gallery, setGallery] = useState('');
  const [media, setMedia] = useState([]);
  const dispatch = useDispatch();
  const galleryRef = useRef();
  const activeGalleryId = useSelector((state) => state.home.activeGalleryId);
  const activeUnit = useSelector((state) => state.home.activeUnit);
  const isShowGallery = useSelector((state) => state.unitExplore.isShowGallery);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    className: 'slides',
    arrows: false,
    fade: true,
    lazyLoad: true,
    afterChange: (index) => {
      if (!isPresentation) {
        socket.emitUIActionEvent(ACTION_NAME.CHANGE_SLIDE_GALLERY, {
          index,
        });
      }
    },
  };
  const isTransparent = useSelector((state) => state.home.isTransparent);

  const HOTSPOT_TYPES = {
    PANO_TOUR: '360_pano_tour',
    URL: 'url',
    VIDEO: 'video',
  };

  useEffect(async () => {
    const res = await galleryApi.getGalleryDetail(activeGalleryId);
    // console.log(res)
    // TODO: uncomment this after we have data
    // setGallery(res.data);
    setGallery({
      media: [
        {
          isActive: true,
          type: 'image',
          path: '/media/media_images/image_gallery/BINYAN_PPG2747_BayHarborIsland2_S310_EXT_Hero_FINAL_2K.jpg',
          order: 15,
          id: '653075625d6a9ed09e667e21',
        },
        {
          isActive: true,
          type: 'image',
          path: '/media/media_images/image_gallery/BINYAN_PPG2747_BayHarborIsland2_S320_EXT_HeroWater_Final_2K.jpg',
          order: 14,
          createdAt: '2024-01-09T01:35:31.093Z',
          updatedAt: '2024-01-09T01:36:13.207Z',
          id: '653075625d6a9ed09e667e22',
        },
        {
          isActive: true,
          type: 'image',
          path: '/media/media_images/image_gallery/BINYAN_PPG2747_BayHarborIsland2_S330_EXT_Aerial_FINAL_2K.jpg',
          order: 14,
          createdAt: '2024-01-09T01:35:31.093Z',
          updatedAt: '2024-01-09T01:36:13.207Z',
          id: '653075625d6a9ed09e667e22',
        },
        {
          isActive: true,
          type: 'image',
          path: '/media/media_images/image_gallery/BINYAN_PPG2747_BayHarborIsland2_S340_EXT_RooftopPool_Final_2K.jpg',
          order: 14,
          createdAt: '2024-01-09T01:35:31.093Z',
          updatedAt: '2024-01-09T01:36:13.207Z',
          id: '653075625d6a9ed09e667e22',
        },
      ],
      floorplans: [],
      name: 'Orla renders',
      type: 'image',
      thumbnail: '/media/1683781209936.png',
      createdAt: '2023-04-11T02:42:14.480Z',
      updatedAt: '2023-05-12T09:48:08.734Z',
      id: '6434c906df01b100465efae8',
    });
  }, [activeGalleryId]);

  useEffect(async () => {
    setMedia(gallery?.media ? gallery.media : []);
  }, [gallery]);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.ENABLE_TRANSPARENT_MODE) {
      enableTransparent();
    }
    if (content.action === ACTION_NAME.DISABLE_TRANSPARENT_MODE) {
      showFilter();
    }
    if (content.action === ACTION_NAME.CHANGE_SLIDE_GALLERY) {
      onGoToSlideIndex(content.data.index);
    }
    if (content.action === ACTION_NAME.CLOSE_UNIT_GALLERY) {
      hideGallery();
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
  }, [isPresentation]);

  const handleClickHotspot = async (data) => {
    const hotpotData = data;

    if (hotpotData.link_type == HOTSPOT_TYPES.PANO_TOUR) {
      dispatch(reqSetPage(PAGES.IMMERSE_PAGE));
      dispatch(reqSetActivePatourId(hotpotData.link));
    }
  };

  const showFilter = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.DISABLE_TRANSPARENT_MODE);
    }
    dispatch(reqSetIsTransparent(false));
  };

  const hideGallery = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_GALLERY);
    }
    dispatch(unitExploreAct.reqIsShowGallery(false));
    dispatch(reqSetIsTransparent(false));
  };

  const enableTransparent = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.ENABLE_TRANSPARENT_MODE);
    }
    dispatch(reqSetIsTransparent(true));
  };

  const onGoToSlideIndex = (index) => {
    galleryRef?.current?.slickGoTo(index);
  };

  return (
    <>
      <div className='wrap-gallery-image'>
        <div className={`gallery-header ${isPresentation ? 'presentation' : ''}`}>
          <p>{`Residence ${activeUnit.name}`}</p>
          <img className='close-icon' src={closeIcon} alt="close-icon" onClick={hideGallery} />
        </div>
        <Slider ref={galleryRef} {...settings}>
          {(media || []).map((item, key) => {
            return (
              <div key={key}>
                <div
                  className='gallery-item'
                  style={{
                    backgroundImage: `url(${getUploadedMediaUrl(item.path)})`,
                  }}
                >
                  {(item.hotspots || []).map((item, index) => {
                    return (
                      <img
                        onClick={() => handleClickHotspot(item)}
                        key={index}
                        style={{
                          left: `${item.position.x}%`,
                          top: `${item.position.y}%`,
                        }}
                        className='gallery-hotspot'
                        src={getUploadedMediaUrl(item.image_path)}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Slider>
        <span className='btn-gallery prev-slide'>
          <img
            width={80}
            height={80}
            src={ChevronLeft}
            alt='cms-extra-left'
            onClick={() => galleryRef.current.slickPrev()}
          />
        </span>
        <span className='btn-gallery next-slide'>
          <img
            width={80}
            height={80}
            src={ChevronRight}
            onClick={() => galleryRef.current.slickNext()}
          />
        </span>
      </div>
      {!isTransparent && isShowGallery && (
        <div className='wrap-close-btn'>
          <div onClick={enableTransparent} className='reopen'>
            <span>Close panels</span>
            <img src='/icons/arrow-panel.svg' width={13.5} />
          </div>
        </div>
      )}
    </>
  );
};

export default Gallery;
