import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faVolumeUp,
  faEye,
  faLightbulb,
  faHeart,
  faEyeSlash,
  faUser,
  faSignOutAlt,
  faTimesCircle,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  reqSetIsShowExploreModal,
  reqSetPage,
} from "../../reduxs/home/action";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { USER_GROUP } from "../../constants/master-data";
import {
  reqGetCustomerProfile,
  reqGetUserProfile,
  reqSetCustomerProfile,
} from "../../reduxs/user/action";
import preferenceApi from "../../apis/api/preference";
import { toast } from "react-toastify";
import { reqGetCustomerPreference } from "../../reduxs/precinct-explore/action";
import jwtAuth from "../../apis/utils/jwtAuth";
import {
  reqSetIsShowLanguageModal,
} from "../../reduxs/guide-session/action";
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import { ReactComponent as Logout } from "../../assets/images/icLogout.svg";
import { classNames } from "../../helper/utils";
import { ActiveStarIcon, InActiveStarIcon, StarIcon } from "../svgs/icons";
import { ReactComponent as Person } from '../../assets/images/iconPerson.svg'
import socket from "../../helper/socket";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

library.add(
  faVolumeUp,
  faEye,
  faLightbulb,
  faHeart,
  faEyeSlash,
  faUser,
  faSignOutAlt,
  faCalendarCheck,
  faTimesCircle
);
const BottomNavigation = ({
  handleClickEndGuideTenantSession,
  handleClickTenantEndSession,
  isPresentation,
  setActiveObjectIds
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const customerId = useQuery().get("customer");
  const customer = useSelector((state) => state.user.customer);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const isShowFloorplan = useSelector(
    (state) => state.unitExplore.isShowFloorplan
  );
  const preference = useSelector(
    (state) => state.precinctExplore.customerPreference
  );
  const authUser = useSelector((state) => state.user.data);
  const isShowEndGuideTenantSession = useSelector(
    (state) => state.home.isShowEndGuideTenantSession
  );
  const page = useSelector((state) => state.home.page);

  useEffect(() => {
    dispatch(reqGetUserProfile());
  }, []);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action == ACTION_NAME.CLICK_END_SESSION) {
      handleShowPostSessionPopup();
    }
    if (content.action == ACTION_NAME.CLICK_LANGUAGE_BUTTON) {
      handleLanguages();
    }
  };
  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  useEffect(() => {
    if (customerId) {
      if (customerId !== customer?.id) {
        getCustomerPreference();
        getCustomerDetail();
      }
    } else {
      if (customer && customer?.id) {
        dispatch(reqSetCustomerProfile(''));
      }
    }
  }, [customerId, customer]);

  const onClickUserIcon = () => {
    window.location.href = "/cms";
  };

  const getCustomerDetail = () => {
    try {
      dispatch(reqGetCustomerProfile(customerId));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const getCustomerPreference = async () => {
    try {
      dispatch(reqGetCustomerPreference(customerId));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleShowPostSessionPopup = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_END_SESSION)
    }
    dispatch(reqSetIsShowExploreModal(false));
    setActiveObjectIds([])
    analytics.track("Agent Ended Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientEmail: customer?.email,
      clientPhone: customer?.mobile,
    });
    dispatch(reqSetPage(PAGES.SESSION_PAGE));
  };

  const sendToastMessage = (message, type) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.TOAST_MESSAGE, {
        message,
        type
      })
    }
    toast[type](message, {
      toastId: "socket-toast"
    });
  }

  const handleSavePreference = () => {
    if (!selectedUnit) {
      return sendToastMessage("Please select an unit!", "error");
    }
    let data = {};

    if (selectedUnit) {
      let unitIds = getPreferenceUnitIds();

      if (unitIds.includes(selectedUnit.id)) {
        unitIds = unitIds.filter((item) => item !== selectedUnit.id);
        data.units = unitIds;
      } else {
        unitIds.push(selectedUnit.id);
        data.units = unitIds;
      }
    }

    if (customerId && Object.keys(data).length) {
      data.customer = customerId;
      preferenceApi
        .savePreference(data)
        .then(() => {
          sendToastMessage("Saved Preference successfully!", "success");
          getCustomerPreference();
          analytics.track("Agent Saved Property", {
            agentId: authUser?.id,
            clientId: customer?.id,
            clientEmail: customer?.email,
            clientPhone: customer?.mobile,
            propertyId: selectedUnit?.id,
          });
        })
    }
  };

  const handleUnsavePreference = () => {
    if (!selectedUnit) {
      return sendToastMessage("Please select an unit!", "error");
    }
    let data = {};

    if (selectedUnit) {
      let unitIds = getPreferenceUnitIds();

      if (unitIds.includes(selectedUnit.id)) {
        unitIds = unitIds.filter((item) => item !== selectedUnit.id);
        data.units = unitIds;
      } else {
        unitIds.push(selectedUnit.id);
        data.units = unitIds;
      }
    }

    if (customerId && Object.keys(data).length) {
      data.customer = customerId;
      preferenceApi
        .savePreference(data)
        .then(() => {
          sendToastMessage("Unsaved Preference successfully!", "success");
          getCustomerPreference();
          analytics.track("Agent Unsaved Property", {
            agentId: authUser?.id,
            clientId: customer?.id,
            clientEmail: customer?.email,
            clientPhone: customer?.phone,
            propertyId: selectedUnit?.id,
          });
        })
      // .then((res) => {
      //   // dispatch(unitExploreAct.reqGetUnitList(unitQuery));
      // });
    }
  };

  const getPreferenceUnitIds = () => {
    const units = preference?.units || [];

    return units.map((item) => item.id);
  };

  const handleLogout = async () => {
    setActiveObjectIds([])
    await jwtAuth.removeToken();
    history.replace("/holding");
    dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
  };

  const handleSelectProfile = () => {
    dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
  }

  const handleLanguages = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLICK_LANGUAGE_BUTTON);
    }
    dispatch(reqSetIsShowLanguageModal(true));
  };

  // const handleShowMakeAppointmentForm = () => {
  //   dispatch(reqSetIsShowBookingAppointmentForm(true));
  // };

  // const handleCloseMakeAppointmentForm = () => {
  //   dispatch(reqSetIsShowBookingAppointmentForm(false));
  // };

  const darkPage = [
    PAGES.ONBOARD_PAGE,
    PAGES.UNIT_EXPLORER_PAGE,
    PAGES.AMENITIES_PAGE,
    PAGES.LANDING_PAGE,
  ].includes(page);

  // const renderLanguageBtn = () => {
  //   return (
  //     <div onClick={handleLanguages} className="locale-btn">
  //       <Language fill={darkPage && !isShowFloorplan ? "white" : "#01344B"} />
  //     </div>
  //   );
  // };

  // const handleClickEyeIcon = (isTransparent) => {
  //   dispatch(reqSetIsTransparent(!isTransparent));
  // };

  // const renderEyeIcon = () => {
  //   if (page !== PAGES.ONBOARD_PAGE) {
  //     return (
  //       <div>
  //         <span
  //           className="btn-item"
  //           onClick={() => handleClickEyeIcon(isTransparent)}
  //         >
  //           {!isTransparent ? (
  //             <img src="/icons/eye-open.svg" alt="" />
  //           ) : (
  //             <img src="/icons/eye-close.svg" alt="" />
  //           )}
  //         </span>
  //       </div>
  //     );
  //   }
  // };

  const renderLogoutBtn = () => {
    if (!authUser) return;
    const fill = 'white';

    return (
      <div
        onClick={handleLogout}
        className="cursor-pointer logout-btn"
      >
        <Logout fill={fill} />
      </div>
    );
  };

  const renderSelectProfileBtn = () => {
    return (
      <div
        onClick={handleSelectProfile}
        className="select-profile-btn"
      >
        Back to start
      </div>
    );
  };

  // const renderMakeAppointmentBtn = () => {
  //   if (!authUser) return;

  //   if (isShowBookingAppointmentForm) {
  //     return (
  //       <span
  //         onClick={handleCloseMakeAppointmentForm}
  //         className="me-2 cursor-pointer"
  //         style={{ fontSize: "20px" }}
  //       >
  //         <FontAwesomeIcon icon={["fas", "times-circle"]} />
  //       </span>
  //     );
  //   }

  //   return (
  //     <span
  //       onClick={handleShowMakeAppointmentForm}
  //       className="me-2 cursor-pointer"
  //       style={{ fontSize: "20px" }}
  //     >
  //       <FontAwesomeIcon icon={["fas", "calendar-check"]} />
  //     </span>
  //   );
  // };

  const renderCustomerName = () => {
    if (page !== PAGES.ONBOARD_PAGE) {
      return (
        <span className="text-customer">
          {customer ? `${customer?.firstname} ${customer?.surname}` : `customer name`}
        </span>
      );
    }
  };

  const renderUserBtn = () => {
    if (page === PAGES.ONBOARD_PAGE) {
      return (
        <span className="btn-item cms" onClick={() => onClickUserIcon()}>
          <Person fill={page === PAGES.ONBOARD_PAGE ? "white" : "black"} />
        </span>
      );
    }
  };

  const renderStarBtn = () => {
    if (page === PAGES.ONBOARD_PAGE) return null;
    const unitIds = getPreferenceUnitIds();

    if (unitIds.includes(selectedUnit?.id)) {
      return (
        <span className='btn-item star' onClick={() => handleUnsavePreference()}>
          <ActiveStarIcon />
        </span>
      );
    }

    if (selectedUnit) {
      return (
        <span className='btn-item star' onClick={() => handleSavePreference()}>
          <InActiveStarIcon />
        </span>
      );
    }

    return (
      <span className='btn-item star'>
        <StarIcon />
      </span>
    );
  };

  const renderEndSessionBtn = () => {
    if (!customer || !customerId) return;

    if (
      customer?.userGroup?.name === USER_GROUP.TENANT &&
      !isShowEndGuideTenantSession
    ) {
      return (
        <span
          className="btn-end"
          onClick={() => handleClickEndGuideTenantSession(customerId)}
        >
          END GUIDED SESSION
        </span>
      );
    }

    if (
      customer?.userGroup?.name === USER_GROUP.TENANT &&
      isShowEndGuideTenantSession
    ) {
      return (
        <span
          className="btn-end"
          onClick={() => handleClickTenantEndSession()}
        >
          END GUIDED SESSION
        </span>
      );
    }

    return (
      <span
        className="btn-end"
        onClick={() => handleShowPostSessionPopup()}
      >
        END GUIDED SESSION
      </span>
    );
  };

  if (isPresentation) {
    return null
  }

  return (
    <>
      {/* {isShowFloorplan && <div className="outline" />} */}
      <div
        className={classNames(
          "wrap-bottom-navigation",
          page === PAGES.VIEWS_LEVEL ? "linear-bottom" : "",
          page === PAGES.SESSION_PAGE && "hide-bottom",
          isShowFloorplan && "is-show-floorplan",
        )}
      >
        <div className="left-group-btn">
          {/* {renderLanguageBtn()} */}
          {/*{renderEyeIcon()}*/}
          {(!customer && !customerId) && renderLogoutBtn()}
          {(!customer && !customerId) && page !== PAGES.ONBOARD_PAGE && renderSelectProfileBtn()}
          {/*{false && renderMakeAppointmentBtn()}*/}

          {/* Hide while Session Summary in development */}
          {renderEndSessionBtn()}

        </div>
        <div className="right-group-btn">
          {renderCustomerName()}
          {renderStarBtn()}
          {renderUserBtn()}
        </div>
      </div>
    </>
  );
};

export default BottomNavigation;
