import ApiClient from '../apiClient';
import { authFetcher, defaultFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);
const defaultClient = new ApiClient(defaultFetcher);

const getGalleryDetail = (galleryId, data) =>
  client.get(
    endPoint.GALLERY_GET_DETAIL.replace(':galleryId', galleryId),
    data
  );

const createGallery = (data) =>
  defaultClient.post(endPoint.GALLERY_CREATE, data);

const getListGallery = (data) =>
  defaultClient.get(endPoint.GALLERY_GET_LIST, data);

const getListGalleryFloor = (data) =>
  defaultClient.get(endPoint.GALLERY_GET_LIST, data);

const findOneGalleryDetail = (data) =>
  defaultClient.get(endPoint.GALLERY_FIND_ONE, data);

const updateGallery = (id, data) =>
  defaultClient.put(endPoint.GALLERY_UPDATE.replace(':id', id), data);

const deleteGallery = (id) =>
  defaultClient.delete(endPoint.GALLERY_DELETE.replace(':id', id));

export default {
  getGalleryDetail,
  createGallery,
  getListGallery,
  updateGallery,
  deleteGallery,
  getListGalleryFloor,
  findOneGalleryDetail
};
