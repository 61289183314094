import React from "react";
import './index.scss'

const CreateIcon = ({onClick, createCustomer}) => {
    const className = !createCustomer ? 'create-component' : 'create-component create-customer';
    return(
        <div className={className} onClick={onClick}>
            <img className="img-fluid" src="/icons/icCreateCircle.svg" alt="" />
            <span>{createCustomer ? 'ADD NEW CUSTOMER' : 'SUBMIT SUPPORT TICKET'}</span>
        </div>
    )
};

export default CreateIcon;
