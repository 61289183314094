/* eslint-disable no-unreachable */
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { routeOptions } from './options';
import path from './path';
import authApi from '../apis/api/auth';
import jwtAuth from '../apis/utils/jwtAuth';

const redirectPath = '/holding';

export const Router = () => {
  const authMiddleware = async (routeRoles, history) => {
    if (routeRoles.length) {
      const isToken = await jwtAuth.checkToken();
      if (isToken) {
        return getUserProfile(routeRoles, history);
      } else {
        return history.push(redirectPath);
      }
    }
  };


  const getUserProfile = async (routeRoles, history) => {
    try {
      const res = await authApi.getMe();
      const userData = res?.data;
      if (routeRoles.length && !routeRoles.includes(userData?.userGroup?.name)) {
        return history.push(redirectPath);
      }
    } catch (error) {
      return history.push(redirectPath);
    }
  };

  return (
    <BrowserRouter>
      <Switch>
        {routeOptions.map((item, index) => (
          <Route
            key={index}
            path={item.path}
            render={(props) => (
              <item.component {...props} roles={item.roles} authMiddleware={authMiddleware} />
            )}
            exact={item.exact}
          />
        ))}
        <Route path="*">
          <Redirect to={path.NOTFOUND_PAGE} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
