import React from "react";

const Header = () => {
  return (
    <div className="wrap-title page-header d-flex align-items-center">
      <h1 className="f-title mb-0">GALLERY</h1>
    </div>
  );
};

export default Header;
