/** @format */

import "./index.scss";
import { createPortal } from "react-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";

import { getImagePath, getUploadedServerMediaUrl } from "../../../helper/media";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Navigation } from "swiper";
import socket from "../../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../../constants/options";
import closeIcon from "../../../assets/images/close-v3.svg";

const PopupGallery = ({
  show,
  listImage,
  onClose,
  isPresentation,
  isClientImages = false,
  children,
  isShowPreNextSlide = false,
  isShowViewLine = false,
  nameUnit = "",
}) => {
  const [swiper, setSwiper] = useState(null);
  const [currentIdx, setCurrentIdx] = useState(0);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CHANGE_SLIDE_GALLERY) {
      handleIndexChange(content.data.index);
    }
  };
  useEffect(() => {
    if (isPresentation && swiper) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation, swiper]);

  const handleIndexChange = (index) => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CHANGE_SLIDE_GALLERY, {
        index,
      });
    }
    setCurrentIdx(index)
    if (swiper && swiper?.params) swiper.slideToLoop(index, 150);
  };

  const handleNavigation = (direction) => {
    if (direction === "left") {
      swiper.slidePrev();
    } else {
      swiper.slideNext();
    }
  };

  const getPosition = useCallback(() => {
    try {
      const str = listImage[currentIdx]?.name || "Unit 206 East View.jpg";
      const split02 = str.split(".");
      const split03 = split02[0].split(" ");
      const lastVal = split03[4] || ""
      return split03[2] + " " + split03[3] + " " + lastVal;
    } catch (e) {
      console.log("error split name", e);
      return "";
    }
  }, [currentIdx, swiper]);

  const initialSlide = listImage.findIndex((item) => item.path === show)

  return (
    <>
      {isShowViewLine && (
        <div className={`wrap-top-nav second-nav ${isPresentation ? 'presentation' : ''}`}>
          <div className="top-nav">
            <div className="unit-name">RESIDENCE {nameUnit}</div>
            <div className="postiont-close">
              <div className="wrapper-positon">
                <span>{getPosition()}</span>
              </div>
              <img
                onClick={onClose}
                src={closeIcon}
                className="close-icon-nav"
              />
            </div>
          </div>
        </div>
      )}
      {show &&
        createPortal(
          <div
            className={`media-popup-gallery ${
              isShowViewLine && "media-popup-view-line"
            }`}
          >
            <div className={"popup-gallery-wrapper"}>
              {!isShowViewLine && (
                <div className={"popup-gallery-close"} onClick={onClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="24"
                    viewBox="0 0 26 24"
                    fill="none"
                  >
                    <path
                      d="M1 1L25 22.8182M25 1L1 22.8182"
                      stroke="white"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              )}

              <Swiper
                onSwiper={(swiper) => {
                  setSwiper(swiper)
                }}
                navigation={true}
                modules={[Navigation]}
                className={"popup-gallery-swiper"}
                initialSlide={initialSlide !== -1 ? initialSlide : 0}
                loop={true}
                onRealIndexChange={(s) => handleIndexChange(s.realIndex)}
              >
                {listImage &&
                  listImage.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div key={"item-" + index} className="container-image">
                          <img
                            style={{ width: "100%", height: "100%" }}
                            alt={item?.name}
                            src={
                              isClientImages
                                ? getImagePath(item?.path)
                                : getUploadedServerMediaUrl(item?.path)
                            }
                            width={"100%"}
                            height={"100%"}
                          />
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
              {isShowPreNextSlide && (
                <>
                  <div
                    className={"popup-gallery-navigation left"}
                    onClick={() => handleNavigation("left")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                    >
                      <circle cx="40" cy="40" r="40" fill="#242422" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="10"
                      viewBox="0 0 32 10"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1226_46867)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.207 5.50048H32V4.50048H2.207L5.354 1.35448L4.646 0.646484L0.646 4.64648L0.292999 5.00048L0.646 5.35448L4.646 9.35448L5.354 8.64648L2.207 5.50048Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1226_46867">
                          <rect
                            width="32"
                            height="10"
                            fill="white"
                            transform="matrix(-1 0 0 1 32 0)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div
                    className={"popup-gallery-navigation right"}
                    onClick={() => handleNavigation("right")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                    >
                      <circle cx="40" cy="40" r="40" fill="#242422" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="10"
                      viewBox="0 0 32 10"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1226_46863)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M29.793 5.50048H0V4.50048H29.793L26.646 1.35448L27.354 0.646484L31.354 4.64648L31.707 5.00048L31.354 5.35448L27.354 9.35448L26.646 8.64648L29.793 5.50048Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1226_46863">
                          <rect width="32" height="10" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </>
              )}

              {children}
            </div>
          </div>,
          document.body
        )}
    </>
  );
};
export default PopupGallery;
