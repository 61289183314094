import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { getImagePath, getUploadedServerMediaUrl } from "../../helper/media";
import closeV2 from "../../assets/images/closeV2.svg";
import socket from "../../helper/socket";
import { ACTION_NAME, WEBSOCKET_CHANNEL } from "../../constants/options";
import { useEffect, useRef, useState } from "react";
import iconArrowBg from "../../assets/images/bg-arrow.svg";

const FloorplanModal = ({ mediaImages, onClose, isPresentation }) => {
  const transformComponentRef = useRef(null);

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.ZOOM_OUT_FLOORPLAN_SESSION) {
      if (!transformComponentRef.current) return;
      if (content.data.state) {
        const ratioHeight = window.screen.height / content.data.height;
        const ratioWidth = (window.screen.width * 0.7) / content.data.width;
        const marginWidth = ratioHeight > 1 ? 0.98 : 1.03;
        const marginHeight = ratioHeight > 1 ? 1.3 : 0.98;
        return transformComponentRef.current.setTransform(
          content.data.state.positionX * ratioWidth * marginWidth,
          content.data.state.positionY * ratioHeight * marginHeight,
          content.data.state.scale === 1
            ? 1
            : content.data.state.scale * (1 / marginWidth)
        );
      }
    }

    if (content.action === ACTION_NAME.NEXT_IMAGE) {
      onMoveImagePresentation(content.data.idxActive);
    }
    if (content.action === ACTION_NAME.PREV_IMAGE) {
      onMoveImagePresentation(content.data.idxActive);
    }
  };

  useEffect(() => {
    if (isPresentation) {
      return socket.on(
        WEBSOCKET_CHANNEL.SHARE_UI_ACTION,
        listenerSharedUIAction
      );
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  });

  const refCurrentIdxActive = useRef(0);
  const [showGalleryData, setShowGalleryData] = useState({
    idxActive: 0,
  });

  const onMoveImagePresentation = (idxActive) => {
    setShowGalleryData({
      idxActive: idxActive,
    });
    transformComponentRef.current?.resetTransform();
  };

  const onNextImage = (data) => {
    if (refCurrentIdxActive.current === data?.length - 1) {
      setShowGalleryData({
        idxActive: 0,
      });
      refCurrentIdxActive.current = 0;
    } else {
      setShowGalleryData((v) => ({
        idxActive: v?.idxActive + 1,
      }));
      refCurrentIdxActive.current += 1;
    }
    transformComponentRef.current.resetTransform();
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.NEXT_IMAGE, {
        idxActive: refCurrentIdxActive.current,
      });
    }
  };

  const onPrevImage = (data) => {
    if (refCurrentIdxActive.current === 0) {
      let idx = data?.length - 1;
      setShowGalleryData({
        idxActive: idx,
      });
      refCurrentIdxActive.current = idx;
    } else {
      setShowGalleryData((v) => ({
        idxActive: v?.idxActive - 1,
      }));
      refCurrentIdxActive.current -= 1;
    }

    transformComponentRef.current.resetTransform();
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.PREV_IMAGE, {
        idxActive: refCurrentIdxActive.current,
      });
    }
  };


  if (!mediaImages) return null;

  return (
    <TransformWrapper
      ref={transformComponentRef}
      maxScale={2.5}
      minPositionX={window.screen.width * 1.55 * -1}
      minPositionY={window.screen.height * 1.7 * -1}
      maxPositionX={window.screen.width * 1.55}
      maxPositionY={window.screen.height * 1.7}
      wheel={{
        step: 0.1,
      }}
      pinch={{
        step: 0.1,
      }}
      onTransformed={(ref, state) => {
        if (!isPresentation) {
          socket.emitUIActionEvent(ACTION_NAME.ZOOM_OUT_FLOORPLAN_SESSION, {
            state,
            height: window.screen.height,
            width: window.screen.width,
          });
        }
      }}
    >
      {() => (
        <>
          <div className="session wrap-floor-plan-gallery">
            <img src={closeV2} className="close-icon" onClick={onClose} />
            <TransformComponent
              contentStyle={{ height: "100%", width: "100vw" }}
              wrapperStyle={{
                height: "100%",
                width: "100vw",
                background: "black",
                zIndex: 100,
              }}
            >
              {mediaImages?.length ? (
                mediaImages.map((item, key) => {
                  if (key === showGalleryData.idxActive) {
                    return (
                      <img
                        key={key}
                        src={getUploadedServerMediaUrl(encodeURI(item?.path))}
                        alt="floorplan"
                        className="floorplan-item"
                      />
                    );
                  }
                })
              ) : (
                <div className="floorplan-item img-not-found">
                  Image Not Found
                </div>
              )}
            </TransformComponent>
          </div>
          <>
              {mediaImages?.length !== 1 && (
                <div>
                  <div
                    className="fancy-btn-prev cursor-pointer"
                    onClick={() => onPrevImage(mediaImages)}
                  >
                    <img src={iconArrowBg} alt="next" className="next-arrow" />
                  </div>
                  <div
                    className="fancy-btn-next cursor-pointer"
                    onClick={() => onNextImage(mediaImages)}
                  >
                    <img src={iconArrowBg} alt="next" className="pre-arrow" />
                  </div>
                </div>
              )}
            </>
        </>
      )}
    </TransformWrapper>
  );
};

export default FloorplanModal;
