import React from "react";
import "./index.scss";
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import { useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import icBayHarbor from "../../assets/images/logo-bay-harbor.svg";
import { classNames } from "../../helper/utils";
import socket from "../../helper/socket";
import closeIcon from "../../assets/images/close-v3.svg";
import * as unitExploreAct from "../../reduxs/unit-explore/action";
import {
  reqSetActiveGalleryId,
  reqSetIsTransparent,
  reqSetPage,
} from "../../reduxs/home/action";
import { reqSetIsShowAmenityVirtualTour, reqSetIsShowExploreModal } from "../../reduxs/explore-modal/action";

library.add(faSearch);

const TopNav = (props) => {
  const {
    isShow,
    handleClickCube,
    handleClickGallery,
    handleLocation,
    handleAmenities,
    handleNeighborhood,
    handlePpg,
    handleClickResidences,
    isPresentation,
  } = props;
  const dispatch = useDispatch();
  const pageActive = useSelector((state) => state.home.page);
  const isShowViewLine = useSelector(
    (state) => state.unitExplore.isShowViewLine
  );
  const isShowReplayVideo = useSelector(
    (state) => state.precinctExplore.isShowReplayVideo
  );
  const isShowAmenityVirtualTour = useSelector(
    (state) => state.exploreModal.isShowAmenityVirtualTour
  );
  const page = useSelector((state) => state.home.page);
  const unit = useSelector((state) => state.unitExplore.selectedUnit);
  const modal = useSelector((state) => state.exploreModal.modal);
  const isLoading = useSelector((state) => state.scene.isLoading);

  const onClickCube = () => {
    if (isLoading) {
      return;
    }

    handleClickCube();
  };

  const listenerSharedUIAction = ({ content }) => {
    if (content.action === ACTION_NAME.CLOSE_UNIT_VIEWLINE) {
      hideViewLines();
    }
    if(content.action === ACTION_NAME.CLOSE_AMENITY_VIRTUAL_TOUR){
      hideAmenityVirtualTour()
    }
  };

  useEffect(() => {
    isShow && onClickCube();
  }, [isShow]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    }
    return () => {
      socket.off(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, listenerSharedUIAction);
    };
  }, [isPresentation]);

  const isActive = (menu) => {
    return pageActive === menu ? "active" : "";
  };

  const hideViewLines = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_UNIT_VIEWLINE)
    }
    dispatch(unitExploreAct.reqIsShowViewLine(false));
    dispatch(reqSetIsTransparent(false));
    dispatch(reqSetActiveGalleryId(""));

    if (page === PAGES.VIEWS_LEVEL) dispatch(reqSetPage(PAGES.VIEWS_PAGE));
    else dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
  };

  const hideAmenityVirtualTour = () => {
    if(!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.CLOSE_AMENITY_VIRTUAL_TOUR)
    }
    dispatch(reqSetIsShowExploreModal(true))
    dispatch(reqSetIsShowAmenityVirtualTour(false));
  };

  const PageOnboardTopNav = () => (
    <div className="nav-cube-onboard">
      <img src={icBayHarbor} alt="icon-cube" onClick={onClickCube} />
    </div>
  );

  if (isPresentation) {
    return null
  }

  if (page === PAGES.ONBOARD_PAGE && !isShow && !isShowReplayVideo) {
    return <PageOnboardTopNav />;
  }

  return (
    <>
      <div
        className={classNames(
          "wrap-top-nav body-container",
        )}
      >
        {(isShow || isShowReplayVideo) && (
          <div className="top-nav">
            <img
              className="icon-cube-small"
              src={icBayHarbor}
              alt="icon-cube-small"
              onClick={onClickCube}
            />
            <ul className="nav-right">
              <li
                className={isActive(PAGES.UNIT_EXPLORER_PAGE)}
                onClick={handleClickResidences}
              >
                <span>
                  EXPLORE
                </span>
              </li>
              <li
                className={isActive(PAGES.LOCATION_PAGE)}
                onClick={handleLocation}
              >
                <span>
                  LOCATION
                </span>
              </li>
              <li
                className={isActive(PAGES.GALLERY_LANDING_PAGE)}
                onClick={handleClickGallery}
              >
                <span>
                  GALLERY
                </span>
              </li>
              <li
                className={isActive(PAGES.AMENITIES_PAGE_V2)}
                onClick={handleAmenities}
              >
                <span>
                  AMENITIES
                </span>
              </li>
              <li
                className={isActive(PAGES.NEIGHBORHOOD_PAGE)}
                onClick={handleNeighborhood}
              >
                <span>
                  NEIGHBORHOOD
                </span>
              </li>
              <li
                className={isActive(PAGES.PPG_PAGE)}
                onClick={handlePpg}
              >
                <span>
                  TEAM
                </span>
              </li>
            </ul>
          </div>
        )}
      </div>
      {/* {
        isShowViewLine && (
          <div
            className="wrap-top-nav second-nav"
          >
            <div className="top-nav">
              <div className="unit-name">RESIDENCE {unit?.name}</div>
              <img onClick={hideViewLines} src={closeIcon} className="close-icon-nav" />
            </div>
        </div>
      )
    } */}

    {
      isShowAmenityVirtualTour &&  (
        <div
          className="wrap-top-nav second-nav"
        >
            <div className="top-nav">
              <div className="amenity-top-nav-title">{modal?.name}</div>
              <img onClick={hideAmenityVirtualTour} src={closeIcon} className="close-icon-nav"/>
            </div>
        </div>
      )
    }
    </>
  );
};

export default TopNav;
